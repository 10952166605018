// sidebar.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { BiEnvelope } from 'react-icons/bi';
import { BsGithub, BsLinkedin, BsHouse, BsPerson, BsFileText, BsJournalRichtext, BsServer, BsEnvelope } from 'react-icons/bs';
import { AiFillFacebook } from 'react-icons/ai';
import { DiNpm } from 'react-icons/di';
// import kodanad from '@src/images/kodanad';
import DonateButton from './support/support_content.jsx';

import '@src/home.scss';

const Sidebar = () => {
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const updateWindowWidth = () => {
      if (window.innerWidth < 576) { // Adjust as needed
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
  
    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return (
    <div id="header" className={isCollapsed ? 'collapsed' : ''}>
      <div className="d-flex flex-column">
        <div className="profile">
          <img src={"/files/profiles/kodanad.jpg"} alt="" className="img-fluid rounded-circle" />
          <h1 className="text-light"><Link to="/">RpAsher</Link></h1>
          <div className="social-links mt-3 text-center">
            <a href="https://github.com/Rpasher1987" className="github" target="_blank" rel="noreferrer"><BsGithub /></a>
            <a href="https://www.facebook.com/RpAsher327" className="facebook" target="_blank" rel="noreferrer"><AiFillFacebook /></a>
            <a href="https://app.netlify.com/teams/rpasher1987/" className="netlify" target="_blank" rel="noreferrer"><DiNpm /></a>
            <a href="https://www.linkedin.com/in/rpasher1987/" className="linkedin" target="_blank" rel="noreferrer"><BsLinkedin /></a>
            <Link to="/contact/" className="nav-link active" rel="noreferrer"><BiEnvelope /></Link>
          </div>
        </div>
        <nav id="navbar" className="nav-menu navbar border-bottom">
          <ul>
              <li><Link to="/" className="nav-link active"><BsHouse /> <span>Home</span></Link></li>
              <li><Link to="/about/" className="nav-link active"><BsPerson /> <span>About</span></Link></li>
              <li><Link to="/resume/" className="nav-link active"><BsFileText /> <span>Resume</span></Link></li>
              <li><Link to="/portfolio/" className="nav-link active"><BsJournalRichtext /> <span>Portfolio</span></Link></li>
              <li><Link to="/services/" className="nav-link active"><BsServer /> <span>Services</span></Link></li>
              <li><Link to="/contact/" className="nav-link active" rel="noreferrer"><BsEnvelope /> <span>Contact</span></Link></li>
              <li  id='donate-button' className="donate-button nav-link active"><DonateButton /></li>
          </ul>
        </nav>
        <div id="sidebar-wrapper" className="sidebar-wrapper">
        <div id="sidebar" className="text-center mb-4">
          <i className="bi bi-c-circle-fill text-white pt-2"> RpAsher, LLC {new Date().getFullYear()}</i>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;