import React from 'react';
import { Link } from "react-router-dom";
import { AiFillCalculator } from "react-icons/ai";

const GoBack = () => {
  return (
    <Link to="/portfolio" id='goBack' className="goBackLink">
      <AiFillCalculator className="goBackIcon" />
      <span className="goBackText">Back to Calculators</span>
    </Link>
  );
};

export default GoBack;