import React from 'react';
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";

const GoHome = () => {
  return (
    <Link to="/" className="goHomeLink">
      <AiFillHome className="goHomeIcon" />
      <span className="goHomeText"></span>
    </Link>
  );
};

export default GoHome;