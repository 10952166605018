// fetchHelper.js
// import { safeCredentials, handleErrors } from './utils/fetchHelper';
// // change the path to fetchHelper.js to the one that fits your file structure
// // Remember the todo list, this would go in either the "requests" or "index" JS section under SRC
// fetch(`/users`, safeCredentials({
//   method: 'POST',
//   body: JSON.stringify({
//     users: {
//       email: 'hello@world.com',
//       password: 'abc123'
//     }
//   })
// }))
// .then(handleErrors)
// .then(res => {
//   console.log(res);
// })
/**
 * For use with window.fetch
 */
export function jsonHeader(options = {}) {
   return Object.assign(options, {
     Accept: 'application/json',
     'Content-Type': 'application/json',
   });
 }
 // Additional helper methods
 export function getMetaContent(name) {
   const header = document.querySelector(`meta[name="${name}"]`);
   return header && header.content;
 }
 
 export function getAuthenticityToken() {
   return getMetaContent('csrf-token');
 }
 
 export function authenticityHeader(options = {}) {
   return Object.assign(options, {
     'X-CSRF-Token': getAuthenticityToken(),
     'X-Requested-With': 'XMLHttpRequest',
   });
 }
 
 /**
 * Lets fetch include credentials in the request. This includes cookies and other possibly sensitive data.
 * Note: Never use for requests across (untrusted) domains.
 */
 export function safeCredentials(options = {}) {
   return Object.assign(options, {
     credentials: 'include',
     mode: 'same-origin',
     headers: Object.assign((options.headers || {}), authenticityHeader(), jsonHeader()),
   });
 }

// Use this function if you are using formData as body when uploading images
export function safeCredentialsFormData(options = {}) {
   return Object.assign(options, {
     credentials: 'include',
     mode: 'same-origin',
     headers: Object.assign((options.headers || {}), authenticityHeader()),
   });
 }

 // Handle Errors
 export function handleErrors(response) {
   if (!response.ok) {
     throw Error(response.statusText);
   }
   return response.json();
 }

 //Format Date
 export function FormatDate(date, isRelative) {
   let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
   let newDate = new Date(date)
   newDate = months[newDate.getMonth()] + ' ' + newDate.getDate() + ', ' + newDate.getFullYear() + ' ' + newDate.toLocaleTimeString()
   if(isRelative) {
     newDate = Date.now() - new Date(date)
     newDate = newDate / 1000
     if(newDate < 60 ) {
       newDate = Math.round(newDate) + ' seconds ago'
     } else if(newDate < 3600 ) {
       newDate = Math.round(newDate / 60) + ' minutes ago'
     } else if(newDate < 86400) {
       newDate = Math.round(newDate / 3600) + ' hours ago'
     } else {
       newDate = Math.round(newDate / 86400) + ' days ago'
     }
     // newDate = newDate / 1000  / 60 / 60 / 24
     return newDate
   }
   return newDate
 }


 //Get today's date
export function setDate(name) {
   const date = new Date().toLocaleDateString('en-US', {
     month: 'long',
     day: 'numeric',
     year: 'numeric'
   });
 
   return `Hello ${name}, Today's date is ${date}.`;
 }