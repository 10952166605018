import React from 'react';
// import indiahampi1 from '@src/images/indiahampi1';

import '@src/home.scss';

class About extends React.Component {
  render () {
    return (
      <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
        <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/indiahampi1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
        <section id='about' className='about'>
          <div className='container pb-4'>
            <div className='section-title'>
              <h2 className='fw-bold mb-3 text-center text-decoration-underline'>About</h2>
              <h4 className='fw-bold'>Life</h4>
                <p>Hi, my name is Ryan Asher and I am originally from Tivoli, NY. I built this site to channel all my crazy thoughts into various excel, coding, and finance projects. A little about me...</p>
                <p>I left New York when I was around 19 to see the world. Traveling is at the heart of who I am. From the age of 19 to 25 I lived and spent time in 6 continents and 23 countries. The experiences I had, and people I met along the way have shaped me into the man I am today. My passion for learning and desire to see and understand more was born from my first and favorite place - Rome, Italy. Each new destination fueled that desire and set me down the path of a lifelong learner. I would have no career in Investment/Finance today if it were not for the experience I gained working for a hedge fund in Queensland, Australia. While I can not travel the same way I did then, everything I do today is building towards a time when I can again.</p>
                <p>Sometime close to 25 I realized that being a vagabond was not an actual career path, so I came back to the US to go to College. I later graduated from the College of Charleston in 2018 with a B.S. in Business Administration with a dual concentration in Finance and Entrepreneurship. Currently, I live in Charleston, South Carolina where I am a full time equities trader.</p>
                <hr/>
                <h4 className='fw-bold'>Career</h4>
                <p>Throughout my career I have been a goal-focused and results-driven professional with substantial experience in leading all facets of financial planning and analysis, profit and revenue optimization, portfolio management, business strategy development, contract negotiations, investment planning/execution, and stakeholder engagement, while ensuring attainment of set organizational financial objectives with unequalled success.</p>
                <ul className='mb-3'>
                  <li>I have a demonstrated history of excellence in steering routine activities related to research and development of strategic initiatives to improve firm growth and market reach. </li>
                  <li>I am an expert at financial management and modeling tools, such as ORION, ECLIPSE, Astro, Kwanti, Salesforce (Salentica), Redtail, Portfolio Pathway, iAdvisor, Adhesion, eMoney, MorningStar, iRebal, Riskalyze, VBA Macros, Pivot Tables, Microsoft Excel, Microsoft Word. </li>
                  <li>I have extensive experience with coding and full-stack web development, specifically with HTML, CSS, Javascript, JQuery, ReactJS, Ruby, Ruby on Rails, jbuilder, GIT, Ubuntu, Heroku, Filezilla and familiarity with PHP, WAMP, MySQL, and Kompozer. </li>
                  <li>I excel at monitoring and evaluating compliance with regulations, policies, and standards as well as planning and executing fully considered programs to meet marketing plan objectives.  </li>
                  <li>Skilled in generating and securing investment opportunities, structuring equity investments and loans, and overseeing evaluation, due diligence, and closing process in portfolios acquisition. </li>
                  <li>I am highly adaptable to ever-changing demand of FinTech with a track record of enhancing technological and operational capabilities and efficiency of the firm.</li>
                </ul>
                <p>While others take pride in meeting all standards set before them, my passion is for delivering FinTech service that goes beyond organizational expectations, creating a win-win scenario for everyone involved! Please feel free to contact me with any thoughts, comments, or questions about my work, I'm always interested in making new professional acquaintances.</p>
            </div>
          </div>
        </section>
      </main>
    )
  }
}

export default About
