// SavingsLinks.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Accordion, Table, Card, CardGroup, ListGroup } from 'react-bootstrap';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const SubRoute = ({ match }) => <p className="p-2 mt-2 border border-light">{match.url}</p>

const NotFound = () => {
  return <h2>404 Not Found</h2>;
}

const SavingsLinks = () => {
  return (
    <React.Fragment>
  <Layout>
    <CoverCard>
    <Card.Title id="title">Savings & Interest Calculators</Card.Title>
        <p>Select a calculator from the list below:</p>
        <ListGroup className='mt-2'>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/apr_to_apy">APR to APY Calculator</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/apy_to_apr">APY to APR Calculator</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/count_periods1">Number of Periods Calculator</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/savings_calculator">Savings Calculator</Link></ListGroup.Item>
            {/* <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/name_here">Name Here</Link></ListGroup.Item> */}
        </ListGroup>
    </CoverCard>
  </Layout>
</React.Fragment>
  )
}

export default SavingsLinks;