import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Card, Alert } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const MortgageTotalInterest = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [homeValue, setHomeValue] = useState(400000);
  const [interestRate, setInterestRate] = useState(4.5);
  const [termYears, setTermYears] = useState(20);
  const [schedule, setSchedule] = useState([]);
  const [totalInterestPaid, setTotalInterestPaid] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const calculatePayment = (e) => {
    e.preventDefault();

    const P = homeValue;
    const i = (interestRate / 100) / 12;
    const n = termYears * 12;
    const M = P * (i * Math.pow((1 + i), n)) / (Math.pow((1 + i), n) - 1);

    let schedule = [];
      let remainingBalance = P;
      let totalInterestPaid = 0;
  
      for (let period = 1; period <= n; period++) {
          let interestPayment = remainingBalance * i;
          let principalPayment = M - interestPayment;
          remainingBalance -= principalPayment;
          totalInterestPaid += interestPayment;
  
          schedule.push({
              period: period,
              beginningBalance: remainingBalance + principalPayment, // Calculate beginning balance based on remaining balance and principal payment
              payment: M,
              interestPayment: interestPayment,
              principalPayment: principalPayment,
              endingBalance: remainingBalance
          });
  
          if (remainingBalance <= 0) {
              break;
          }
      }
      let totalPaid = totalInterestPaid + P;

    setMonthlyPayment(M);
    setSchedule(schedule);
    setTotalInterestPaid(totalInterestPaid);
    setTotalPaid(totalPaid);
    setShowAlert(true);
  }

  const resetFields = () => {
    setHomeValue(400000);
    setInterestRate(4.5);
    setTermYears(20);
    setMonthlyPayment(0);
    setTotalInterestPaid(0);
    setTotalPaid(0);
    setSchedule([]);
    setShowAlert(false);
  }

  return (
    <React.Fragment>
    <Layout>
       <CoverCard>
          {/* Insert Below */}
          <Card.Title id='title'>Mortgage Cumulative Interest Calculator</Card.Title>
          <Form onSubmit={calculatePayment}>
          <div className="row-container">
            <div className="row-item">
              {/* CONTENT BELOW THIS LINE */}
              <Form.Group controlId="formHomeValue" className="mb-4">
                  <Form.Label className="mb-1">Home Value:</Form.Label>
                  <NumericFormat 
                    thousandSeparator={true} 
                    prefix={'$'} 
                    className="form-control" 
                    value={homeValue} 
                    onValueChange={(values) => {
                    const { floatValue } = values;
                    setHomeValue(floatValue);
                    }} 
                  />
              </Form.Group>
            </div>
            <div className="row-item">
              {/* CONTENT BELOW THIS LINE */}
              <Form.Group controlId="formInterestRate" className="mb-4">
              <Form.Label className="mb-1">Interest Rate:
              <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={props => (
                  <Tooltip id="button-tooltip" {...props}>
                  Your expected or offered mortgage interest rate
                  </Tooltip>
                  )}
              >
              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
              </OverlayTrigger>
              </Form.Label>
              <NumericFormat 
                  decimalScale={2}
                  suffix={'%'} 
                  className="form-control" 
                  value={interestRate} 
                  onValueChange={(values) => {
                  const { floatValue } = values;
                  setInterestRate(floatValue);
                  }} 
              />
            </Form.Group>

            </div>
            <div className="row-item">
              {/* CONTENT BELOW THIS LINE */}
              <Form.Group controlId="formTermYears" className="mb-4">
                <Form.Label className="mb-1">Term (Years):</Form.Label>
                <NumericFormat 
                  className="form-control" 
                  value={termYears} 
                  onValueChange={(values) => {
                  const { floatValue } = values;
                  setTermYears(floatValue);
                  }} 
                />
            </Form.Group>
            </div>
          </div>
              <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
              <Button variant="danger" type="button" onClick={resetFields} className="custom-button" size="sm">Reset</Button>
              {showAlert && (
               <Alert variant="success" className="mt-3">
                     If just making the minimum payment through the entire life of the loan, the total interest you would pay is <NumericFormat value={totalInterestPaid} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> for a grand total of <NumericFormat value={totalPaid} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> with Principle and Interest.
               </Alert>
               )}
          </Form>
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title className='fw-bold text-center'>How The Mortgage Interest Calculator Works</Card.Title>
            <p>
              This calculator allows users to understand the monthly repayment and the breakdown of the payments of a fixed-rate mortgage loan. It provides a detailed repayment schedule over the course of the loan term.
            </p>
            <p>
              A mortgage is a loan that is used to finance the purchase of property or real estate. The borrower is obligated to make a predetermined set of payments to repay the loan over a set term. A portion of each payment goes towards the loan principal, and the remainder is used to pay interest accrued on the loan. Over time, the interest portion decreases, and the principal portion increases.
            </p>
            <p>
              The formula for calculating the monthly payment (M) is:
            </p>
            <ul>
              <li><code> M = P * (i * (1 + i) ^ n) / ((1 + i) ^ n - 1) </code></li>
            </ul>
            <p>Where:</p>
              <ul>
                <li>P is the Principal loan amount</li>
                <li>i is the monthly interest rate (annual rate divided by 12)</li>
                <li>n is the number of payments (months)</li>
              </ul>
              <p>
              The formula for calculating the Total Interest Paid is:
            </p>
            <ul>
              <li><code> ΣInterest = (M x n) - P </code></li>
            </ul>
            <p>Where:</p>
              <ul>
                <li>M is the monthly payment</li>
                <li>n is the number of payments (months)</li>
                <li>P is the principal</li>
              </ul>
            <p>
              This Mortgage Calculator takes the Home Value, Interest Rate, and Term (Years) as inputs, and calculates total interest paid over the life of a mortgage loan. The user can reset all fields using the "Reset" button.
            </p>
            <div>
              <Card.Img variant="top" src="/files/images/mortgage_total_interest.png" />
            </div>
       </CoverCard>
    </Layout>
 </React.Fragment>
 );
};

export default MortgageTotalInterest;