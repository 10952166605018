import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Card } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const SavingsCalculator = () => {
  const [savingsGoal, setSavingsGoal] = useState(20000);
  const [rateOfReturn, setRateOfReturn] = useState(5);
  const [timePeriod, setTimePeriod] = useState(5);
  const [compoundFrequency, setCompoundFrequency] = useState("Monthly");
  const [result, setResult] = useState("");
  const [error, setError] = useState("");

  const calculateContribution = () => {
    if (!savingsGoal || !rateOfReturn || !timePeriod || !compoundFrequency) {
      setError("Please fill out all fields.");
      setResult("");
    } else {
      const FV = parseFloat(savingsGoal);
      const r = parseFloat(rateOfReturn) / 100;
      const t = parseFloat(timePeriod);
      const m = compoundFrequency === 'Annual' ? 1 : 12;
  
      const C = FV / (((Math.pow((1 + r / m), (t * m)) - 1) / (r / m)));
      setResult(C.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}));
      setError("");
    }
  };  

  const resetFields = () => {
    setSavingsGoal("");
    setRateOfReturn("");
    setTimePeriod("");
    setCompoundFrequency("Monthly");
    setResult("");
    setError("");
  };

  return (
    <React.Fragment>
      <Layout>
      <CoverCard>
          {/* Insert Below */}
          <Card.Title id="title">Required Savings Calculator</Card.Title>
          <Form>
          <div className="row-container">
                <div className="row-item">
                  <Form.Group className="mb-4">
                  <Form.Label className="mb-1">Savings Goal:
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>The amount that you want to save</Tooltip>}
                        >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                        </OverlayTrigger>
                  </Form.Label>
                  <NumericFormat
                        value={savingsGoal}
                        onValueChange={(values) => setSavingsGoal(values.value)}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        placeholder="Savings Goal"
                  />
                  </Form.Group>
                  </div>

                  <div className="row-item">
                  <Form.Group className="mb-4">
                        <Form.Label className="mb-1">Rate of Return:
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>The Average annaul rate of return you expect to make on your investments over your specified time frame.</Tooltip>}
                        >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                        </OverlayTrigger>
                        </Form.Label>
                        <NumericFormat
                            value={rateOfReturn}
                            onValueChange={(values) => setRateOfReturn(values.value)}
                            suffix={'%'}
                            decimalScale={2}
                            placeholder="Rate of Return"
                          />
                  </Form.Group>
                  </div>

                  <div className="row-item">
                  <Form.Group className="mb-4">
                  <Form.Label className="mb-1">Time Period (Years):</Form.Label>
                  <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>When you desire to reach your savings goal by</Tooltip>}
                  >
                      <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  <NumericFormat
                      value={timePeriod}
                      onValueChange={(values) => setTimePeriod(values.value)}
                      type="number"
                      placeholder="Time Period (Years)"
                  />
                  </Form.Group>
                </div>
            </div>
            <Form.Group className="mb-4">
              <Form.Label className="mb-1">Time Period (Years):</Form.Label>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>When you desire to reach your savings goal by</Tooltip>}
                >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                </OverlayTrigger>
                <Form.Check 
                  type="radio"
                  id="annual-compound-frequency"
                  name="compoundFrequency"
                  label="Annual"
                  onChange={() => setCompoundFrequency('Annual')}
                  checked={compoundFrequency === 'Annual'}
                />
                <Form.Check 
                  type="radio"
                  id="monthly-compound-frequency"
                  name="compoundFrequency"
                  label="Monthly"
                  onChange={() => setCompoundFrequency('Monthly')}
                  checked={compoundFrequency === 'Monthly'}
                />
            </Form.Group>
            </Form>
            <Button variant="success" className="custom-button" size="sm" onClick={calculateContribution}>Calculate</Button>
              <Button variant="danger" type="button" onClick={resetFields} className="custom-button" size="sm">Reset</Button>
              {result && 
                <h4 id='result-display' className='border border-success p-2 border-opacity-10 text-center'>Required {compoundFrequency === 1 ? 'Annual' : 'Monthly'} Savings for Goal: ${result}</h4>
              }
              {error && <Alert variant='danger'>{error}</Alert>}
          </CoverCard>
        {/* Space Filler */}
        <CoverCard>
            {/* Insert Below */}
            <Card.Title className='fw-bold text-center'>How The Savings Goal Calculator Works</Card.Title>
            <p>
              This Savings Goal Calculator allows users to determine the required contributions needed to reach a specific financial target. It does this by taking into account the savings goal, the expected rate of return, the time period for the investment, and the frequency of compounding. The calculator provides a specific amount that needs to be invested annually or monthly (based on the selected frequency of compounding) to reach the savings goal at the end of the time period.
            </p>
            <p>
              When individuals or businesses plan for future financial goals, they often need to calculate how much money needs to be set aside regularly to reach these goals. The savings could be for retirement, purchasing a property, starting a business, or any other future expenditure. This calculator helps to determine this amount, taking into account the power of compounding and the expected rate of return.
            </p>
            <p>
              The Savings Goal Calculator uses several key pieces of information:
            </p>
            <ul>
              <li>Savings Goal (Future Value - FV)</li>
              <li>Rate of Return (r)</li>
              <li>Time Period in years (t)</li>
              <li>Compound Frequency (m) - with options for 'Annual' and 'Monthly'</li>
            </ul>
            <p>
              Using these inputs, it calculates the amount of contribution required either annually or monthly. This is done based on the compound interest formula for future value of an annuity. The formula rearranges to solve for the regular contribution amount (C).
            </p>
            <p>
              The formula used when 'Annual' frequency is selected is:
            </p>
            <ul>
              <li><code>C = FV / (((1 + r) ^ t) - 1) / r</code></li>
            </ul>
            <p>Where:</p>
            <ul>
              <li>FV is the Future Value (Savings Goal)</li>
              <li>r is the annual interest rate</li>
              <li>t is the time period in years</li>
            </ul>
            <p>
              The formula used when 'Monthly' frequency is selected is:
            </p>
            <ul>
              <li><code>C = FV / (((1 + r / m) ^ (t * m)) - 1) / (r / m)</code></li>
            </ul>
            <p>Where:</p>
            <ul>
              <li>FV is the Future Value (Savings Goal)</li>
              <li>r is the annual interest rate</li>
              <li>t is the time period in years</li>
              <li>m is the compound frequency (12 for monthly)</li>
            </ul>
            <div>
              <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/savings_goal_calc.png" />
            </div>
        </CoverCard>
      </Layout>
    </React.Fragment>
  );
};

export default SavingsCalculator;