import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const NumberOfPeriods = () => {
    const [PV, setPV] = useState(25000);
    const [r, setR] = useState(5);
    const [P, setP] = useState(550);
    const [t, setT] = useState(12);
    const [n, setN] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showInsufficientPaymentError, setShowInsufficientPaymentError] = useState(false);
  
    const calculateN = () => {
      if (PV && r && P && t) {
        let i = (r / 100) / t;
        if ((PV * i) / P >= 1) {
          setShowInsufficientPaymentError(true);
          setN(null);
        } else {
          let n = Math.log(1 / (1 - ((PV * i) / P))) / Math.log(1 + i);
          n = Math.round(n);
          setN(n);
          setShowError(false);
          setShowInsufficientPaymentError(false);
        }
      } else {
        setShowError(true);
        setShowInsufficientPaymentError(false);
      }
    };
  
    const resetValues = () => {
      setPV(25000);
      setR(5);
      setP(550);
      setT(12);
      setN(null);
      setShowError(false);
      setShowInsufficientPaymentError(false);
    };

  return (
    <React.Fragment>
        <Layout>
            <CoverCard>
            <Card.Title id="title">Number of Payments Calculator</Card.Title>
                {/* Insert Below */}
                <Form>
                <div className="row-container">
                    <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <NumericFormat
                            value={PV}
                            thousandSeparator={true} 
                            prefix={'$'} 
                            className="form-control"
                            onValueChange={(values) => {
                            const { value } = values;
                            setPV(parseFloat(value));
                            }}
                            required
                        />
                    </Form.Group>
                    </div>
                    <div className="row-item">

                    <Form.Group controlId="rate">
                        <Form.Label>Rate</Form.Label>
                        <NumericFormat
                            decimalScale={3}
                            suffix="%"
                            className="form-control"
                            value={r}
                            onValueChange={(values) => {
                            const { value } = values;
                            setR(parseFloat(value));
                            }}
                            required
                        />
                    </Form.Group>
                    </div>
                    <div className="row-item">
                    <Form.Group controlId="periodicPayment">
                        <Form.Label>Periodic Payment</Form.Label>
                        <NumericFormat
                        value={P}
                        thousandSeparator={true} 
                        prefix={'$'}
                        className="form-control"
                        onValueChange={(values) => {
                        const { value } = values;
                        setP(value === '' ? 0 : parseFloat(value));
                        }}
                        required
                        />
                    </Form.Group>
                </div>
            </div>

                <Form.Group controlId="compoundingFrequency">
                    <Form.Label>Compounding Frequency</Form.Label>
                    <Form.Check
                    type="radio"
                    label="Monthly"
                    name="compoundingFrequency"
                    id="monthly"
                    value={12}
                    checked={t === 12}
                    onChange={() => setT(12)}
                    />
                    <Form.Check
                    type="radio"
                    label="Quarterly"
                    name="compoundingFrequency"
                    id="quarterly"
                    value={4}
                    checked={t === 4}
                    onChange={() => setT(4)}
                    />
                    <Form.Check
                    type="radio"
                    label="Semi-Annually"
                    name="compoundingFrequency"
                    id="semiAnnually"
                    value={2}
                    checked={t === 2}
                    onChange={() => setT(2)}
                    />
                    <Form.Check
                    type="radio"
                    label="Annually"
                    name="compoundingFrequency"
                    id="annually"
                    value={1}
                    checked={t === 1}
                    onChange={() => setT(1)}
                    />
                </Form.Group>

                <Button variant="success" type="button" onClick={calculateN} className="custom-button" size="sm">Calculate</Button>
                <Button variant="danger" type="button" onClick={resetValues} className="custom-button" size="sm">Reset</Button>

                {showError && 
                    <Alert variant="danger">
                    Please fill out all fields.
                    </Alert>
                }

                {showInsufficientPaymentError && 
                    <Alert variant="danger">
                    Payment must be sufficient to cover the interest on the principal.
                    </Alert>
                }

                {n !== null && 
                    <Alert variant="success">
                    It would take {n} payment(s) to payoff the amount.
                    </Alert>
                }
                </Form>
            </CoverCard>
            {/* Space Filler */}
            <CoverCard>
                {/* Insert Below */}
                <Card.Title className='fw-bold text-center'>How the Number of Payments Calculator Works</Card.Title>
                <p>
                    The Number of Payments Calculator uses several key pieces of information:
                </p>
                <ul>
                    <li>Investment Amount (PV)</li>
                    <li>Contributions (P)</li>
                    <li>Rate of Return (r)</li>
                    <li>Compound Frequency (t) - options include weekly, bi-weekly, monthly, quarterly, semi-annually, and annually</li>
                </ul>
                <p>
                    Using these inputs, the calculator computes the number of payments required to pay off a stated amount, given the amount, interest rate, periodic payments, and compounding frequency.
                </p>
                <p>
                    The formulas used are:
                </p>
                <ul>
                    <li><code>Number of Periods (<i>n</i>) = ln[(1-((PV * i)/P))^-1] / ln(1 + i)</code></li>
                </ul>
                <div>
                    <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/pv_periods.png" />
                </div>
            </CoverCard>
        </Layout>
    </React.Fragment>
  );
};

export default NumberOfPeriods;
