import React, { useState } from 'react';
import { Form, Button, Card, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import tombsnobles1 from '@src/images/tombsnobles1';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const DividendDiscountModel = () => {
   const [currentStockPrice, setCurrentStockPrice] = useState(0);
   const [discountRate, setDiscountRate] = useState(0);
   const [ttmDividends, setTtmDividends] = useState(0);
   const [dividendGrowth, setDividendGrowth] = useState(0);
   const [ddmValue, setDdmValue] = useState(0);
   const [valueDifference, setValueDifference] = useState(0);
 
   const calculateDDM = (e) => {
     e.preventDefault();
     
     let D = Number(ttmDividends); // TTM Dividends per Share
     let r = Number(discountRate) / 100; // Discount Rate
     let g = Number(dividendGrowth) / 100; // Dividend Growth Rate
 
     let ddm = D / (r - g);
     let diff = ((ddm - Number(currentStockPrice)) / Number(currentStockPrice)) * 100;
 
     setDdmValue(ddm.toFixed(2));
     setValueDifference(diff.toFixed(2));
   }

   const clearForm = () => {
      setCurrentStockPrice(0);
      setDiscountRate(0);
      setTtmDividends(0);
      setDividendGrowth(1);
      setDdmValue(0);
      setValueDifference(0);
    }
 

  return (
  <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
    <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
    <section id="rmdCalc" className="onlineTools">
      <div className="container" id="onlineTools">
          <>
          <div className="d-flex justify-content-center align-items-center">
          <Card style={{ width: '40rem' }}>
            <Card.Body>
            <Card.Title className='fw-bold text-center p-2 pb-3'><h2>Dividend Discount Model (DDM) Calculator</h2></Card.Title>
               {/* INSERT BELOW */}
               <div>
                  <Form onSubmit={calculateDDM}>
                  <Form.Group controlId="formCurrentStockPrice" className="mb-4">
                     <Form.Label className="mb-1">Current Stock Price ($):</Form.Label>
                        <NumericFormat 
                        thousandSeparator={true} 
                        prefix={'$'} 
                        className="form-control" 
                        value={currentStockPrice} 
                        onValueChange={(values) => {
                           const { value } = values;
                           setCurrentStockPrice(parseFloat(value));
                        }} 
                        />
                     </Form.Group>

                     <Form.Group controlId="formDiscountRate">
                        <Form.Label>Discount Rate (%):
                           <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => (
                              <Tooltip id="button-tooltip" {...props}>
                                 The return you could achieve someplace else. I usually put the risk-free rate.
                              </Tooltip>
                              )}
                           >
                              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                           </OverlayTrigger>
                        </Form.Label>
                           <NumericFormat 
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={'%'} 
                              className="form-control" 
                              value={discountRate} 
                              onValueChange={(values) => {
                                 const { value } = values;
                                 setDiscountRate(parseFloat(value));
                              }} 
                           />
                        </Form.Group>

                        <Form.Group controlId="formTtmDividends">
                        <Form.Label>Total Dividends per Share ($) (annual):
                           <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => (
                              <Tooltip id="button-tooltip" {...props}>
                                 If $1 every quarter, then $4 would go here (annual dividend amount)
                              </Tooltip>
                              )}
                           >
                              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                           </OverlayTrigger>
                           </Form.Label>
                              <NumericFormat 
                                 thousandSeparator={true} 
                                 prefix={'$'} 
                                 className="form-control" 
                                 value={ttmDividends} 
                                 onValueChange={(values) => {
                                 const { value } = values;
                                 setTtmDividends(parseFloat(value));
                                 }} 
                              />
                        </Form.Group>

                        <Form.Group controlId="formDividendGrowth">
                           <Form.Label>Dividend Growth (%):
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    The average annual amount you expect the dividend to grow over the years
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                           </Form.Label>
                              <NumericFormat 
                                 decimalScale={2}
                                 fixedDecimalScale
                                 suffix={'%'} 
                                 className="form-control" 
                                 value={dividendGrowth} 
                                 onValueChange={(values) => {
                                    const { value } = values;
                                    setDividendGrowth(parseFloat(value));
                                 }} 
                              />
                           </Form.Group>

                        <Button variant="success" type="submit" className="mb-4" size="sm">
                           Calculate
                        </Button>

                        <Button variant="danger" type="button" onClick={clearForm} className="mb-4 ml-2" size="sm">
                           Clear
                        </Button>
                  </Form>

                  <Card className="mt-4">
                  <Card.Body>
                     <Card.Title>Calculated DDM Value</Card.Title>
                     <Card.Text>
                        <NumericFormat value={ddmValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                     </Card.Text>

                     <Card.Title>Over / Under Value Percentage</Card.Title>
                     <Card.Text>
                        <NumericFormat value={valueDifference} displayType={'text'} suffix={'%'} decimalScale={2} />
                     </Card.Text>
                  </Card.Body>
                  </Card>
               </div>
              {/* INSERT ABOVE */}
              <GoBack className="mt-2 ml-2 text-right"/>
            </Card.Body>
          </Card>
          </div>
          </>
        </div>
        <div className='container' className='onlineTools mt-3'>
            <div className="d-flex justify-content-center align-items-center">
            <Card style={{ width: '40rem' }}>
                  <Card.Body>
                     <Card.Title>Dividend Discount Model (DDM)</Card.Title>
                     <p>
                        The Dividend Discount Model (DDM) is a method of valuing a company's stock price based on the theory that its stock is worth the sum of all of its future dividend payments, discounted back to their present value. In other words, it is used to value stocks based on the net present value of the future dividends.
                     </p>
                     <p>
                        The formula used for the calculation is:
                        <br />
                        <code>P<sub>0</sub> = D / (k - g)</code>
                        <br />
                        where,
                     </p>
                     <ul>
                        <li>D is the Dividend Per Share</li>
                        <li>k is the Required Rate of Return (or discount rate)</li>
                        <li>g is the Dividend Growth Rate</li>
                        <li>DDM is the Dividend Discount Model value</li>
                     </ul>
                     <p>
                        After entering these parameters, the calculator determines the theoretical value of the company.
                     </p>
                     <div>
                        <Card.Img variant="top" src="/files/images/ddm.png" />
                     </div>
                  </Card.Body>
               </Card>
            </div>
         </div>
      </section>
    </main>
  );
};

export default DividendDiscountModel;

