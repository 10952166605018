// contact_form.jsx
import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { BiEnvelope } from 'react-icons/bi';
import { BsGithub, BsLinkedin, BsHouse, BsPerson, BsFileText, BsJournalRichtext, BsServer, BsEnvelope } from 'react-icons/bs';
import { AiFillFacebook } from 'react-icons/ai';
import { DiNpm } from 'react-icons/di';
import { safeCredentials, handleErrors } from '@utils/fetchHelper';
// import Layout from '@utils/_layout.jsx';
import CoverCard2 from '@utils/_card2.jsx';
// import monkey1 from '@src/images/monkey1';

import './home.scss';

const ContactForm = () => {
  const [contactForm, setContactForm] = useState({ name: '', email: '', subject: '', message: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state variable for error message

  const handleChange = (event) => {
    setContactForm({ ...contactForm, [event.target.name]: event.target.value });
  };

  const handleReset = () => {
    setContactForm({ name: '', email: '', subject: '', message: '' });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch('/contact_forms', safeCredentials({
      method: 'POST',
      body: JSON.stringify(contactForm)
    }))
    .then(handleErrors)
    .then(response => {
      setIsSubmitted(true);
      setErrorMessage(''); // Clear any previous error message on success
      console.log(response);
    })
    .catch(error => {
      console.error(error);
      setErrorMessage('An error occurred. Please try again.'); // Set the error message
    });
  };

  return (
  <main id='contact-form' style={{position: 'relative'}}>
    <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/monkey1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
    <section id="contact" className="onlineTools contact-form">
      <div className="container" id="onlineTools">
          <>
          <CoverCard2 style={{position: 'relative'}}>
          {/* Insert Below */}
          <Card.Header as="h2" className="text-center fw-bold">Contact</Card.Header>
                {isSubmitted ? (
                  <Alert variant="success">Your message has been successfully submitted!</Alert>
                ) : (
                  <>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form onSubmit={handleSubmit}>
                      {/* Your form fields go here */}
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={contactForm.name}
                          placeholder="Enter name"
                          maxLength="200"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
  
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={contactForm.email}
                          placeholder="Enter email"
                          maxLength="200"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
  
                      <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          name="subject"
                          value={contactForm.subject}
                          placeholder="Enter subject"
                          maxLength="125"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
  
                      <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="message"
                          value={contactForm.message}
                          placeholder="Enter message"
                          maxLength="2500"
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                      <Button variant="success" type="submit" className="custom-button" size="sm">Submit</Button>
                      <Button variant="danger" type="button" onClick={handleReset} className="custom-button" size="sm">Reset</Button>
                    </Form>
                  </>
                )}
       </CoverCard2>
       {/* Space Filler */}
       <CoverCard2 className='mb-4' style={{position: 'relative'}}>
          {/* Insert Below */}
          <Card.Title className='text-center fw-bold' id="title">Questions, Comments, Concerns?</Card.Title>
          <p>If you have any questions about the site, how I created some of my content, need a quote on creating content, need to correct me on some of my featured content (hey, math is hard sometimes) or have an idea or suggestion on new content or features to add, feel free to send me a message. I try to respond to all requests and comments within 24 hours.</p>
          <Card.Title className='text-center fw-bold' id="title">Need to find me other places?</Card.Title>
          <div id='contact-header' className="contact-profile">
          <div className="social-links mt-3 text-center">
            <ul style={{ listStyleType: 'none' }} className='text-start'>
              <li className='p-1 contact-icons'><a href="https://github.com/Rpasher1987" className="github" target="_blank" rel="noreferrer"><BsGithub /></a><span className='ms-3'>GitHub</span></li>
              <li className='p-1 contact-icons'><a href="https://www.linkedin.com/in/rpasher1987/" className="linkedin" target="_blank" rel="noreferrer"><BsLinkedin /></a><span className='ms-3'>LinkedIn</span></li>
              <li className='p-1 contact-icons'><a href="https://www.facebook.com/RpAsher327" className="facebook" target="_blank" rel="noreferrer"><AiFillFacebook /></a><span className='ms-3'>Facebook</span></li>
              <li className='p-1 contact-icons'><a href="https://app.netlify.com/teams/rpasher1987/" className="netlify" target="_blank" rel="noreferrer"><DiNpm /></a><span className='ms-3'>Netlify</span></li>
            </ul>
          </div>
        </div>
       </CoverCard2>
          </>
        </div>
      </section>
    </main>
  );
};

export default ContactForm;