// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap-icons/font/bootstrap-icons.css"
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import React and ReactDOM
import React from 'react'
import ReactDOM from 'react-dom'

// Import your App component
import App from '@src/App'

Rails.start()
ActiveStorage.start()

// Render your App component
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App />,
    document.body.appendChild(document.createElement('div')),
  )
})
