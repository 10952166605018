import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import Layout from './_layout.jsx';
// import CoverCard from '.../_card.jsx';

// import '@src/creations/tools/components/components.scss';

const RealFV = () => {
   const [PV, setPV] = useState(10000);
   const [n, setN] = useState(5);
   const [r, setR] = useState(7);
   const [P, setP] = useState(50);
   const [f, setF] = useState(12);
   const [π, setPi] = useState(3.5);
   const [result, setResult] = useState({});
 
   const calculate = () => {
     const m = n * f;
     const i = r / (f * 100);
     const πr = π / (f * 100);
     const rrr = ((1 + i) / (1 + πr)) - 1;
     const compPV = PV * (1 + i) ** m;
     const compP = P * ((((1 + i) ** m) - 1) / i);
     const compPVReal = PV * (1 + rrr) ** m;
     const compPReal = P * ((((1 + rrr) ** m) - 1) / rrr);
     const result1 = (P === 0 || P === '') ? compPV : compPV + compP;
     const result2 = (P === 0 || P === '') ? compPVReal : compPVReal + compPReal;

     const rateOne = ((1 + (r/100))/(1 + (π/100))) - 1;
     const newRate = rateOne * 100;
 
     setResult({
       withoutInflation: result1.toFixed(2),
       withInflation: result2.toFixed(2),
       inflationReturn: newRate.toFixed(3)
     });
   }

 
   const reset = () => {
     setPV(10000);
     setN(5);
     setR(7);
     setP(50);
     setF(12);
     setPi(3.5);
     setResult({});
   }
 
   return (
      <>
      <Card.Title id="title">Inflation Adjusted Investment Return Calculator</Card.Title>
            <Form>
            <div className="row-container">
               <div className="row-item">
               {/* CONTENT BELOW THIS LINE */}
               <Form.Group controlId="PV">
                  <Form.Label>Investment Amount</Form.Label>
                  <NumericFormat
                     value={PV}
                     thousandSeparator={true} 
                     prefix={'$'} 
                     className="form-control"
                     onValueChange={(values) => {
                     const { value } = values;
                     setPV(parseFloat(value));
                     }}
                  />
               </Form.Group>
               </div>
               <div className="row-item">
               {/* CONTENT BELOW THIS LINE */}
               <Form.Group controlId="r">
                  <Form.Label>Rate (%)</Form.Label>
                  <NumericFormat
                     decimalScale={3}
                     suffix="%"
                     className="form-control"
                     value={r}
                     onValueChange={(values) => {
                     const { value } = values;
                     setR(parseFloat(value));
                     }}
                  />
               </Form.Group>
               </div>
               <div className="row-item">
               {/* CONTENT BELOW THIS LINE */}
               <Form.Group controlId="n">
                  <Form.Label>Time Period (years)</Form.Label>
                  <NumericFormat
                     value={n}
                     className="form-control"
                     onValueChange={(values) => {
                     const { value } = values;
                     setN(parseInt(value));
                     }}
                  />
               </Form.Group>
               </div>
               <div className="row-item">
               {/* CONTENT BELOW THIS LINE */}
               <Form.Group controlId="P">
                  <Form.Label>Contributions</Form.Label>
                  <NumericFormat
                     value={P}
                     thousandSeparator={true} 
                     prefix={'$'}
                     className="form-control"
                     onValueChange={(values) => {
                     const { value } = values;
                     setP(value === '' ? 0 : parseFloat(value));
                     }}
                  />
                  </Form.Group>
               </div>
            </div>
      
            <div className="row-container">
               <div className="row-item">
               <Form.Group controlId="f">
                  <Form.Label>Frequency</Form.Label>
                  <InputGroup>
                     <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title="Select frequency"
                        id="dropdown-f"
                     >
                        <Dropdown.Item value={52} onClick={() => setF(52)}>Weekly</Dropdown.Item>
                        <Dropdown.Item value={26} onClick={() => setF(26)}>Bi-weekly</Dropdown.Item>
                        <Dropdown.Item value={12} onClick={() => setF(12)}>Monthly</Dropdown.Item>
                        <Dropdown.Item value={4} onClick={() => setF(4)}>Quarterly</Dropdown.Item>
                        <Dropdown.Item value={2} onClick={() => setF(2)}>Semi-annually</Dropdown.Item>
                        <Dropdown.Item value={1} onClick={() => setF(1)}>Annually</Dropdown.Item>
                     </DropdownButton>
                     <Form.Control
                        type="text"
                        readOnly
                        value={f === 52 ? 'Weekly' : f === 26 ? 'Bi-weekly' : f === 12 ? 'Monthly' : f === 4 ? 'Quarterly' : f === 2 ? 'Semi-annually' : f === 1 ? 'Annually' : ''}
                     />
                  </InputGroup>
               </Form.Group>
               </div>
               <div className="row-item">
               <Form.Group controlId="π">
                  <Form.Label>Expected Inflation rate (%)</Form.Label>
                  <NumericFormat
                  decimalScale={3}
                  thousandSeparator={true}
                  suffix="%"
                  className="form-control"
                  value={π}
                  onValueChange={(values) => {
                     const { value } = values;
                     setPi(value === '' ? 0 : parseFloat(value));
                  }}
                  />
               </Form.Group>
               </div>
            </div>
            <Button variant="success" onClick={calculate} className="custom-button" size="sm">Calculate</Button>
            <Button variant="danger" type="button" onClick={reset} className="custom-button" size="sm">Reset </Button>
            {/* <Button variant="primary" onClick={calculate}>Calculate</Button>
            <Button variant="secondary" onClick={reset}>Clear</Button> */}
         </Form>
      
         {result.withoutInflation !== undefined && result.withInflation !== undefined && (
      <Alert variant="success">
      <NumericFormat value={PV} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> invested today compounded with your target return of {r}% would give you <NumericFormat value={result.withoutInflation} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> after {n} years{' '}  
         {π !== 0 ? (
               <>
               however, erosion of your buying power due to inflation adjusts this to <NumericFormat value={result.withInflation} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in future dollars, with a Real Rate of Return of {result.inflationReturn}% after factoring in your expected future rise in prices.
               </>
          ) : (
               <>
                in the unlikely event you did not have inflation between now and that time.
               </>
         )}
      </Alert>
      )}
   </>
   );
}

export default RealFV;