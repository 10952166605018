import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Card } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const MortgageCalculator = () => {
    const [homeValue, setHomeValue] = useState(500000);
    const [downPayment, setDownPayment] = useState(50000);
    const [interestRate, setInterestRate] = useState(4.5);
    const [termYears, setTermYears] = useState(20);
    const [schedule, setSchedule] = useState([]);
    const [totalInterestPaid, setTotalInterestPaid] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [monthlyPayment, setMonthlyPayment] = useState(0);  // Add this line

    const resetFields = () => {
        setHomeValue(0);
        setDownPayment(0);
        setInterestRate(0);
        setTermYears(0);
        setTotalInterestPaid(0);
        setTotalPaid(0);
        setMonthlyPayment(0);  // Add this line
        setSchedule([]);
    };

    const calculateSchedule = (e) => {
      e.preventDefault();
      const P = homeValue - downPayment;
      const i = (interestRate / 100) / 12;
      const n = termYears * 12;
      const M = P * (i * Math.pow((1 + i), n)) / (Math.pow((1 + i), n) - 1);

      let schedule = [];
      let remainingBalance = P;
      let totalInterestPaid = 0;
  
      for (let period = 1; period <= n; period++) {
          let interestPayment = remainingBalance * i;
          let principalPayment = M - interestPayment;
          remainingBalance -= principalPayment;
          totalInterestPaid += interestPayment;
  
          schedule.push({
              period: period,
              beginningBalance: remainingBalance + principalPayment, // Calculate beginning balance based on remaining balance and principal payment
              payment: M,
              interestPayment: interestPayment,
              principalPayment: principalPayment,
              endingBalance: remainingBalance
          });
  
          if (remainingBalance <= 0) {
              break;
          }
      }
      let totalPaid = totalInterestPaid + P;
  
      setMonthlyPayment(M);  // Add this line
      setSchedule(schedule);
      setTotalInterestPaid(totalInterestPaid);
      setTotalPaid(totalPaid);
    };

    return (
      <React.Fragment>
        <Layout>
          <CoverCard>
              <Card.Title id='title'>Mortgage Calculator</Card.Title>
                <Form onSubmit={calculateSchedule}>
                <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formHomeValue" className="mb-4">
                        <Form.Label className="mb-1">Home Value:</Form.Label>
                        <NumericFormat 
                          thousandSeparator={true} 
                          prefix={'$'} 
                          className="form-control" 
                          value={homeValue} 
                          onValueChange={(values) => {
                          const { floatValue } = values;
                          setHomeValue(floatValue);
                          }} 
                        />
                    </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formDownPayment" className="mb-4">
                        <Form.Label className="mb-1">Down Payment:</Form.Label>
                        <NumericFormat 
                          thousandSeparator={true} 
                          prefix={'$'} 
                          className="form-control" 
                          value={downPayment} 
                          onValueChange={(values) => {
                          const { floatValue } = values;
                          setDownPayment(floatValue);
                          }} 
                        />
                    </Form.Group>
                    </div>
                  </div>
                  <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formInterestRate" className="mb-4">
                    <Form.Label className="mb-1">Interest Rate:
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                        Your expected or offered mortgage interest rate
                        </Tooltip>
                        )}
                    >
                    <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                    </OverlayTrigger>
                    </Form.Label>
                    <NumericFormat 
                        decimalScale={2}
                        suffix={'%'} 
                        className="form-control" 
                        value={interestRate} 
                        onValueChange={(values) => {
                        const { floatValue } = values;
                        setInterestRate(floatValue);
                        }} 
                    />
                  </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formTermYears" className="mb-4">
                        <Form.Label className="mb-1">Term (Years):</Form.Label>
                        <NumericFormat 
                          className="form-control" 
                          value={termYears} 
                          onValueChange={(values) => {
                          const { floatValue } = values;
                          setTermYears(floatValue);
                          }} 
                        />
                    </Form.Group>
                    </div>
                  </div>
                  <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="monthlyPayment" className="mb-4">
                        <Form.Label className="mb-1">Monthly Payment:</Form.Label>
                        <Form.Control type="text" readOnly value={'$' + Number(monthlyPayment.toFixed(2)).toLocaleString()} />
                    </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="totalInterestPaid" className="mb-4">
                        <Form.Label className="mb-1">Total Interest Paid:</Form.Label>
                        <Form.Control type="text" readOnly value={'$' + Number(totalInterestPaid.toFixed(2)).toLocaleString()} />
                    </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="totalPaid" className="mb-4">
                        <Form.Label className="mb-1">Total Paid <small><i>(Principal + Interest)</i></small></Form.Label>
                        <Form.Control type="text" readOnly value={'$' + Number(totalPaid.toFixed(2)).toLocaleString()} />
                    </Form.Group>
                    </div>
                  </div>

                    <Button variant="success" type="submit" className="custom-button" size="sm">
                    Calculate
                  </Button>
                  <Button variant="danger" type="button" onClick={resetFields} className="custom-button" size="sm">
                    Reset
                  </Button>
                </Form>
    
                <Table striped bordered hover responsive="sm" size="sm" border="dark">
                    <thead className='text-center mortgage-header' id='mortgage-calc-table-1'>
                        <tr>
                            <th>Period (Months)</th>
                            <th>Beginning Balance</th>
                            <th>Payment</th>
                            <th>Interest Payment</th>
                            <th>Principal Payment</th>
                            <th>Ending Balance</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {schedule.map((item, index) =>
                            <tr key={index}>
                                <td>{item.period}</td>
                                <td>${Number(item.beginningBalance.toFixed(2)).toLocaleString()}</td>
                                <td>${Number(item.payment.toFixed(2)).toLocaleString()}</td>
                                <td>${Number(item.interestPayment.toFixed(2)).toLocaleString()}</td>
                                <td>${Number(item.principalPayment.toFixed(2)).toLocaleString()}</td>
                                <td>${Number(item.endingBalance.toFixed(2)).toLocaleString()}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
          </CoverCard>
          {/* Space Filler */}
          <CoverCard>
            {/* Insert Below */}
            <Card.Title className='fw-bold'>How The Mortgage Calculator Works</Card.Title>
            <p>
              This calculator allows users to understand the monthly repayment and the breakdown of the payments of a fixed-rate mortgage loan. It provides a detailed repayment schedule over the course of the loan term.
            </p>
            <p>
              A mortgage is a loan that is used to finance the purchase of property or real estate. The borrower is obligated to make a predetermined set of payments to repay the loan over a set term. A portion of each payment goes towards the loan principal, and the remainder is used to pay interest accrued on the loan. Over time, the interest portion decreases, and the principal portion increases.
            </p>
            <p>
              The formula for calculating the monthly payment (M) is:
            </p>
            <ul>
              <li><code> M = P * (i * (1 + i) ^ n) / ((1 + i) ^ n - 1) </code></li>
            </ul>
            <p>Where:</p>
              <ul>
                <li>P is the Principal loan amount</li>
                <li>i is the monthly interest rate (annual rate divided by 12)</li>
                <li>n is the number of payments (months)</li>
              </ul>
            <p>
              For every period (month) during the term of the loan, the mortgage payment is divided into two parts: principal and interest. The following calculations are made for each period:
            </p>
            <p>
              <b>Interest Payment:</b> The interest payment for a particular period is calculated as the remaining balance at the start of the period multiplied by the monthly interest rate.
            </p>
            <ul>
              <li><code>Interest Payment = Remaining Balance * i</code></li>
            </ul>
            <p>
              <b>Principal Payment:</b> The principal payment is calculated as the total monthly payment minus the interest payment for that period.
            </p>
            <ul>
              <li><code>Principal Payment = M - Interest Payment</code></li>
            </ul>
            <p>
              <b>Ending Balance:</b> The ending balance for a particular period is calculated as the remaining balance at the start of the period minus the principal payment for that period.
            </p>
            <ul>
              <li><code>Ending Balance = Remaining Balance - Principal Payment</code></li>
            </ul>
            <p>
              For the following period, the beginning balance will be the ending balance of the previous period, and the same set of calculations are performed until the term of the loan is complete. This gives a detailed schedule of how the total mortgage payment is divided into principal and interest payments, and how the loan balance decreases over time.
            </p>
            <p>
              This Mortgage Calculator takes the Home Value, Down Payment, Interest Rate, and Term (Years) as inputs, and calculates the monthly repayment amount based on the above formula. It also generates a repayment schedule, detailing each payment, principal payment, interest payment, and the balance for each period. The user can reset all fields using the "Reset" button.
            </p>
            <div>
              <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/mortgage_calc.png" />
            </div>

          </CoverCard>
      </Layout>
    </React.Fragment>
    );
};

export default MortgageCalculator;