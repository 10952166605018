import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const CompoundInterest = () => {
  const [showAlert, setShowAlert] = useState(false); // Added to control visibility of alert
  const [initialInvestment, setInitialInvestment] = useState(15000);
  const [periodicInvestment, setPeriodicInvestment] = useState(250);
  const [periodicFrequency, setPeriodicFrequency] = useState('Monthly');
  const [interestRate, setInterestRate] = useState(7);
  const [periods, setPeriods] = useState(5);
  const [periodType, setPeriodType] = useState('Monthly');
  const [compoundInterest, setCompoundInterest] = useState(0);

  const calculateCompoundInterest = (e) => {
   e.preventDefault();
 
   let p = Number(initialInvestment); // Initial investment amount
   let r = Number(interestRate) / 100; // convert interest rate to a decimal
   let t = Number(periods); // periods to compound
   let periodicPmt = Number(periodicInvestment); // periodic investment
   
   let n;
   switch (periodType) {
     case 'Daily':
       n = 365;
       break;
     case 'Monthly':
       n = 12;
       break;
     case 'Annually':
       n = 1;
       break;
     default:
       n = 12; // default to monthly
   }
 
   // calculate future value of the initial investment
   let FV_initial = p * Math.pow(1 + r/n, n*t);
   FV_initial = Number(FV_initial.toFixed(2));
 
   // calculate future value of series for periodic investments
   let FV_periodic = 0;
   if (r !== 0) {
     FV_periodic = periodicPmt * ((Math.pow(1 + r/n, n*t) - 1) / (r/n));
     FV_periodic = Number(FV_periodic.toFixed(2));
   } else {
     FV_periodic = periodicPmt * n * t;
     FV_periodic = Number(FV_periodic.toFixed(2));
   }
 
   let FV_total = FV_initial + FV_periodic;
   setCompoundInterest(FV_total.toFixed(2));
   setShowAlert(true); // Set the showAlert to true to display the alert
 }
 
 
 
  const clearForm = () => {
    setInitialInvestment(15000);
    setPeriodicInvestment(250);
    setPeriodicFrequency('Monthly');
    setInterestRate(7);
    setPeriods(5);
    setPeriodType('Monthly');
    setCompoundInterest(0);
    setShowAlert(false);
  }

  return (
    <React.Fragment>
    <Layout>
       <CoverCard>
          {/* Insert Below */}
          <Card.Title id="title">Compound Interest Rate Calculator</Card.Title>
            {/* INSERT BELOW */}
            <Form onSubmit={calculateCompoundInterest}>
            <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                      <Form.Group controlId="formInitialInvestment">
                        <Form.Label>Initial Investment ($):</Form.Label>
                        <NumericFormat 
                        thousandSeparator={true} 
                        prefix={'$'} 
                        className="form-control" 
                        value={initialInvestment} 
                        onValueChange={(values) => {
                            const { value } = values;
                            setInitialInvestment(parseFloat(value));
                        }} 
                        />
                      </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formPeriodicInvestment">
                      <Form.Label>Periodic Investment ($):</Form.Label>
                      <NumericFormat 
                      thousandSeparator={true} 
                      prefix={'$'} 
                      className="form-control" 
                      value={periodicInvestment} 
                      onValueChange={(values) => {
                          const { value } = values;
                          setPeriodicInvestment(parseFloat(value));
                      }} 
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formInterestRate">
                        <Form.Label>Rate or APR (%):</Form.Label>
                        <NumericFormat 
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={'%'} 
                        className="form-control"
                        value={interestRate} 
                        onValueChange={(values) => {
                            const { value } = values;
                            setInterestRate(parseFloat(value));
                        }} 
                        />
                      </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formPeriods">
                      <Form.Label>Periods (years):
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                Enter the period in years. If the period is less than a year, 
                                enter it as a fraction of a year. For example, for 6 months, 
                                enter 0.5. This field controls the overall period of the calculation.
                            </Tooltip>
                            )}
                          >
                            <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                          </OverlayTrigger>
                      </Form.Label>
                      <Form.Control type="number" value={periods} onChange={e => setPeriods(parseFloat(e.target.value))} />
                      </Form.Group>
                  </div>
                </div>

                <div className="row-container">
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formPeriodicFrequency">
                      <Form.Label>Periodic Frequency:</Form.Label>
                      <Form.Check 
                          type="radio"
                          id="monthly"
                          label="Monthly"
                          checked={periodicFrequency === 'Monthly'}
                          onChange={() => setPeriodicFrequency('Monthly')}
                      />
                      <Form.Check 
                          type="radio"
                          id="annually"
                          label="Annually"
                          checked={periodicFrequency === 'Annually'}
                          onChange={() => setPeriodicFrequency('Annually')}
                      />
                      </Form.Group>
                  </div>
                  <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formPeriodType">
                      <Form.Label>APR(%) Frequency</Form.Label>
                      <Form.Check 
                      type="radio"
                      id="daily"
                      label="Daily"
                      checked={periodType === 'Daily'}
                      onChange={() => setPeriodType('Daily')}
                      />
                      <Form.Check 
                      type="radio"
                      id="monthly"
                      label="Monthly"
                      checked={periodType === 'Monthly'}
                      onChange={() => setPeriodType('Monthly')}
                      />
                      <Form.Check 
                      type="radio"
                      id="annually"
                      label="Annually"
                      checked={periodType === 'Annually'}
                      onChange={() => setPeriodType('Annually')}
                      />
                    </Form.Group>
                  </div>
                </div>
                <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
                <Button variant="danger" type="button" onClick={clearForm} className="custom-button" size="sm">Reset</Button>
                {showAlert && (
                    <Alert variant="success" className="mt-3">
                    Using Compound Interest Rate, your initial investment of <NumericFormat value={initialInvestment} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> would now be <NumericFormat value={compoundInterest} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />.
                    </Alert>
                )}
            </Form>
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title>Compound Interest</Card.Title>
          <p>
            Compound interest is the interest on a loan or deposit calculated based on both the initial principal and the accumulated interest from previous periods. It is thought to have been the concept that led to the phrase, "Money earns money."
          </p>
          <p>
            The formula used for the calculation is:
            <br/>
            <code>FV = P(1 + i/n)^(nt) + Pmt[(1 + i/n)^(nt) - 1] / (i/n)</code>
            <br/>
            where,
          </p>
          <ul>
            <li>P is the initial principal (starting amount)</li>
            <li>Pmt is the periodic investment</li>
            <li>i is the annual interest rate (APR)</li>
            <li>n is the number of times interest applied per time period</li>
            <li>t is the number of time periods the money is invested for</li>
            <li>FV is the future value of the investment</li>
          </ul>
          <p>
            After entering these parameters, the calculator determines the Compound Interest Rate defined by the inputs.
          </p>
          <div>
            <Card.Img variant="top" src="/files/images/compound_interest_rate.png" />
          </div>
       </CoverCard>
    </Layout>
    </React.Fragment>
   );
 };

export default CompoundInterest;