import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import '@src/home.scss';

const FullStack = (props) => {
   return(
      <React.Fragment>
        <Accordion.Header><b>Full-Stack Web Development</b></Accordion.Header>
        <Accordion.Body>
           {/* new card group */}
           <CardGroup>
                 <Card>
                 <a href="https://github.com/Rpasher1987/fswd-airbnb-clone" target="_blank" rel="noreferrer">
                 <Card.Img variant="top" src="/files/images/fswd-airbnb.jpg" />
                 </a>
                 <Card.Body>
                    <Card.Title>Airbnb Clone (Full-Stack)</Card.Title>
                    <Card.Text>
                       Fully functional Airbnb clone to view and select properties, sign-up & login, book properties with check-in and check-out dates, upload photos, and make payments via Stripe.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">Click link to view code on Github</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://github.com/Rpasher1987/fswd-twitter-clone" target="_blank" rel="noreferrer">
                    <Card.Img variant="top" src="/files/images/fswd-twitter.jpg" />
                    </a>
                    <Card.Body>
                    <Card.Title>Twitter Clone (Full-Stack)</Card.Title>
                    <Card.Text>
                       Fully functional clone with login/signup, profile page, user feed with photo upload. Made with React, JavaScript, Ruby & Rails 6
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                    <small className="text-muted">Click to view code on Github</small>
                    </Card.Footer>
                    </Card>      
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://endearing-raindrop-68f6a8.netlify.app/" target="_blank" rel="noreferrer">
                    <Card.Img variant="top" src="/files/images/fe-blackjack.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>BlackJack Application (Front-End)</Card.Title>
                    <Card.Text>
                       Front-end BlackJack Game made with JavaScript & JQuery.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">click the photo to play at Netlify</small>
                 </Card.Footer>
                 </Card>
                 {/* end cards */}
              </CardGroup>
              {/* end card group */}

              <hr/>

              {/* new card group */}
              <CardGroup>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://thriving-torte-8911b4.netlify.app" target="_blank" rel="noreferrer">
                    <Card.Img variant="top" src="/files/images/fe-onlinecart.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>Online Shopping Cart (Front-End)</Card.Title>
                    <Card.Text>
                       Front-end Shopping Cart made with Javascript & JQuery.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">click the picture to checkout at Netlify</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://transcendent-griffin-7ff9d8.netlify.app/" target="_blank" rel="noreferrer">
                    <Card.Img variant="top" src="/files/images/fe-mathgame.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>Math Game Application (Front-End)</Card.Title>
                    <Card.Text>
                       Play the computer in this 10 second math game application made with React, Javascript & JQuery.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">click the picture to play at Netlify</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                    <a href="https://objective-spence-39fc50.netlify.app" target="_blank" rel="noreferrer">
                       <Card.Img variant="top" src="/files/images/fe-apple.png" />
                    </a>
                    <Card.Body>
                       <Card.Title>Apple Website Clone (Front-End)</Card.Title>
                       <Card.Text>
                          Front-end Apple store clone using HTML, CSS, & Javascript.
                       </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                       <small className="text-muted">click the photo to checkout on Netlify</small>
                    </Card.Footer>
                    </Card>
                 {/* end cards */}
              </CardGroup>
              {/* end card group */}
        </Accordion.Body>
      </React.Fragment>
   );
}

export default FullStack;