import React from 'react';
// import motorcycle from '@src/images/motorcycle';

import '@src/resume.scss';

class Resume extends React.Component {
  render () {
    return (
      <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
        <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/motorcycle.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
        <section id="resume" className="resume">
        <div className="container">
          <div className="section-title">
            <h2 className='fw-bold'>Resume</h2>
            <p>Performance-driven executive with experience in financial and investment management. Skilled in monitoring and evaluating compliance with regulations, policies, and standards as well as planning and executing fully considered programs to meet marketing plan objectives. Proven track record of managing and delivering complex and large-scale regulatory and discretionary change projects, leading contract negotiations with third-party suppliers, and increasing value for customers and shareholders. Skilled in generating and securing investment opportunities, structuring equity investments and loans, and overseeing evaluation, due diligence, and closing process in portfolios acquisition.  Adaptable to ever-changing demand of FinTech with a track record of enhancing technological and operational capabilities and efficiency of the firm.</p>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <h3 className="resume-title">Summary</h3>
              <div className="resume-item pb-0">
                <h4>Ryan Asher</h4>
                <p><em>Improving bottom-line and enabling the achievements of full business potential by delivering strategic leadership.</em></p>
                <p>Throughout my career I have been a goal-focused and results-driven professional with substantial experience in leading all facets of financial planning and analysis, profit and revenue optimization, portfolio management, business strategy development, contract negotiations, investment planning/execution, and stakeholder engagement, while ensuring attainment of set organizational financial objectives with unequalled success.</p>
                <ul>
                  <li>Charleston, SC</li>
                  <li>ryan@rpasher.com</li>
                </ul>
              </div>

              <h3 className="resume-title">Education</h3>
              <div className="resume-item">
                <h4>Bachelor of Science in Business Administration</h4>
                <h6><em>Dual Concentration in Finance & Entrepreneurship</em></h6>
                <h5>2016 - 2018</h5>
                <p><em>College of Charleston, Charleston, SC</em></p>
                <p>President of the Finance Club from 2016-2018. Performed various functions, such as organizing club meeting, steering simulated stock trading competitions, and hosting speakers from various financial firms. Established and managed social media platforms to engage new and existing members, as well as moderated career panels.</p>
              </div>
              <div className="resume-item">
                <h4>Full-Stack Web Development</h4>
                <h5>2021 - 2023</h5>
                <p><em>Altcademy</em></p>
                <p>Full-stack development with certificates earned in HTML & CSS, Javascript Programming, Dynamic Website Development, ReactJS Development, Ruby Programming, Ruby on Rails, and Full-stack Deployment.</p>
              </div>
              {/*  */}
              <h3 className="resume-title">Technical Skills</h3>
              <div className="resume-item">
                <h4>Financial Management Tools</h4>
                <p>ORION, Eclipse, Astro, Kwanti, Salesforce (Salentica), Microsoft Excel, Microsoft Word, Redtail, Riskalyze, iAdvisor, eMoney, MorningStar, iRebal, Adhesion</p>
              </div>
              <div className="resume-item">
                <h4>Web Development</h4>
                <p>HTML, CSS, SQL, PHP, JavaScript, ReactJS, Ruby, Ruby on Rails, Github, Heroku, Kompozer, GIT, Ubuntu, MySQL, WAMP, Filezilla</p>
              </div>
              <div className="resume-item">
                <h4>Financial Modeling</h4>
                <p>VBA Macros, Pivot Tables, Goal Seek with Microsoft Excel</p>
              </div>
              {/*  */}
              <h3 className="resume-title">Professional Certifications</h3>
              <div className="resume-item">
              <ul>
                  <li>Investment Advisor Representative – Series 66, 2020</li>
                  <li>Full Stack Web Development, Altcademy – 2021-2023</li>
                  <li>South Carolina Notary Public, 2020</li>
                  <li>SC Life, Accident and Health Insurance Producer – Series 19-03, 2019</li>
                  <li>Microsoft Office Specialist (MOS) in Excel Expert, 2019, 2021</li>
                  <li>Investment Foundation Certificate from CFA Institute, 2018</li>
                </ul>
              </div>
              {/*  */}
            </div>
            {/*  */}
            
            <div className="col-lg-6">
              <h3 className="resume-title">Professional Experience</h3>
              <div className="resume-item">
                <h4>Senior Trader</h4>
                <h5>2022 - Present</h5>
                <p><em>CG Advisor Network, Charlotte, NC </em></p>
                <p>Directed the firms trading operations and implemented custom strategies for high-net-worth clients. Optimized technological and operational capabilities and efficiency of the firm. Planned, constructed, & administered investment models for use across the multiple RIAs our firm serviced. Created website to distribute content for the firms we work with around the country. Analyzed securities and made proposals as a member of the Investment Committee analyst team.</p>
                <ul>
                  <li>Lead the transition for moving the firms trading operations externally from Envestnet to manage internally with ORION.</li>
                  <li>Streamlined portfolio management process through collaboration with team to enhance clients experience.</li>
                  <li>Analyzed potential investments and provided reports as Investment Committee member.</li>
                  <li>Utilized various tools for trading, such as sleeve trading with ECLIPSE and ORION, and iRebal for model integration and portfolio management.</li>
                  <li>Created website to host monthly performance updates and webcasts, provide information on our models and strategies, and distribute content to Advisors around the country.</li>
                </ul>
              </div>
              {/*  */}
              <div className="resume-item">
                <h4>Director of Trading, Research, & Development</h4>
                <h5>2020 - 2022</h5>
                <p><em>Curran Financial Partners, Charleston, SC</em></p>
                <p>Oversaw routine activities related to research and development of strategic initiatives for improvement of firm growth & market reach. Headed efficient development, management, and execution of strategies to boost firms' growth and increase product offerings.</p>
                <ul>
                  <li>Improved firm capabilities by creating tools for detecting portfolio overlaps and legacy positions within clients’ accounts.</li>
                  <li>Developed and managed databases to track marketing efforts, firm production, and required minimum distributions.</li>
                  <li>Constructed models and implemented investment strategies in client accounts.</li>
                  <li>Regularly traded with ECLIPSE and ORION, in addition to Adhesion, iRebal, and ThinkPipes.</li>
                </ul>
              </div>
              {/*  */}
              <div className="resume-item">
                <h4>Financial Professional</h4>
                <h5>2019 - 2020</h5>
                <p><em>Mass Mutual, Charleston, SC</em></p>
                <ul>
                  <li>Recognized as “Rising Leader" in first three months by devising and developing robust financial plans. </li>
                </ul>
              </div>
              {/*  */}
              <div className="resume-item">
                <h4>Additional Experience</h4>
                <p><em>Sales Representative</em> at KPI Capital Markets in Queensland, Australia.</p>
                <p><em>Sales Representative</em> at US Invest in Queensland, Australia.</p>
                <p><em>Financial Professional</em> at Securus in Myrtle Beach, SC.</p>
              </div>
              {/*  */}
              <h3 className="resume-title">Areas of Expertise</h3>
              <div className="resume-item">
              <ul>
                  <li>Financial/Investment Planning & Analysis</li>
                  <li>Profit & Revenue Margins Optimization</li>
                  <li>Problem solving & operational efficiency</li>
                  <li>Business Strategy Development </li>
                  <li>Operational Planning & Execution </li>
                  <li>Team Collaboration & Leadership</li>
                  <li>Full Stack Web Development</li>
                  <li>Negotiation & Communication</li>
                  <li>Operational Planning & Execution </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
    )
  }
}

export default Resume
