import React, { useState } from 'react';
import { Alert, Button, Card, Form, Collapse, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const APYCalculator = () => {
  const [interestRate, setInterestRate] = useState(3.75);
  const [compoundingFrequency, setCompoundingFrequency] = useState('monthly');
  const [apy, setAPY] = useState(0);

  const frequencies = {
    daily: 365,
    weekly: 52,
    biweekly: 26,
    monthly: 12,
    quarterly: 4,
    annually: 1
  };

  const calculateAPY = (e) => {
    e.preventDefault();
    const rate = interestRate / 100;
    const n = frequencies[compoundingFrequency];
    const apy = ((1 + (rate / n)) ** n) - 1;
    setAPY((apy * 100).toFixed(3));
  };

  const resetForm = () => {
    setInterestRate(0);
    setCompoundingFrequency('monthly');
    setAPY(0);
  };

  return (
      <React.Fragment>
         <Layout>
            <CoverCard>
               {/* Insert Below */}
               <Card.Title id='title'>APR to APY Calculator</Card.Title>
                  <Form onSubmit={calculateAPY}>
                  <Form.Group controlId="interestRate">
                     <Form.Label>Interest Rate (APR %):</Form.Label>
                     <NumericFormat
                        decimalScale={3}
                        suffix="%"
                        className="form-control"
                        value={interestRate}
                        onValueChange={(values) => {
                        const { value } = values;
                        setInterestRate(parseFloat(value));
                        }}
                     />
                  </Form.Group>

                  <Form.Group controlId="compoundingFrequency">
                     <Form.Label>Compounding Frequency:</Form.Label>
                     <div>
                        <Form.Check
                        type="radio"
                        id="daily"
                        label="Daily"
                        value="daily"
                        checked={compoundingFrequency === 'daily'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="weekly"
                        label="Weekly"
                        value="weekly"
                        checked={compoundingFrequency === 'weekly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="biweekly"
                        label="Bi-Weekly"
                        value="biweekly"
                        checked={compoundingFrequency === 'biweekly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="monthly"
                        label="Monthly"
                        value="monthly"
                        checked={compoundingFrequency === 'monthly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="quarterly"
                        label="Quarterly"
                        value="quarterly"
                        checked={compoundingFrequency === 'quarterly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="annually"
                        label="Annually"
                        value="annually"
                        checked={compoundingFrequency === 'annually'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                     </div>
                  </Form.Group>

                  <Button variant="success" type="submit" className="custom-button" size="sm">
                     Calculate
                  </Button>
                  <Button variant="danger" type="button" onClick={resetForm} className="custom-button" size="sm">
                     Reset
                  </Button>

                  <Card.Text>
                     APY (Annual Percentage Yield): {apy}%
                  </Card.Text>
                  </Form>
            </CoverCard>
            <CoverCard>
               {/* Insert Below */}
               <Card.Title className='fw-bold'>APR to APY Calculator</Card.Title>
            <p>
               This calculator allows users to input an interest rate in APR (Annual Percentage Rate) and select the compounding frequency. The compounding frequency options are represented by radio buttons: daily, weekly, bi-weekly, monthly, quarterly, and annually. By default, "Monthly" is selected.
            </p>
            <p>
               When you click the "Calculate" button, the calculator uses the provided interest rate and compounding frequency to convert the APR to APY (Annual Percentage Yield) using the following formula:
            </p>
            <p className="fw-bold">
               APY Formula:
               <br />
               <code>APY = ((1 + (r/n))^n) - 1</code>
            </p>
            <p>
               Where:
            </p>
            <ul>
               <li>APY: Annual Percentage Yield</li>
               <li>r: Annual interest rate (as a decimal)</li>
               <li>n: Number of compounding periods per year</li>
            </ul>
            <p>
               APR represents the interest rate charged on a loan or investment over one year without considering compounding. APY, on the other hand, accounts for the effect of compounding by considering the annual yield including the effects of interest earning on interest. APY provides a more accurate representation of the true return on an investment.
            </p>
            <p>
               The calculator takes the APR and applies the chosen compounding frequency to calculate the APY. The resulting APY is displayed as a percentage to three decimal places.
            </p>
            <p>
               The "Reset" button clears the form, allowing you to enter new values.
            </p>
            <div>
               <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/apr_to_apy.png" alt="APR to APY" />
            </div>
            </CoverCard>
         </Layout>
   </React.Fragment>
  );
};

export default APYCalculator;
