import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const FutureValue = () => {
  const [showAlert, setShowAlert] = useState(false); // Added to control visibility of alert
  const [principal, setPrincipal] = useState(25000);
  const [rate, setRate] = useState(5);
  const [years, setYears] = useState(4);
  const [compoundsPerYear, setCompoundsPerYear] = useState(12);
  const [futureValue, setFutureValue] = useState(0);

  const calculateFutureValue = (e) => {
    e.preventDefault();
    let r = rate / 100; // convert to decimal
    let n = compoundsPerYear;
    let t = years;
    let futureValue = principal * Math.pow((1 + r/n), n*t);
    setFutureValue(futureValue.toFixed(2));
    setShowAlert(true); // Set the showAlert to true to display the alert
  }

  const clearForm = () => {
    setPrincipal(25000);
    setRate(5);
    setYears(4);
    setCompoundsPerYear(12);
    setFutureValue(0);
    setShowAlert(false); // Set the showAlert to true to display the alert
  }

  return (
    <React.Fragment>
    <Layout>
       <CoverCard>
          {/* Insert Below */}
          <Card.Title id="title">Future Value Calculator</Card.Title>
               {/* Principal */}
             <Form onSubmit={calculateFutureValue}>
             <div className="row-container">
                  <div className="row-item">
                     {/* CONTENT BELOW THIS LINE */}
                     <Form.Group controlId="formPrincipal" className="mb-4">
                        <Form.Label className="mb-1">Present Value:</Form.Label>
                        <NumericFormat 
                           thousandSeparator={true} 
                           prefix={'$'} 
                           className="form-control" 
                           value={principal} 
                           onValueChange={(values) => {
                           const { value } = values;
                           setPrincipal(parseFloat(value));
                           }} 
                        />
                        </Form.Group>
                  </div>
                     <div className="row-item">
                     {/* Annual Interest Rate */}
                     <Form.Group controlId="formRate" className="mb-4">
                        <Form.Label>Rate (%):
                           <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => (
                              <Tooltip id="button-tooltip" {...props}>
                                    The rate you expect or desire to receive on your investment
                              </Tooltip>
                              )}
                           >
                              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                        </Form.Label>
                              <NumericFormat 
                              decimalScale={2}
                              suffix={'%'} 
                              className="form-control" 
                              value={rate} 
                              onChange={(values) => {
                              const { value } = values;
                              setRate(parseFloat(value));
                              }} 
                              />
                        </Form.Group>
                  </div>
                  <div className="row-item">
                        {/* Number of Years */}
                        <Form.Group controlId="formYears" className="mb-4">
                        <Form.Label>Periods (years):</Form.Label>
                        <Form.Control type="number" value={years} onChange={e => setYears(e.target.value)} />
                        </Form.Group>
                  </div>
               </div>
         {/* Compounds per Year */}
         <Form.Group controlId="formCompoundsPerYear" className="mb-4">
            <Form.Label>Compounding frequency
               <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={props => (
                  <Tooltip id="button-tooltip" {...props}>
                  The Number of times interest applied per time period; (12 = 12x per year; 1 = 1x per year;  while 4 = 4x per year [qtrly dividends for example])
                  </Tooltip>
                  )}
               >
               <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
               </OverlayTrigger>
         </Form.Label>
            <Form.Check 
               type="radio" 
               label="Monthly" 
               name="compoundsPerYear" 
               value={12} 
               defaultChecked 
               onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
            />
            <Form.Check 
               type="radio" 
               label="Quarterly" 
               name="compoundsPerYear" 
               value={4} 
               onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
            />
            <Form.Check 
               type="radio" 
               label="Bi-Annually" 
               name="compoundsPerYear" 
               value={2} 
               onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
            />
            <Form.Check 
               type="radio" 
               label="Annually" 
               name="compoundsPerYear" 
               value={1} 
               onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
            />
         </Form.Group>
               <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
               <Button variant="danger" type="button" onClick={clearForm} className="custom-button" size="sm">Reset</Button>
               {showAlert && (
               <Alert variant="success" className="mt-3">
                     The Future Value is <NumericFormat value={futureValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
               </Alert>
               )}
         </Form>
         {/* INSERT ABOVE */}
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title className='fw-bold'>Future Value Calculator</Card.Title>
          <p>
             The Future Value Calculator helps you estimate the future value of an investment based on the initial principal, annual interest rate, number of years, and the number of times interest is compounded per year.
          </p>
          <p>
             The calculation for future value is based on the compound interest formula:
          </p>
          <p className="fw-bold">
             Future Value:
             <br />
             <code>FV = Principal * (1 + (Rate / CompoundsPerYear))^(CompoundsPerYear * Years) </code>
          </p>
          <p>
             The calculator takes the following inputs:
          </p>
          <ul>
             <li>Initial Investment (Principal): The amount of money you initially invest.</li>
             <li>Annual Interest Rate: The annual interest rate on the investment.</li>
             <li>Number of Years: The number of years the investment will compound.</li>
             <li>Number of Times Interest Applied per Time Period: The number of times the interest is compounded per year (e.g., quarterly, monthly).</li>
          </ul>
          <p>
             After entering these parameters, the calculator determines the future value defined by the inputs.
          </p>
          <div>
             <Card.Img variant="top" src="/files/images/future_value.png" />
          </div>
       </CoverCard>
    </Layout>
    </React.Fragment>
  );
};

export default FutureValue;