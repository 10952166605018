import React from 'react';
// import coffsharbour3 from '@src/images/coffsharbour3';
import Typed from 'react-typed';
import AOS from 'aos';
// import 'aos/dist/aos.css';

import '@src/home.scss';

class Home extends React.Component {
  componentDidMount() {
    AOS.init();
  }

  render () {
    return (
      <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
         <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/coffsharbour3.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.25, zIndex: -1}}></div>
         <section id='home'>
            <div className='container'>
               <h1>RpAsher</h1>
               <p style={{ fontSize: '2em' }}><Typed strings={["Investment Representative", "Trader", "Full-Stack Web Developer", "Part-Time Coder", "Full-Time Nerd"]} typeSpeed={45} backSpeed={30} loop /></p>
            </div>
         </section>
      </main>
    )
  }
}

export default Home;



