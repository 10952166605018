import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const PresentValue = () => {
  const [showAlert, setShowAlert] = useState(false); // Added to control visibility of alert
  const [futureValue, setFutureValue] = useState(25000); // Changed to futureValue
  const [rate, setRate] = useState(5);
  const [years, setYears] = useState(4);
  const [compoundsPerYear, setCompoundsPerYear] = useState(12);
  const [presentValue, setPresentValue] = useState(0); // Added presentValue

  const calculatePresentValue = (e) => {
    e.preventDefault();
    let r = rate / 100; // convert to decimal
    let n = compoundsPerYear;
    let t = years;
    let presentValue = futureValue / Math.pow((1 + r/n), n*t);
    setPresentValue(presentValue.toFixed(2));
    setShowAlert(true); // Set the showAlert to true to display the alert
  }

  const clearForm = () => {
    setFutureValue(25000); // Changed to futureValue
    setRate(5);
    setYears(4);
    setCompoundsPerYear(12);
    setPresentValue(0);
    setShowAlert(false); // Set the showAlert to true to display the alert
  }

  return (
    <React.Fragment>
    <Layout>
       <CoverCard>
          {/* Insert Below */}
          <Card.Title id="title">Present Value Calculator</Card.Title>
               {/* INSERT BELOW */}
                  {/* CONTENT BELOW THIS LINE */}
                <Form onSubmit={calculatePresentValue}>
                <div className="row-container">
                    <div className="row-item">
                        {/* CONTENT BELOW THIS LINE */}
                        <Form.Group controlId="formPrincipal" className="mb-4">
                            <Form.Label className="mb-1">Future Value:</Form.Label>
                            <NumericFormat 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            className="form-control" 
                            value={futureValue} // Changed to futureValue
                            onValueChange={(values) => {
                            const { value } = values;
                            setFutureValue(parseFloat(value)); // Changed to setFutureValue
                            }} 
                            />
                    </Form.Group>
                    </div>
                    <div className="row-item">
                        {/* CONTENT BELOW THIS LINE */}
                        {/* Annual Interest Rate */}
                        <Form.Group controlId="formRate" className="mb-4">
                        <Form.Label>Rate (%):
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                            The rate you expect or desire to receive on your investment
                            </Tooltip>
                            )}
                        >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                        </OverlayTrigger>
                        </Form.Label>
                        <NumericFormat 
                            decimalScale={2}
                            suffix={'%'} 
                            className="form-control" 
                            value={rate} 
                            onChange={(values) => {
                            const { value } = values;
                            setRate(parseFloat(value));
                            }} 
                        />
                        </Form.Group>
                    </div>
                    <div className="row-item">
                        {/* CONTENT BELOW THIS LINE */}
                        {/* Number of Years */}
                        <Form.Group controlId="formYears" className="mb-4">
                        <Form.Label className="mb-1">Period (years)</Form.Label>
                        <Form.Control type="number" value={years} onChange={e => setYears(e.target.value)} />
                        </Form.Group>
                    </div>
                    </div>
                  {/* Compounds per Year */}
                    <Form.Group controlId="formCompoundsPerYear" className="mb-4">
                    <Form.Label>Compounding frequency
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                            The Number of times interest applied per time period; (12 = 12x per year; 1 = 1x per year;  while 4 = 4x per year [qtrly dividends for example])
                            </Tooltip>
                            )}
                        >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Check 
                        type="radio" 
                        label="Monthly" 
                        name="compoundsPerYear" 
                        value={12} 
                        defaultChecked 
                        onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
                    />
                    <Form.Check 
                        type="radio" 
                        label="Quarterly" 
                        name="compoundsPerYear" 
                        value={4} 
                        onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
                    />
                    <Form.Check 
                        type="radio" 
                        label="Bi-Annually" 
                        name="compoundsPerYear" 
                        value={2} 
                        onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
                    />
                    <Form.Check 
                        type="radio" 
                        label="Annually" 
                        name="compoundsPerYear" 
                        value={1} 
                        onChange={(e) => setCompoundsPerYear(parseInt(e.target.value))}
                    />
                    </Form.Group>
                  <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
                  <Button variant="danger" type="button" onClick={clearForm} className="custom-button" size="sm">Reset</Button>
                  {showAlert && (
                    <Alert variant="success" className="mt-3">
                    The Present Value is <NumericFormat value={presentValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} />
                    </Alert>
                    )}
              </Form>
              {/* INSERT ABOVE */}
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title className='fw-bold text-center'>Present Value Calculator</Card.Title>
               <p>
                  The Present Value Calculator helps you estimate the present value of an investment based on the future value, discount rate, number of years, and the number of times interest is compounded per year.
               </p>
               <p>
                  The calculation for present value is based on the discounted cash flow formula:
               </p>
               <p className="fw-bold">
                  Present Value:
                  <br />
                  <code>PV = FV / (1 + (Rate / CompoundsPerYear))^(CompoundsPerYear * Years) </code>
               </p>
               <p>
                  The calculator takes the following inputs:
               </p>
               <ul>
                  <li>Future Value: The expected value of the investment in the future.</li>
                  <li>Discount Rate: The rate used to discount the future cash flows.</li>
                  <li>Number of Years: The number of years the investment will compound.</li>
                  <li>Number of Times Interest Applied per Time Period: The number of times the interest is compounded per year (e.g., quarterly, monthly).</li>
               </ul>
               <p>
                  After entering these parameters, the calculator determines the present value defined by the inputs.
               </p>
               <div>
                  <Card.Img variant="top" src="/files/images/present_value.png" />
               </div>
       </CoverCard>
    </Layout>
 </React.Fragment>
  );
};

export default PresentValue;