import React, { useState } from 'react';
import { Alert, Button, Card, Form, Collapse, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import tombsnobles1 from '@src/images/tombsnobles1';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const RothConversionCalculator = () => {
  const [currentAge, setCurrentAge] = useState(45);
  const [withdrawalAge, setWithdrawalAge] = useState(65);
  const [currentMarginalTax, setCurrentMarginalTax] = useState(24);
  const [marginalTaxAtRetirement, setMarginalTaxAtRetirement] = useState(22);
  const [retirementInvestmentTax, setRetirementInvestmentTax] = useState(15);
  const [returnOnInvestment, setReturnOnInvestment] = useState(7);
  const [totalAmountToConvert, setTotalAmountToConvert] = useState(25000);
  const [result, setResult] = useState(null);

  const calculateResult = () => {
   const rothReturns = totalAmountToConvert * Math.pow(1 + returnOnInvestment / 100, withdrawalAge - currentAge);
   
   // Part A
   const taxSavings = totalAmountToConvert * currentMarginalTax / 100;
   const taxSavingsReturn = taxSavings * Math.pow(1 + returnOnInvestment / 100, withdrawalAge - currentAge);
   const taxedTaxSavingsReturn = taxSavingsReturn - (taxSavingsReturn - taxSavings) * retirementInvestmentTax / 100; // Tax is deducted only from the gains
   
   // Part B
   const iraReturnsBeforeTax = totalAmountToConvert * Math.pow(1 + returnOnInvestment / 100, withdrawalAge - currentAge);
   const iraReturns = iraReturnsBeforeTax * (1 - marginalTaxAtRetirement / 100); // Tax is deducted
   
   const totalIraReturns = taxedTaxSavingsReturn + iraReturns;
 
   let resultString = "";
   
   const formatCurrency = (amount) => {
     const [integerPart, decimalPart] = parseFloat(amount).toFixed(2).split('.');
     return `${parseInt(integerPart).toLocaleString()}.${decimalPart}`;
   };
   
   if (rothReturns > totalIraReturns) {
     resultString = `Convert to Roth, this option gives you $${formatCurrency(rothReturns)}, which is greater than the IRA option of $${formatCurrency(totalIraReturns)}.`;
   } else if (rothReturns < totalIraReturns) {
     resultString = `Do not convert to Roth, this option gives you $${formatCurrency(rothReturns)}, which is less than the IRA option of $${formatCurrency(totalIraReturns)}.`;
   } else {
     resultString = `Do not convert, as $${formatCurrency(rothReturns)} is equal to $${formatCurrency(totalIraReturns)}.`;
   }
 
   setResult(resultString);
 };
 
 
 
 
  const resetForm = () => {
    setCurrentAge(45);
    setWithdrawalAge(65);
    setCurrentMarginalTax(24);
    setMarginalTaxAtRetirement(22);
    setRetirementInvestmentTax(15);
    setReturnOnInvestment(7);
    setTotalAmountToConvert(10000);
    setResult(null);
  };

  return (
   <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
   <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
   <section id="rothConversion" className="onlineTools">
    <div className="container" id="onlineTools">
         <>
         <div className="d-flex justify-content-center align-items-center">
         <Card style={{ width: '40rem' }}>
            <Card.Body>
            <Card.Title id="title">Roth Conversion Calculator</Card.Title>
            {/* INSERT BELOW */}
            <Form>
               {/* Current Age */}
               <Form.Group controlId="currentAge">
               <Form.Label>Current Age
                  <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={props => (
                     <Tooltip id="button-tooltip" {...props}>
                        Can not by higher than 72
                     </Tooltip>
                     )}
                  >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                     className="form-control" 
                     value={currentAge} 
                     onChange={(e) => setCurrentAge(parseInt(e.target.value))}
                  />
               </Form.Group>
               {/* Withdrawal Age */}
               <Form.Group controlId="withdrawalAge">
                  <Form.Label>Retirement (Withdrawal) Age
                     <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                           Your projected retirement age. When you plan to start taking withdrawals.
                        </Tooltip>
                        )}
                     >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                     </Form.Label>
                     <Form.Control
                        className="form-control" 
                        value={withdrawalAge} 
                        onChange={(e) => setWithdrawalAge(parseInt(e.target.value))}
                     />
                  </Form.Group>
               {/* Current Marginal Tax */}
               <Form.Group controlId="currentMarginalTax">
               <Form.Label>Current Tax Rate (%)</Form.Label>
               <NumericFormat 
                     decimalScale={2}
                     fixedDecimalScale
                     suffix={'%'} 
                     className="form-control" 
                     value={currentMarginalTax} 
                     onChange={(e) => setCurrentMarginalTax(parseInt(e.target.value))}
               />
               </Form.Group>
               {/* Marginal Tax At Retirement */}
               <Form.Group controlId="marginalTaxAtRetirement">
                  <Form.Label>Projected Tax Rate at Retirement (%)
                     <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                           Your expected income tax rate at retirement. Or expected marginal tax rate at retirement.
                        </Tooltip>
                        )}
                     >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                  </Form.Label>
                  <NumericFormat 
                     decimalScale={2}
                     fixedDecimalScale
                     suffix={'%'} 
                     className="form-control" 
                     value={marginalTaxAtRetirement} 
                     onChange={(values) => {
                        const { floatValue } = values;
                        setMarginalTaxAtRetirement(floatValue);
                     }} 
                     />
                  </Form.Group>
               {/* Retirement Investment Tax */}
               <Form.Group controlId="retirementInvestmentTax">
                  <Form.Label>Retirement Investment Tax Rate (%)
                     <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                           Your projected capital gains tax rate on investments by retirement.
                        </Tooltip>
                        )}
                     >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                  </Form.Label>
                  <NumericFormat 
                     decimalScale={2}
                     fixedDecimalScale
                     suffix={'%'} 
                     className="form-control" 
                     value={retirementInvestmentTax} 
                     onChange={(values) => {
                        const { floatValue } = values;
                        setRetirementInvestmentTax(floatValue);
                     }} 
                     />
                  </Form.Group>
               {/* Return on Investment */}
               <Form.Group controlId="returnOnInvestment">
                  <Form.Label>Return on Investment (%)
                     <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                           The average annual return you expect your investments to make between now and your projected retirement date (withdrawal date). Many put the average return of the S&P 500, or the risk free rate if more conservative. Somewhere in between can work too. This all depends on your own personal investment style and risk tolerance.
                        </Tooltip>
                        )}
                     >
                        <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                  </Form.Label>
                  <NumericFormat 
                     decimalScale={2}
                     fixedDecimalScale
                     suffix={'%'} 
                     className="form-control" 
                     value={returnOnInvestment} 
                     onChange={(values) => {
                        const { floatValue } = values;
                        setReturnOnInvestment(floatValue);
                     }} 
                     />
                  </Form.Group>
               {/* Total Amount to Convert */}
               <Form.Group controlId="totalAmountToConvert">
               <Form.Label>Total Amount to Convert ($)</Form.Label>
               <NumericFormat 
                     thousandSeparator={true} 
                     prefix={'$'} 
                     className="form-control" 
                     value={totalAmountToConvert} 
                     onChange={(values) => {
                     const { floatValue } = values;
                     setTotalAmountToConvert(floatValue);
                     }}
                  />
               </Form.Group>
               <Button variant="success" type="submit" className="mb-4 ml-2" size="sm" onClick={(e) => { e.preventDefault(); calculateResult(); }}>
               Calculate
               </Button>
               <Button variant="danger" className="mb-4 ml-2" size="sm" onClick={resetForm}>
               Reset
               </Button>
               {result && <Alert variant="info">{result}</Alert>}
               </Form>
            {/* INSERT ABOVE */}
            <GoBack className="mt-2 ml-2 text-right"/>
         </Card.Body>
         </Card>
         </div>
         </>
       </div>
       <div className='container' className='onlineTools mt-3'>
            <div className="d-flex justify-content-center align-items-center">
            <Card style={{ width: '40rem' }}>
                <Card.Body>
                    <Card.Title>Roth IRA Conversion</Card.Title>
                    <p>
                      A Roth Conversion refers to the process of converting a Traditional IRA or other eligible retirement accounts into a Roth IRA. This is done by paying income taxes on the pre-tax dollars in the traditional retirement account that you're converting.
                    </p>
                    <p>
                      The benefit of a Roth IRA is that it allows for tax-free growth and tax-free withdrawals in retirement, unlike Traditional IRAs which are tax-deferred - you pay taxes on your money when you withdraw in retirement.
                    </p>
                    <p>
                      The calculator takes into account the following parameters to determine whether converting your Traditional IRA to a Roth IRA is beneficial:
                    </p>
                    <ul>
                      <li>Total Amount to Convert: The total amount you want to convert.</li>
                      <li>Current Marginal Tax Rate: Your current tax rate which you'll pay on the converted amount.</li>
                      <li>Return on Investment (ROI): The average annual return you expect from your investments.</li>
                      <li>Current Age: Your current age.</li>
                      <li>Withdrawal Age: The age at which you plan to start withdrawing money from the retirement account.</li>
                      <li>Marginal Tax at Retirement: Your expected tax rate at retirement age.</li>
                    </ul>
                    <p>
                      The calculator then determines the potential value of both your Roth IRA and Traditional IRA at retirement age and gives you a comparison, enabling you to make an informed decision.
                    </p>
                    <p>
                      <b>Assumptions: </b>This calculator assumes any taxes due from the Roth IRA conversion are paid with funds not in the IRA account. Example, if $25,000 were the amount you were looking to convert, you would owe $6,000 in tax (The amount you convert multiplied by your current tax rate). The assumption is that you would pay this later when filing your tax returns or some other time, ultimately with funds not used in the conversion. As part of the evaluation, this tool then looks at how much that amount would earn based on your projected ROI, and subtracting taxes on amount greater than basis later at your projected Tax at retirement.
                    </p>
                </Card.Body>
            </Card>
            </div>
         </div>
     </section>
   </main>
  );
};

export default RothConversionCalculator;
