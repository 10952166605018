import React, { useState, useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import Home from '@src/home'
import About from '@src/about'
import Resume from '@src/resume'
import Portfolio from '@src/portfolio'
import Services from '@src/services'
import Sidebar from '@src/sidebar';
import ScrollComponent from '@utils/_scroll';
import ContactForm from './contact_form.jsx';
import DonateButton from './support/support_content';
import DonatePage from './support/support_page';
import SuccessfulCharge from './support/successful_charge';
import CancelCharge from './support/cancel_charge';

// Investment Links
import InvestmentLinks from '@tools/investments/investment_links';
import RMDCalculator from '@tools/investments/calculators/rmd_calculator.jsx';
import FutureValue from '@tools/investments/calculators/future_value';
import PresentValue from '@tools/investments/calculators/present_value';
import CAGR from '@tools/investments/calculators/cagr.jsx';
import CompoundInterest from '@tools/investments/calculators/compound_interest';
import DividendDiscountModel from '@tools/investments/calculators/ddmodel';
import DiscountedCashFlow from '@tools/investments/calculators/discountedCashFlow';
import RothConversionCalculator from '@tools/investments/calculators/roth_conversion';
import RealReturn from '@tools/investments/calculators/real_return';
import TaxEquivalentYield from '@tools/investments/calculators/tey.jsx';
import BondYieldCalculator from '@tools/investments/calculators/bond_yield.jsx';

// Mortgage Links
import MortgageLinks from '@tools/mortgage/mortgage_links.jsx';
import MortgageCalculator from '@tools/mortgage/calculators/mortgage_calculator.jsx';
import MortgagePayOff from '@tools/mortgage/calculators/mortgage_payoff';
import MortgageMoPayments from '@tools/mortgage/calculators/mortgage_monthly_payments.jsx';
import MortgageTotalInterest from '@tools/mortgage/calculators/mortgage_interest.jsx';

// Savings Links
import SavingsLinks from '@tools/savings/savings_links';
import APYCalculator from '@tools/savings/calculators/apr_to_apy.jsx';
import APRCalculator from '@tools/savings/calculators/apy_to_apr.jsx';
import SavingsCalculator from '@tools/savings/calculators/savings_calculator.jsx';
import NumberOfPeriods from '@tools/savings/calculators/count_periods1.jsx';

import '@src/home.scss';


const SubRoute = ({ match }) => <p className="p-2 mt-2 border border-light">{match.url}</p>

const NotFound = () => {
  return <h2>404 Not Found</h2>;
}

const App = () => {
   const [isHomePage, setIsHomePage] = useState(false);

   useEffect(() => {
    setIsHomePage(location.pathname === '/');
  }, [location]);


  return (
    <>
    <Router>
      <ScrollManager setIsHomePage={setIsHomePage} />
      {!isHomePage && <ScrollComponent />}
      <div id="sidebar">
        <Sidebar />
      </div>
      <div id="content">
        <main>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about/" exact component={About} />
            <Route path="/resume/" exact component={Resume} />
            <Route path="/contact/" exact component={ContactForm} />
            <Route path="/services/" exact component={Services} />

            {/* PORTFOLIO SECTION START  */}
            <Route path="/portfolio/" exact component={Portfolio} />

            {/* INVESTMENT LINKS  */}
            <Route path="/portfolio/investment_links" exact component={InvestmentLinks} />
            <Route path="/portfolio/rmd_calculator" component={RMDCalculator} />
            <Route path="/portfolio/future_value" component={FutureValue} />
            <Route path="/portfolio/present_value" component={PresentValue} />
            <Route path="/portfolio/cagr" component={CAGR} />
            <Route path="/portfolio/compound_interest" component={CompoundInterest} />
            <Route path="/portfolio/ddmodel" component={DividendDiscountModel} />
            <Route path="/portfolio/discountedCashFlow" component={DiscountedCashFlow} />
            <Route path="/portfolio/roth_conversion" component={RothConversionCalculator} />
            <Route path="/portfolio/bond_yield" component={BondYieldCalculator} />
            <Route path="/portfolio/real_return" component={RealReturn} />
            <Route path="/portfolio/tey" component={TaxEquivalentYield} />

            {/*  MORTGAGE LINKS  */}
            <Route path="/portfolio/mortgage_links" exact component={MortgageLinks} />
            <Route path="/portfolio/mortgage_calculator" component={MortgageCalculator} />
            <Route path="/portfolio/mortgage_payoff" component={MortgagePayOff} />
            <Route path="/portfolio/mortgage_monthly_payments" component={MortgageMoPayments} />
            <Route path="/portfolio/mortgage_interest" component={MortgageTotalInterest} />

            {/* SAVINGS LINKS  */}
            <Route path="/portfolio/savings_links" exact component={SavingsLinks} />
            <Route path="/portfolio/apr_to_apy" component={APYCalculator} />
            <Route path="/portfolio/apy_to_apr" component={APRCalculator} />
            <Route path="/portfolio/count_periods1" component={NumberOfPeriods} />
            <Route path="/portfolio/savings_calculator" component={SavingsCalculator} />

            {/*  STRIPE LINKS  */}
            <Route path="/donate"><DonatePage /></Route>
            <Route path="/charges/mark_complete" component={SuccessfulCharge} /> {/* Added route for SuccessfulCharge */}
            <Route path="/charges/cancel" component={CancelCharge} /> {/* Added route for CancelCharge */}
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </Router>
    </>
  )
}

const ScrollManager = ({ setIsHomePage }) => {
  const location = useLocation();

  useEffect(() => {
    setIsHomePage(location.pathname === '/');
  }, [location, setIsHomePage]);

  return null;
}

export default App;