import React, { useState } from 'react';
import { Alert, Button, Card, Form, Collapse, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const TaxEquivalentYield = () => {
  const [investmentYield, setInvestmentYield] = useState(4.25);
  const [federalTaxRate, setFederalTaxRate] = useState(28);
  const [stateLocalTaxRate, setStateLocalTaxRate] = useState(4.53);
  const [taxExempt, setTaxExempt] = useState('Federal Tax'); // set default to 'Federal Tax'
  const [result, setResult] = useState(0);

  const calculateYield = (e) => {
    e.preventDefault();
    
    // Form validation: all inputs and a radio option must be filled/selected
    if (investmentYield === 0 || federalTaxRate === 0 || stateLocalTaxRate === 0 || !taxExempt) {
      alert('Please fill out all fields');
      return;
    }
    
    let taxRate = 0;
    if (taxExempt === 'Federal Tax') {
      taxRate = federalTaxRate;
    } else if (taxExempt === 'State Tax') {
      taxRate = stateLocalTaxRate;
    } else {
      taxRate = federalTaxRate + stateLocalTaxRate;
    }
    
    const yieldResult = investmentYield / (1 - (taxRate / 100));
    setResult(yieldResult.toFixed(3));
  };

  const resetForm = () => {
    setInvestmentYield(0);
    setFederalTaxRate(0);
    setStateLocalTaxRate(0);
    setTaxExempt('');
    setResult(0);
  };

  return (
    <React.Fragment>
      <Layout>
         <CoverCard>
            {/* Insert Below */}
            <Card.Title id='title'>Tax Equivalent Yield (TEY) Calculator</Card.Title>
            <Form onSubmit={calculateYield}>
              <Form.Group controlId="investmentYield">
                <Form.Label>Investment Yield (%):</Form.Label>
                <NumericFormat
                  decimalScale={3}
                  suffix="%"
                  className="form-control"
                  value={investmentYield}
                  onValueChange={(values) => {
                    const { value } = values;
                    setInvestmentYield(parseFloat(value));
                  }}
                />
              </Form.Group>

              <Form.Group controlId="federalTaxRate">
                <Form.Label>Federal Marginal Tax Rate (%):</Form.Label>
                <NumericFormat
                  decimalScale={3}
                  suffix="%"
                  className="form-control"
                  value={federalTaxRate}
                  onValueChange={(values) => {
                    const { value } = values;
                    setFederalTaxRate(parseFloat(value));
                  }}
                />
              </Form.Group>

              <Form.Group controlId="stateLocalTaxRate">
                <Form.Label>State/Local Marginal Tax Rate (%):</Form.Label>
                <NumericFormat
                  decimalScale={3}
                  suffix="%"
                  className="form-control"
                  value={stateLocalTaxRate}
                  onValueChange={(values) => {
                    const { value } = values;
                    setStateLocalTaxRate(parseFloat(value));
                  }}
                />
              </Form.Group>

              <Form.Group controlId="taxExempt">
                <Form.Label>Tax Exempt:</Form.Label>
                <Form.Check
                  type="radio"
                  id="federalTax"
                  label="Federal Tax"
                  value="Federal Tax"
                  checked={taxExempt === 'Federal Tax'}
                  onChange={(e) => setTaxExempt(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  id="stateTax"
                  label="State Tax"
                  value="State Tax"
                  checked={taxExempt === 'State Tax'}
                  onChange={(e) => setTaxExempt(e.target.value)}
                />
                <Form.Check
                  type="radio"
                  id="both"
                  label="Both"
                  value="Both"
                  checked={taxExempt === 'Both'}
                  onChange={(e) => setTaxExempt(e.target.value)}
                />
              </Form.Group>

              <Button variant="success" type="submit" className="custom-button" size="sm">
                Calculate
              </Button>
              <Button variant="danger" type="button" onClick={resetForm} className="custom-button" size="sm">
                Reset
              </Button>

              <Card.Text>
                Tax Equivalent Yield: {result}%
              </Card.Text>
            </Form>
         </CoverCard>
         {/* Space Filler */}
         <CoverCard>
            {/* Insert Below */}
            <Card.Title className='fw-bold'>Tax Equivalent Yield Calculator</Card.Title>
          <p>
            This calculator allows users to determine the tax equivalent yield given the investment yield, federal tax rate, and state/local tax rate.
          </p>
          <p>
          Tax Equivalent Yield (TEY) is a financial term that refers to the pretax yield that a taxable bond needs to possess for its yield to be equal to that of a tax-free municipal bond. It's used to make a fair comparison between the yield of a tax-free bond and that of a taxable bond to determine which one has a higher effective yield.
          </p>
          <p>
            The formula for Tax Equivalent Yield is:
          </p>
          <ul>
            <li><code>Tax Equivalent Yield (TEY) = Tax Free Yield / (1 - Tax Rate)</code></li>
          </ul>
          <p>
          This TEY Calculator takes in the Investment Yield, Federal Marginal Tax Rate, and State/Local Marginal Tax Rate as inputs, and based on whether the tax exemption is Federal Tax, State Tax, or Both, calculates and displays the TEY as per the above formula. If the inputs are not filled out or no radio option is checked, an alert message is displayed. The result can be reset using the "Reset" button.
          </p>
          <div>
            <Card.Img variant="top" src="/files/images/tey.png" />
          </div>
         </CoverCard>
      </Layout>
   </React.Fragment>
  );
};

export default TaxEquivalentYield;