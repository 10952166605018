import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Accordion, Table, Card, CardGroup, ListGroup } from 'react-bootstrap';

import '@utils/styles.scss';

const NotFound = () => {
  return <h2>404 Not Found</h2>;
}

const OnlineTools = () => {
  return (
    <React.Fragment>
      <Accordion.Header><b>Online Tools & Calculators</b></Accordion.Header>
      <Accordion.Body>
          <Card>
            <Card.Body>
            <h3><span className='fw-bold border border-light p-2 customHeader bg-light'>Investment Finance Calculators </span></h3>
            <p>In this section you'll find an assortment of investment, finance, economic, and probably some random, projects and calculators I've built. I will add new content here as I have time but if there are any special requests, by all means, send me a message and I'll see what I can make. In the meantime, click on a link to navigate to a page and test them out.</p>
            <ListGroup className='mt-2'>
              <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/investment_links">Investment Calculators</Link></ListGroup.Item>
              <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_links">Mortgage & Loan Calculators</Link></ListGroup.Item>
              <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/savings_links">Savings and Interest Calculators</Link></ListGroup.Item>
              {/* <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/name_here">Name Here</Link></ListGroup.Item> */}
            </ListGroup>
            </Card.Body>
          </Card>
      </Accordion.Body>
    </React.Fragment>
  )
}

export default OnlineTools;