import React from 'react'


const singleLifeExpectancyTable = {
// Table I (Single Life Expectancy)
    // Replace with actual data
    70: 18.8,
    71: 18,
    72: 17.2,
    73: 16.4,
    74: 15.6,
    75: 14.8,
    76: 14.1,
    77: 13.3,
    78: 12.6,
    79: 11.9,
    80: 11.2,
    81: 10.5,
    82: 9.9,
    83: 9.3,
    84: 8.7,
    85: 8.1,
    86: 7.6,
    87: 7.1,
    88: 6.6,
    89: 6.1,
    90: 5.7,
    91: 5.3,
    92: 4.9,
    93: 4.6,
    94: 4.3,
    95: 4,
    96: 3.7,
    97: 3.4,
    98: 3.2,
    99: 3,
    100: 2.8,
    101: 2.6,
    102: 2.5,
    103: 2.3,
    104: 2.2,
    105: 2.1,
    106: 2.1,
    107: 2.1,
    108: 2,
    109: 2,
    110: 2,
    111: 2,
    112: 2,
    113: 1.9,
    114: 1.9,
    115: 1.8,
    116: 1.8,
    117: 1.6,
    118: 1.4,
    119: 1.1,
    120: 1,
  // ...
 };

 export default singleLifeExpectancyTable;
 