// InvestmentLinks.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Accordion, Table, Card, CardGroup, ListGroup } from 'react-bootstrap';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const SubRoute = ({ match }) => <p className="p-2 mt-2 border border-light">{match.url}</p>

const NotFound = () => {
  return <h2>404 Not Found</h2>;
}

const InvestmentLinks = () => {
  return (
    <React.Fragment>
  <Layout>
    <CoverCard>
    <Card.Title id="title">Investment Finance Calculators</Card.Title>
        <p>Select a calculator from the list below:</p>
        <ListGroup className='mt-2'>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/rmd_calculator">RMD Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/future_value">Future Value Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/present_value">Present Value Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/cagr">Compound Annual Growth Rate (CAGR)</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/compound_interest">Compound Interest Rate</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/ddmodel">Dividend Discount Model</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/discountedCashFlow">Discounted Cash Flow</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/roth_conversion">Roth Conversion Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/bond_yield">Bond Yield Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/real_return">Inflation Adjusted Return Calculator</Link></ListGroup.Item>
          <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/tey">Tax Equivalent Yield Calculator</Link></ListGroup.Item>
          {/* <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/name_here">Name Here</Link></ListGroup.Item> */}
        </ListGroup>
    </CoverCard>
  </Layout>
</React.Fragment>
  )
}

export default InvestmentLinks;