import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, SplitButton, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

const RealPV = () => {
  const [FV, setFV] = useState(10000);
  const [n, setN] = useState(5);
  const [r, setR] = useState(7);
  const [P, setP] = useState(50);
  const [f, setF] = useState(12);
  const [cF, setCf] = useState(12); // contribution frequency
  const [π, setPi] = useState(3.5);
  const [result1, setResult1] = useState(null);
  const [result2, setResult2] = useState(null);
  const [realReturn, setRealReturn] = useState(null);
  const [showResults, setShowResults] = useState(false);


  const calculate = () => {
    const adjP = (P * cF) / f; // adjusted contribution
    const m = n * f;
    const i = r / 100 / f;
    const pr = parseFloat(Math.pow(1 + (π / 100), 1 / f).toFixed(8)) - 1;
    const rrr = ((1 + i) / (1 + pr)) - 1;
    const rateOne = ((1 + (r/100))/(1 + (π/100))) - 1;
    const newRate = rateOne * 100;
  
    const PVLumpSum1 = FV / Math.pow((1 + i), m);
    const PVLumpSum2 = FV / Math.pow((1 + rrr), m);
    
    if(adjP === 0) {
      setResult1(PVLumpSum1);
      setResult2(PVLumpSum2);
      setRealReturn(newRate);
      setShowResults(true);
    } else {
      const PVA1 = adjP * ((1 - Math.pow((1 + i), -m)) / i);
      const PVA2 = adjP * ((1 - Math.pow((1 + rrr), -m)) / rrr);
      const res1B = PVLumpSum1 - PVA1;
      const res2B = PVLumpSum2 - PVA2;
      setResult1(res1B);
      setResult2(res2B);
      setRealReturn(newRate);
      setShowResults(true);
    }
  };
  
 


  const reset = () => {
    setFV(10000);
    setN(5);
    setR(7);
    setP(50);
    setF(12);
    setCf(12); // reset contribution frequency
    setPi(3.5);
    setResult1(0);
    setResult2(0);
    setRealReturn(0);
    setShowResults(false);
  };

  const frequencyOptions = [
    { value: 52, text: 'Weekly' },
    { value: 26, text: 'Bi-weekly' },
    { value: 12, text: 'Monthly' },
    { value: 4, text: 'Quarterly' },
    { value: 2, text: 'Semi-annually' },
    { value: 1, text: 'Annually' },
  ];

  return (
   <>
   <Card.Title id="title">Investment Required for Future Target</Card.Title>
   <Form>
       <div className="row-container">
           <div className="row-item">
           <Form.Group controlId="formFV">
             <Form.Label>Target Return ($)</Form.Label>
               <NumericFormat
                 value={FV}
                 thousandSeparator={true}
                 prefix={'$'}
                 decimalScale={2}
                 className="form-control"
                 onValueChange={(values) => {
                   setShowResults(false);
                   const { value } = values;
                   setFV(parseFloat(value));
                 }}
               />
             </Form.Group>
             </div>
             <div className="row-item">
             <Form.Group controlId="formN">
             <Form.Label>Time Period (years)</Form.Label>
               <NumericFormat
                 value={n}
                 className="form-control"
                 onValueChange={(values) => {
                   setShowResults(false);
                   const { value } = values;
                   setN(parseInt(value));
                 }}
               />
             </Form.Group> 
             </div>
       </div>
       <div className="row-container">
           <div className="row-item">
           <Form.Group controlId="formP">
             <Form.Label>Periodic Payment ($)</Form.Label>
               <NumericFormat
                 value={P}
                 thousandSeparator={true} 
                 prefix={'$'}
                 className="form-control"
                 onValueChange={(values) => {
                 setShowResults(false);
                 const { value } = values;
                 setP(value === '' ? 0 : parseFloat(value));
                 }}
               />
             </Form.Group>
             </div>
             <div className="row-item">
             <Form.Group controlId="formCF">
               <Form.Label>Payment Frequency</Form.Label>
               <InputGroup>
                  <DropdownButton
                     as={InputGroup.Prepend}
                     variant="outline-secondary"
                     title={frequencyOptions.find(opt => opt.value === cF)?.text || 'Monthly'}
                     id="dropdown-cf"
                  >
                     {frequencyOptions.map(({ value, text }) => (
                     <Dropdown.Item key={value} onClick={() => setCf(value)}>{text}</Dropdown.Item>
                     ))}
                  </DropdownButton>
               </InputGroup>
               </Form.Group>
             </div>
       </div>
       <div className="row-container">
           <div className="row-item">
           <Form.Group controlId="formR">
             <Form.Label>Return Rate (%)</Form.Label>
             <NumericFormat
               value={r}
               suffix="%"
               decimalScale={2}
               className="form-control"
               onValueChange={(values) => {
                 setShowResults(false);
                 const { value } = values;
                 setR(parseFloat(value));
               }}
               />
             </Form.Group>
             </div>
             <div className="row-item">
             <Form.Group controlId="formF">
               <Form.Label>Compounding Frequency</Form.Label>
               <InputGroup>
                  <DropdownButton
                     as={InputGroup.Prepend}
                     variant="outline-secondary"
                     title={frequencyOptions.find(opt => opt.value === f)?.text || 'Monthly'}
                     id="dropdown-f"
                  >
                     {frequencyOptions.map(({ value, text }) => (
                     <Dropdown.Item key={value} onClick={() => setF(value)}>{text}</Dropdown.Item>
                     ))}
                  </DropdownButton>
               </InputGroup>
               </Form.Group>
             </div>
       </div>
       <Form.Group controlId="formPi">
         <Form.Label>Expected Inflation rate (%)</Form.Label>
           <NumericFormat
             decimalScale={3}
             thousandSeparator={true}
             suffix="%"
             className="form-control"
             value={π}
             onValueChange={(values) => {
                 setShowResults(false);
                 const { value } = values;
                 setPi(value === '' ? 0 : parseFloat(value));
             }}
           />
         </Form.Group>
         <Button variant="success" onClick={calculate} className="custom-button" size="sm">Calculate</Button>
         <Button variant="danger" type="button" onClick={reset} className="custom-button" size="sm">Reset</Button>
         </Form>{showResults && result1 !== null && result2 !== null ? (
          π === 0
              ? result1 < 0
                  ? (
                      <Alert variant="success">
                          Given your expectation of no inflation you would reach your target of <NumericFormat value={FV} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in {n} years, with your monthly payments of  <NumericFormat value={P} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> and without the need for an initial investment
                      </Alert>
                  )
                  : (
                      <Alert variant="success">
                          Given your expectation of no inflation in {n} years, then with your current rate of return you would need an initial investment of <NumericFormat value={result1} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in addition to your <NumericFormat value={P} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> payment(s)
                      </Alert>
                  )
              : P > 0
                  ? result1 === "0.00" && result2 === "0.00"
                      ? (
                          <Alert variant="success">
                              Given your expected inflation rate, return rate, and compounding frequency, desired future value, & planned contributions, you will meet or exceed your desired future target.
                          </Alert>
                      )
                      : (
                          <Alert variant="success">
                              In order to reach your goal of <NumericFormat value={FV} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in {n} years, you would need an initial investment of <NumericFormat value={result1} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in addition to your <NumericFormat value={P} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> payment(s) if just considering your required rate of return however, inflation erodes your required rate to the real rate of <NumericFormat value={realReturn} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} />, which changes your required initial investment to <NumericFormat value={result2} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in addition to your <NumericFormat value={P} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> periodic payments over that same time period.
                          </Alert>
                      )
                  : (
                      <Alert variant="success">
                          In order to reach your goal of <NumericFormat value={FV} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> in {n} years, you would need an initial investment of <NumericFormat value={result1} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> considering your required rate of return and no periodic payments however, inflation erodes your required rate to the real rate of <NumericFormat value={realReturn} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} />, which changes your required investment to reach this goal to <NumericFormat value={result2} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} /> over that same time period.
                      </Alert>
                  )
        ) : null
      }
     </>
  );
};

export default RealPV;