import React, { useState } from 'react';
import { Button, Form, Card, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import tombsnobles1 from '@src/images/tombsnobles1';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';


import '@utils/styles.scss';

const BondYieldCalculator = () => {
  const [currentBondPrice, setCurrentBondPrice] = useState('');
  const [bondFaceValue, setBondFaceValue] = useState('');
  const [yearsToMaturity, setYearsToMaturity] = useState('');
  const [annualCouponRate, setAnnualCouponRate] = useState('');
  const [couponFrequency, setCouponFrequency] = useState('biAnnual');
  const [currentYield, setCurrentYield] = useState('');
  const [yieldToMaturity, setYieldToMaturity] = useState('');

  const calculateYield = () => {
    const price = parseFloat(currentBondPrice);
    const faceValue = parseFloat(bondFaceValue);
    const maturity = parseFloat(yearsToMaturity);
    const couponRate = parseFloat(annualCouponRate) / 100;

    // Calculate current yield
    const currentYieldCalc = (couponRate * faceValue) / price;
    setCurrentYield((currentYieldCalc * 100).toFixed(3));

    if (couponFrequency === 'zeroCoupon') {
      // Zero Coupon YTM
      const zeroCouponYTM = Math.pow(faceValue / price, 1 / maturity) - 1;
      setYieldToMaturity((zeroCouponYTM * 100).toFixed(3));

      // Set Current Yield to 0
      setCurrentYield('0.000');
    } else {
      // Calculate m and C
      const frequency = getCouponFrequencyMultiplier(couponFrequency);
      const m = maturity * frequency;
      const C = (faceValue * couponRate) / frequency;

      // Calculate YTM based on the coupon frequency
      let yieldToMaturityCalc;
      switch (frequency) {
        case 1: // Annual
          yieldToMaturityCalc =
            (C + (faceValue - price) / maturity) / ((faceValue + price) / 2);
          break;
        case 2: // Bi-Annual
          yieldToMaturityCalc =
            ((C + (faceValue - price) / m) / ((faceValue + price) / 2)) * 2;
          break;
        case 4: // Quarterly
          yieldToMaturityCalc =
            ((C + (faceValue - price) / m) / ((faceValue + price) / 2)) * 4;
          break;
        case 12: // Monthly
          yieldToMaturityCalc =
            ((C + (faceValue - price) / m) / ((faceValue + price) / 2)) * 12;
          break;
        default:
          yieldToMaturityCalc = 0;
      }

      setYieldToMaturity((yieldToMaturityCalc * 100).toFixed(3));
    }
  };

  const getCouponFrequencyMultiplier = (frequency) => {
    switch (frequency) {
      case 'monthly':
        return 12;
      case 'quarterly':
        return 4;
      case 'annual':
        return 1;
      case 'zeroCoupon':
        return 1;
      default:
        return 2; // Bi-Annual
    }
  };

  const resetForm = () => {
    setCurrentBondPrice('');
    setBondFaceValue('');
    setYearsToMaturity('');
    setAnnualCouponRate('');
    setCouponFrequency('biAnnual');
    setCurrentYield('');
    setYieldToMaturity('');
  };

  return (
    <main id="main" style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.1,
          zIndex: -1,
        }}
      ></div>
      <section id="discountedCashFlow" className="onlineTools">
        <div className="container" id="onlineTools">
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Card style={{ width: '40rem' }}>
                <Card.Body>
                  <Card.Title><h2>Bond Yield Calculator</h2></Card.Title>
                  <Form>
                    <Form.Group controlId="formCurrentBondPrice">
                      <Form.Label>Current Bond Trading Price ($)</Form.Label>
                      <NumericFormat
                        className="form-control"
                        value={currentBondPrice}
                        onValueChange={(values) => {
                          const { value } = values;
                          setCurrentBondPrice(value);
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBondFaceValue">
                      <Form.Label>Bond Face Value/Par Value ($)</Form.Label>
                      <NumericFormat
                        className="form-control"
                        value={bondFaceValue}
                        onValueChange={(values) => {
                          const { value } = values;
                          setBondFaceValue(value);
                        }}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Form.Group>
                    <Form.Group controlId="formYearsToMaturity">
                      <Form.Label>Years to Maturity</Form.Label>
                      <NumericFormat
                        className="form-control"
                        value={yearsToMaturity}
                        onValueChange={(values) => {
                          const { value } = values;
                          setYearsToMaturity(value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formAnnualCouponRate">
                      <Form.Label>Annual Coupon Rate (%)</Form.Label>
                      <NumericFormat
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={'%'}
                        className="form-control"
                        value={annualCouponRate}
                        onValueChange={(values) => {
                          const { value } = values;
                          setAnnualCouponRate(value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="formCouponFrequency">
                      <Form.Label>Coupon Frequency</Form.Label>
                      <Form.Check
                        type="radio"
                        label="Monthly"
                        value="monthly"
                        checked={couponFrequency === 'monthly'}
                        onChange={() => setCouponFrequency('monthly')}
                      />
                      <Form.Check
                        type="radio"
                        label="Quarterly"
                        value="quarterly"
                        checked={couponFrequency === 'quarterly'}
                        onChange={() => setCouponFrequency('quarterly')}
                      />
                      <Form.Check
                        type="radio"
                        label="Bi-Annual"
                        value="biAnnual"
                        checked={couponFrequency === 'biAnnual'}
                        onChange={() => setCouponFrequency('biAnnual')}
                      />
                      <Form.Check
                        type="radio"
                        label="Annual"
                        value="annual"
                        checked={couponFrequency === 'annual'}
                        onChange={() => setCouponFrequency('annual')}
                      />
                      <Form.Check
                        type="radio"
                        label="Zero Coupon"
                        value="zeroCoupon"
                        checked={couponFrequency === 'zeroCoupon'}
                        onChange={() => setCouponFrequency('zeroCoupon')}
                      />
                    </Form.Group>
                    <Button variant="primary" className="mb-4" size="sm" onClick={calculateYield}>
                      Calculate
                    </Button>
                    <Button variant="secondary" className="mb-4 ml-2" size="sm" onClick={resetForm}>
                      Reset
                    </Button>
                    <Form.Group controlId="formCurrentYield">
                      <Form.Label>Current Yield (%)</Form.Label>
                      <Form.Control type="text" value={currentYield} readOnly />
                    </Form.Group>
                    <Form.Group controlId="formYieldToMaturity">
                      <Form.Label>Approximate Yield to Maturity (%)</Form.Label>
                      <Form.Control type="text" value={yieldToMaturity} readOnly />
                    </Form.Group>
                  </Form>
                  <GoBack className="mt-2 ml-2 text-right"/>
                </Card.Body>
              </Card>
            </div>
          </>
        </div>
        <div className='container' className='onlineTools mt-3'>
         <div className="d-flex justify-content-center align-items-center">
            <Card style={{ width: '40rem' }}>
            <Card.Body>
                <Card.Title>Bond Yield</Card.Title>
                <p>
                    Bond Yield is a measure of the return an investor can expect to earn from a bond investment. It represents the annualized rate of return generated by a bond, taking into account its current price, face value, coupon rate, and time to maturity.
                </p>
                
                <Card.Title>Current Yield</Card.Title>
                <p>
                    Current Yield is a measure of the annual interest income generated by a bond relative to its current market price. It is calculated by dividing the bond's annual coupon payment by its current price.
                </p>
                
                <p>
                    The formula for calculating Current Yield is:
                    <br />
                    <code>Current Yield = (Coupon Rate x Par Value) / Current Price</code>
                </p>

                <div>
                    <Card.Img variant="top" src="/files/images/current_yield.png" />
                </div>
                
                <Card.Title>Yield to Maturity (YTM)</Card.Title>
                <p>
                    The yield to maturity (YTM) on a bond is its internal rate of return (IRR) or, the discount rate which makes the present value (PV) of all the bond’s future cash flows equal to its current market price.
                </p>
                
                <p>
                    The below function is the approximated YTM. The true YTM is calculated with trial and error by entering rates that eventually set the calculated price to match the bond's actual current market price.
                </p>

                <p>
                    Yield To Maturity (Approximate):
                    <br />
                    <code>YTM = ((C + ((FV - PV) / n)) / 2) / ((FV + PV) / 2)</code>
                </p>

                <p>
                    where,
                </p>

                <p>
                    C is the coupon payment
                </p>

                <p>
                    FV is the face value of the bond
                </p>

                <p>
                    PV is the current price of the bond
                </p>

                <p>
                    n is the number of periods until maturity
                </p>

                <p>
                    YTM is the Yield to Maturity
                </p>

                <div>
                    <Card.Img variant="top" src="/files/images/ytm.png" />
                </div>

                <Card.Title>Current Yield</Card.Title>
                <p>
                    Current Yield is a measure of the annual interest income generated by a bond relative to its current market price. It is calculated by dividing the bond's annual coupon payment by its current price.
                </p>
                
                <p>
                    For Zero Coupon bonds, the Yield to Maturity is calculated using the formula:
                    <br />
                    <code>YTM = (FV / PV)^(1/n) - 1</code>
                </p>

                <div>
                    <Card.Img variant="top" src="/files/images/zero_coupon.png" />
                </div>
            </Card.Body>
          </Card>
         </div>
         </div>
      </section>
    </main>
  );
};

export default BondYieldCalculator;
