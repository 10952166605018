import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import '@src/home.scss';

const PageOpen = (props) => {
   return(
      <React.Fragment>
      <Accordion.Header><b>Whats in here</b></Accordion.Header>
         <Accordion.Body>
         {/* new card group */}
            <Card>
               <Card.Body>
               <ListGroup>
                  <ListGroup.Item><span className='fw-bold'>Excel & Googlesheets:</span> I do a lot of work with these, specifically in the trading and RIA space. Over the years many of the projects I've done have been centered around investment finance but more recently I've been creating things to optimize trading operations. Each image contains either a downloadable link to an excel file or Googlesheets link.
                  </ListGroup.Item>
                  <ListGroup.Item><span className='fw-bold'>Full-stack Web Development:</span> This ranges from finance projects to simple ones such as a To-Do List or AirBnB Clone. Most of these projects are made with HTML, CSS, Ruby, Ruby on Rails, Javascript, JQuery, and React. For the Full-Stack Applications, Heroku has started charging for projects with databases on their site, so for these apps the full code can be viewed on Github. I can make live again on Heroku however if a demo is needed. </ListGroup.Item>
                  <ListGroup.Item><span className='fw-bold'>Online Tools & Calculators:</span> Eventually my plan is to add more content here in other areas.</ListGroup.Item>
               </ListGroup>
               </Card.Body>
               {/* <Card.Footer className='border text-small'><i><small>Any names or information in these projects are fictitious or have been randomly generated. </small></i></Card.Footer> */}
            </Card>
            {/* end card group */}
         </Accordion.Body>
      </React.Fragment>
   );
}

export default PageOpen;