// MortgageLinks.jsx
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Accordion, Table, Card, CardGroup, ListGroup } from 'react-bootstrap';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const SubRoute = ({ match }) => <p className="p-2 mt-2 border border-light">{match.url}</p>

const NotFound = () => {
  return <h2>404 Not Found</h2>;
}

const MortgageLinks = () => {
  return (
  <React.Fragment>
    <Layout>
      <CoverCard>
      <Card.Title id="title">Mortgage Calculators</Card.Title>
          <p>Select a calculator from the list below:</p>
          <ListGroup className='mt-2'>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_calculator">Mortgage Calculator & Table</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_payoff">Mortgage Payoff Calculator & Table</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_monthly_payments">Mortgage Monthly Payments Calculator</Link></ListGroup.Item>
            <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_interest">Mortgage Cumulative Interest Calculator</Link></ListGroup.Item>
            {/* <ListGroup.Item action variant="light"><Link className="nav-link fw-bold toolsLink" to="/portfolio/mortgage_interest">Mortgage Cumulative Interest Calculator</Link></ListGroup.Item> */}
          </ListGroup>
      </CoverCard>
    </Layout>
</React.Fragment>
  )
}

export default MortgageLinks;