import React from 'react';
import { Card, Button } from 'react-bootstrap';
// import tombsnobles1 from '@src/images/tombsnobles1';

import '@utils/styles.scss';

const SuccessfulCharge = () => {
  return (
    <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
      <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
      <section id="successfulCharge" className="onlineTools">
        <div className="container" id="onlineTools">
            <>
            <div className="d-flex justify-content-center align-items-center">
            <Card style={{ width: '30rem' }}>
              <Card.Body>
                <Card.Title className='fw-bold'>Payment Successful!</Card.Title>
                <div>
                  <h1 className='fw-bold'>Thank you for your support!</h1>
                  <p>Your donation helps us to keep creating great content. We appreciate your generosity.</p>
                  <Button size="sm" variant="primary" className='fw-bold' href="/">Home</Button>
                </div>
              </Card.Body>
            </Card>
            </div>
            </>
          </div>
        </section>
      </main>
    );
  };

export default SuccessfulCharge;

