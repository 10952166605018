import React, { useState } from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import tombsnobles1 from '@src/images/tombsnobles1';
import singleLifeExpectancyTable from './rmd_tables/T1_SingleLife';
import jointLifeExpectancyTable from './rmd_tables/T2_JointLife';
import uniformLifetimeTable from './rmd_tables/T3_UniformLife';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';


const RMDCalculator = () => {
  const initialState = {
    date: new Date().toISOString().substring(0, 10),
    dob: '',
    maritalStatus: false,
    spouseDob: '',
    accountBalance: '',
  };
  const [form, setForm] = useState(initialState);
  const [rmd, setRmd] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);  // Added an error message state variable

  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const calculateRMD = () => {
    const age = getAge(form.dob);
    const spouseAge = getAge(form.spouseDob); // calculate spouse's age
    let distributionPeriod;

    // validate ages
    if (age < 72) {
      setErrorMessage('User must be at least 72 years old.');
      return;
    }
    if (age > 119 || (form.maritalStatus && spouseAge > 119)) {
      setErrorMessage('Age must be between 72 and 119.');
      return;
    }

    if (form.maritalStatus) {
      if (spouseAge > age + 10) {
        distributionPeriod = jointLifeExpectancyTable[age][spouseAge];
      } else {
        distributionPeriod = uniformLifetimeTable[age];
      }
    } else {
      distributionPeriod = singleLifeExpectancyTable[age];
    }
    const rmd = parseFloat(form.accountBalance) / distributionPeriod;
    setRmd(parseFloat(rmd.toFixed(2)).toLocaleString());
    setErrorMessage(null); // clear error message
  };

  // clear form field
  const clearForm = () => {
    setForm(initialState);
    setRmd(null);
    setErrorMessage(null);
  };

  // Function to calculate age based on date of birth
  function getAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <React.Fragment>
    <Layout>
       <CoverCard>
          {/* Insert Below */}
          <Card.Title id="title">Required Minimum Distributions "RMD" Calculator</Card.Title>
                    <Form>
                    <Form.Group controlId="formDate">
                        <Form.Label>Today's Date</Form.Label>
                        <Form.Control 
                          type="date" 
                          name="date" 
                          value={form.date} 
                          readOnly
                        />
                      </Form.Group>
    
              
                      <Form.Group controlId="formDob">
                        <Form.Label>Account Owner's Date of Birth</Form.Label>
                        <Form.Control type="date" name="dob" onChange={handleChange} />
                      </Form.Group>
              
                      <Form.Group controlId="formMaritalStatus">
                        <Form.Check
                          type="checkbox"
                          label="Married"
                          name="maritalStatus"
                          onChange={handleChange}
                        />
                      </Form.Group>
              
                      {form.maritalStatus && (
                        <Form.Group controlId="formSpouseDob">
                          <Form.Label>Spouse's Date of Birth</Form.Label>
                          <Form.Control type="date" name="spouseDob" onChange={handleChange} />
                        </Form.Group>
                      )}
              
                      <Form.Group controlId="formAccountBalance">
                        <Form.Label>Account Balance as of December 31st Previous Year</Form.Label>
                        <NumericFormat 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            className="form-control" // to keep the Bootstrap styles
                            name="accountBalance" 
                            onValueChange={(values) => {
                              const { value } = values;
                              // value without formatting = 2223
                              setForm({
                                ...form,
                                accountBalance: value,
                              });
                            }} 
                            value={form.accountBalance} // add this line
                          />
                      </Form.Group>
    
                      <Button variant="success" type="button" onClick={calculateRMD} className="custom-button" size="sm">Calculate</Button>
                      <Button variant="danger" type="button" onClick={clearForm} className="custom-button" size="sm">Reset</Button>
                  </Form>
                  {errorMessage && (
                    <Alert variant="danger" className="mt-3">
                      {errorMessage}
                    </Alert>
                  )}
                  
                  {rmd && (
                    <Alert variant="success" className="mt-3">
                      The Required Minimum Distribution (RMD) for this year is: ${rmd}
                    </Alert>
                  )}
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title className='fw-bold'>Required Minimum Distribution (RMD) Calculator</Card.Title>
            <p>
              Required Minimum Distribution (RMD) refers to the minimum amount that individuals with certain retirement accounts, such as Traditional IRAs and 401(k) plans, must withdraw from their accounts each year. The RMD amount is determined based on the account balance and the individual's age.
            </p>
            <p>
              The RMD is mandatory for individuals who have reached the age of 72 (or 70 ½ if born before July 1, 1949). Failing to take the RMD or withdrawing less than the required amount can result in significant tax penalties.
            </p>
            <p>
              This calculator helps you determine the Required Minimum Distribution (RMD) for a specific year. It takes into account the following parameters:
            </p>
            <ul>
              <li>Date: The date for which you want to calculate the RMD.</li>
              <li>Account Owner's Date of Birth: The account owner's date of birth, used to calculate the age.</li>
              <li>Marital Status: Whether the account owner is married or not.</li>
              <li>Spouse's Date of Birth: The spouse's date of birth, if applicable.</li>
              <li>Account Balance: The account balance as of December 31st of the previous year.</li>
            </ul>
            <p>
              After entering these parameters, the calculator determines the required distribution period based on the account owner's age and marital status. It then calculates the RMD amount for the specified year.
            </p>
            <p>
              It's important to note that the RMD calculation is subject to IRS rules and regulations, and this calculator provides an estimate based on the provided parameters.
            </p>
       </CoverCard>
    </Layout>
    </React.Fragment>
  );
};

export default RMDCalculator;