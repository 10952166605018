import React from 'react';
import { Card, Form } from 'react-bootstrap';

import '@utils/styles.scss';

const CoverCard2 = ({ children }) => {
  return (
    <div className="container" id="cardCover">
      <div className="d-flex justify-content-center align-items-start coverCardContainer"> 
        <Card style={{ width: '40rem' }}>
          <Card.Body>
            {children}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CoverCard2;