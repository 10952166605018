import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const CAGR = () => {
  const [showAlert, setShowAlert] = useState(false); // Added to control visibility of alert
  const [initialInvestment, setInitialInvestment] = useState(10000);
  const [endingValue, setEndingValue] = useState(25000);
  const [periods, setPeriods] = useState(5);
  const [cagr, setCAGR] = useState(0);

  const calculateCAGR = (e) => {
    e.preventDefault();
    let cagr = Math.pow(endingValue / initialInvestment, 1 / periods) - 1;
    setCAGR((cagr * 100).toFixed(2));
    setShowAlert(true); // Set the showAlert to true to display the alert
  }

  const clearForm = () => {
    setInitialInvestment(10000);
    setEndingValue(25000);
    setPeriods(5);
    setCAGR(0);
    setShowAlert(false);
  }

  return (
    <React.Fragment>
    <Layout>
      <CoverCard>
        {/* Insert Below */}
        <Card.Title id="title">Compound Annual Growth Rate (CAGR) Calculator</Card.Title>
        {/* INSERT BELOW */}
          <Form onSubmit={calculateCAGR}>
          <div className="row-container">
                <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formInitialInvestment">
                    <Form.Label>Initial Investment:</Form.Label>
                        <NumericFormat 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            className="form-control" 
                            value={initialInvestment} 
                            onValueChange={(values) => {
                            const { value } = values;
                            setInitialInvestment(parseFloat(value));
                            }} 
                        />
                </Form.Group>
                </div>
                <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formEndingValue">
                        <Form.Label>Ending Value:</Form.Label>
                        <NumericFormat 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            className="form-control" 
                            value={endingValue} 
                            onValueChange={(values) => {
                            const { value } = values;
                            setEndingValue(parseFloat(value));
                            }} 
                        />
                </Form.Group>
                </div>
                <div className="row-item">
                    {/* CONTENT BELOW THIS LINE */}
                    <Form.Group controlId="formPeriods">
                        <Form.Label>Period (years):</Form.Label>
                        <Form.Control type="number" value={periods} onChange={e => setPeriods(e.target.value)} />
                </Form.Group>
                </div>
                </div>
                <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
                <Button variant="danger" type="button" onClick={clearForm} className="custom-button" size="sm">Reset</Button>
                {showAlert && (
                    <Alert variant="success" className="mt-3">
                    The Compound Annual Growth Rate (CAGR) is <NumericFormat value={cagr} displayType={'text'} thousandSeparator={true} suffix={'%'} decimalScale={2} />
                    </Alert>
                )}
          </Form>
       </CoverCard>
       {/* Space Filler */}
       <CoverCard>
          {/* Insert Below */}
          <Card.Title className='fw-bold text-center'>Compound Annual Growth Rate (CAGR) Calculator</Card.Title>
            <p>
              The Compound Annual Growth Rate (CAGR) Calculator helps you estimate the annual growth rate of an investment based on the initial value, final value, and the number of years.
            </p>
            <p>
              The calculation for CAGR is based on the formula:
            </p>
            <p className="fw-bold">
              Compound Annual Growth Rate (CAGR):
              <br />
              <code>CAGR = (FV / PV)^(1 / Years) - 1</code>
            </p>
            <p>
              The calculator takes the following inputs:
            </p>
            <ul>
              <li>Initial Value (PV): The initial value of the investment.</li>
              <li>Final Value (FV): The final value of the investment.</li>
              <li>Number of Years: The number of years the investment has grown.</li>
            </ul>
            <p>
              After entering these parameters, the calculator determines the Compound Annual Growth Rate (CAGR) defined by the inputs.
            </p>
            <div>
              <Card.Img variant="top" src="/files/images/cagr.png" />
            </div>
       </CoverCard>
    </Layout>
    </React.Fragment>
  );
};

export default CAGR;