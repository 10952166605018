import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';
// import thailand1 from '../src/images/thailand1.jpg';
import FullStack from './creations/fswd.jsx';
import Excel from './creations/excel.jsx';
import PageOpen from './creations/opening.jsx';
import OnlineTools from './creations/online_tools.jsx';

import '@src/home.scss';

const Portfolio = () => {
  return (
    <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
      <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/thailand2.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
      <section id='portfolio-1' className='portfolio'>
        <div className='container'>
          {/*  */}
               <Accordion defaultActiveKey="0">
                  {/* First Accordion: PageOpen Start */}
                  <Accordion.Item eventKey="0">
                     <PageOpen />
                  </Accordion.Item>
                  {/* Excel & Googlesheets Component Start */}
                  <Accordion.Item eventKey="1">
                     <Excel />
                  </Accordion.Item>
                  {/* Full-Stack Web Development Start */}
                  <Accordion.Item eventKey="2">
                     <FullStack />
                  </Accordion.Item>
                  {/* Online Tools & Calculator */}
                  <Accordion.Item eventKey="3">
                     <OnlineTools />
                  </Accordion.Item>
                  {/* Accordion End */}
               </Accordion>
          {/*  */}
        </div>
      </section>
      {/*  */}
    </main>
  );
}

export default Portfolio;

