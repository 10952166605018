import React, { useState } from 'react';
import { Form, Button, Alert, Card, ListGroup } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import Hampi1 from '@src/images/Hampi1';

import './home.scss';

const Services = () => {

  return (
  <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
    <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/Hampi1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
    <section id="services" className="onlineTools">
      <div className="container" id="onlineTools">
          <>
          <div className="d-flex justify-content-center align-items-center">
          <Card style={{ width: '40rem' }}>
            <Card.Body>
              <Card.Title className='fw-bold text-center'>Services</Card.Title>
              <ListGroup as="ul">
                  <ListGroup.Item action as="li" >Full-Stack Web Development</ListGroup.Item>
                  <ListGroup.Item action as="li">Front-End Development</ListGroup.Item>
                  <ListGroup.Item action as="li">Back-End Development</ListGroup.Item>
                  <ListGroup.Item action as="li">Excel/Googlesheets</ListGroup.Item>
               </ListGroup>
               <Card.Text>
              </Card.Text>
            </Card.Body>
            <Card.Body>
               <Card.Text className='fw-bold text-center'>
                Want something tailored to your needs? Reach out so we can connect!
              </Card.Text>
            </Card.Body>
            </Card>
          </div>
          </>
        </div>
      </section>
    </main>
  );
};

export default Services;