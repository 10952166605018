import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Tabs, Card, Alert } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import RealFV from './inflation_returns/find_fv';
import RealPV from './inflation_returns/find_pv';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';


const RealReturn = () => {
 
   return (
     <React.Fragment>
         <Layout>
         <CoverCard>
         <Tabs
            defaultActiveKey="realFV"
            id="uncontrolled-tab-example"
            className="mb-3"
         >
            <Tab eventKey="realFV" title="Future Value of Investment with Inflation">
            <RealFV />
            </Tab>
            <Tab eventKey="realPV" title="Present Value of Investment with Inflation">
            <RealPV />
            </Tab>
         </Tabs>
         </CoverCard>
         
         <CoverCard>
            <Card.Title className='fw-bold text-center'>How the Future Value of Investment with Inflation Calculator Works</Card.Title>
            <p>
                The Investment Calculator allows users to determine the future value of an investment, taking into account contributions, the rate of return, the investment time period, compounding frequency, and inflation. It provides the total accumulated value at the end of the investment period, both with and without considering inflation.
            </p>
            <p>
                Individuals or businesses planning for future financial goals often need to estimate the growth of their investments, given certain assumptions. This could be for retirement planning, saving for a house, budgeting for a large expense, or any other future financial need. The Investment Calculator helps to calculate this value, demonstrating the power of compound interest and the impact of inflation.
            </p>
            <p>
                The Investment Calculator uses several key pieces of information:
            </p>
            <ul>
                <li>Investment Amount (PV)</li>
                <li>Contributions (C)</li>
                <li>Rate of Return (i)</li>
                <li>Time Period in years (t)</li>
                <li>Compound Frequency (n) - options include weekly, bi-weekly, monthly, quarterly, semi-annually, and annually</li>
                <li>Expected Inflation rate (π)</li>
            </ul>
            <p>
                Using these inputs, the calculator computes the future value of the investment, both with and without accounting for inflation. This is done based on the compound interest formulas for future value of a single amount and an annuity. These formulas consider the present value, contributions, rate of return, compounding frequency, and the time period.
            </p>
            <p>
                The formulas used are:
            </p>
            <p>
                Future Value without Inflation:
            </p>
            <ul>
               <li><code>Compounding Frequency (n) = (t x y)</code></li>
               <li><code>Rate Frequency (r) = (i / f)</code></li>
               <li><code>Real Rate of Return (π<sub>R</sub>) = <span className='fraction'><span className='numerator'>(1 + r)</span><span className='fraction-line'></span><span className='denominator'>(1 + π) </span></span></code> <code className='p-2'> - 1</code></li>
            </ul>
            <ul>
               <li><code>FV of Payments (FV<sub>P</sub>) = <span className='h6'>C x </span><span className='fraction'><span className='numerator'>(1 + r)<sup>n</sup>-1</span><span className='fraction-line'></span><span className='denominator'>r</span></span></code></li>
               <li><code>FV of Initial Investment (FV<sub>inv</sub>) = PV(1 + r)<sup>n</sup></code></li>
               <li><code>FV of Target (FV<sub>end</sub>) = FV<sub>inv</sub> + FV<sub>P</sub></code></li>
            </ul>
            <p>
                Future Value with Inflation:
            </p>
            <ul>
               <li><code>FV of Payments (FV<sub>P</sub>) = <span className='h6'>C x </span><span className='fraction'><span className='numerator'>(1 + r)<sup>n</sup>-1</span><span className='fraction-line'></span><span className='denominator'>r</span></span></code></li>
               <li><code>FV of Initial Investment (FV<sub>inv</sub>) = PV(1 + π<sub>R</sub>)<sup>n</sup></code></li>
               <li><code>FV of Target (FV<sub>end</sub>) = FV<sub>inv</sub> + FV<sub>P</sub></code></li>
            </ul>
               <p>Where:</p>
            <ul>
                <li>PV is the Present Value (Investment Amount)</li>
                <li>C is the Contributions</li>
                <li>i is the nominal interest rate</li>
                <li>π<sub>R</sub> is the real rate of return</li>
                <li>m is the total number of compounding periods</li>
            </ul>
            <p>
                The nominal interest rate and the real rate of return are derived from the rate of return and the inflation rate, considering the compounding frequency.
            </p>
            <p>
                The impact of inflation on the investment is calculated by comparing the future value with and without inflation.
            </p>
            </CoverCard>
            <CoverCard>
            <Card.Title className='fw-bold text-center'>How the Inflation-Adjusted Present Value Calculator Works</Card.Title>
            <p>
                The Inflation-Adjusted Present Value Calculator allows users to understand the initial investment required to meet a certain future target, taking into account periodic contributions, the rate of return, the investment time period, compounding frequency, and inflation. It provides the necessary initial investment, both with and without considering inflation.
            </p>
            <p>
                Whether planning for retirement, saving for a major purchase, or calculating the cost of a future business investment, understanding the impact of inflation on the required initial investment is crucial. This calculator helps visualize this impact, demonstrating the difference between the nominal and the real rate of return.
            </p>
            <p><span className='fw-bold'>Assumptions:</span> This calculator checks first to see if there is an input for monthly contributions. If so, the calculator uses the Present Value of an Annuity formula to solve for P</p>
            <p><code>PVA = <span className='pe-2'>P x </span><span className='fraction'><span className='numerator'>1-(1/(1+r)<sup>n</sup>)</span><span className='fraction-line'></span><span className='denominator'>n</span></span></code></p>
            <p>
                The Inflation-Adjusted Present Value Calculator uses several key inputs:
            </p>
            <ul>
                <li>Target Return (FV)</li>
                <li>Periodic Payments (P)</li>
                <li>Expected Return Rate (r)</li>
                <li>Time Period in years (y)</li>
                <li>Interest Compounding Frequency (t)</li>
                <li>Payment Frequency (f)</li>
                <li>Expected Inflation rate (π)</li>
            </ul>
            <p>
                Using these inputs, the calculator computes the initial investment required, both with and without accounting for inflation. It leverages compound interest and annuity formulas, taking into consideration the target return, periodic contributions, return rate, compounding frequency, and the time period.
            </p>
            <p>
               Some of the formulas used are:
            </p>
            <p>
               Present Value without Inflation:
            </p>
            <ul>
               <li><code>Compounding Frequency (n) = (t x y)</code></li>
               <li><code>Rate Frequency (r) = (i / f)</code></li>
               <li><code>Real Rate of Return (π<sub>R</sub>) = <span className='fraction'><span className='numerator'>(1 + r)</span><span className='fraction-line'></span><span className='denominator'>(1 + π) </span></span></code> <code className='p-2'> - 1</code></li>
            </ul>
            <ul>
               <li><code>Present Value of Payments (PV<sub>pmt</sub>) = <span className='pe-2'>P x </span><span className='fraction'><span className='numerator'>1-(1/(1 + π<sub>R</sub>)<sup>n</sup>)</span><span className='fraction-line'></span><span className='denominator'>n</span></span></code></li>
               <li><code>PV of Target (PV<sub>end</sub>) = <span className='fraction'><span className='numerator'>(FV<sub>target</sub> - PV<sub>pmt</sub>)</span><span className='fraction-line'></span><span className='denominator'>(1 + r)<sup>n</sup></span></span></code></li>
               </ul>
            <p>
               Present Value with Inflation:
            </p>
            <ul>
               <li><code>Present Value of Payments (PV<sub>pmt</sub>) = <span className='pe-2'>P x </span><span className='fraction'><span className='numerator'>1-(1/(1 + π<sub>R</sub>)<sup>n</sup>)</span><span className='fraction-line'></span><span className='denominator'>n</span></span></code></li>
               <li><code>PV of Target (PV<sub>end</sub>) = <span className='fraction'><span className='numerator'>(FV<sub>target</sub> - PV<sub>pmt</sub>)</span><span className='fraction-line'></span><span className='denominator'>(1 + π<sub>R</sub>)<sup>n</sup></span></span></code></li>
            </ul>
            <p>Where:</p>
            <ul>
                <li>FV is the Target Return</li>
                <li>P is the Present Values of the contributions, with and without inflation, respectively</li>
                <li>i is the nominal interest rate</li>
                <li>π<sub>R</sub> is the real rate of return</li>
                <li>m is the total number of compounding periods</li>
            </ul>
            <p>
                The nominal interest rate and the real rate of return are derived from the return rate and the inflation rate, considering the compounding frequency. The impact of inflation on the required initial investment is calculated by comparing the present value with and without inflation.
            </p>
            <div>
                <Card.Img variant="top" src="/files/images/real_return.png" />
            </div>
            </CoverCard>

            <CoverCard>
                <Card.Title className='fw-bold text-center'>Understanding Your Results</Card.Title>
                <p>
                    <strong>Initial Investment Required Without Factoring Inflation:</strong> This value is the amount of money you would need to invest today without factoring inflation to reach your investment goal in the specified period.
                </p>
                <p>
                    <strong>Initial Investment Required With Inflation:</strong> This value is the amount of money you would need to invest today considering the expected inflation rate to reach your investment goal in the specified period.
                </p>
            </CoverCard>
         </Layout>
      </React.Fragment>
   );
}

export default RealReturn;