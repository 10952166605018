// support_page.jsx
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, OverlayTrigger, Tooltip, Button, Table, Tab, Card, Alert, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { safeCredentials, handleErrors } from '@utils/fetchHelper';
import { GiReceiveMoney } from 'react-icons/gi'; // Importing the required icon
import Layout from '@utils/_layout.jsx';
import CoverCard2 from '@utils/_card2.jsx';
import GoHome from '@utils/go_home.jsx';

import '@utils/styles.scss';

const DonatePage = () => {
  const [stripe, setStripe] = useState(null);
  const [amount, setAmount] = useState(25);
  const [isInputVisible, setInputVisible] = useState(false); // This state was missing
  const history = useHistory();

  const handleDonate = (e) => {
    e.preventDefault();

    if (amount === '') {
      return;
    }

    // Handle Stripe checkout session creation and redirection here
    createCheckoutSession()
    .then(sessionId => {
      redirectToCheckout(sessionId);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    setInputVisible(false);
    setAmount('');
  };

  const createCheckoutSession = () => {
    return fetch(`/charges`, safeCredentials({
      method: 'POST',
      body: JSON.stringify({ amount: amount }) // send amount in dollars
    }))
      .then(handleErrors)
      .then(async response => {
        
        const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
        return { response, stripe };
      })
      .then(({ response }) => {
        return response.charge.checkout_session_id;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const redirectToCheckout = async sessionId => {
    const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
    stripe.redirectToCheckout({
      sessionId: sessionId,
    })
      .then(result => {
        if (result.error) {
          console.log(result.error);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
    <Layout>
      <CoverCard2>
          {/* Insert Below */}
          <Form onSubmit={handleDonate} className="mb-3">
          <Form.Group controlId="amount">
            <Form.Label>Your Support is Appreciated! Enter your preferred amount.</Form.Label>
            <Form.Control
              type="number"
              min="5"
              value={amount}
              onChange={e => setAmount(Number(e.target.value))}
              required
              placeholder="Enter amount"
            />
          </Form.Group>
          <Button variant="success" type="submit" className="custom-button" size="sm" disabled={amount === ''}>
            Donate
          </Button>{' '}
          {/* <Button variant="danger" className="custom-button" size="sm" onClick={handleCancel}>
            Cancel
          </Button> */}
          <GoHome />
        </Form>
      </CoverCard2>
    </Layout>
    </React.Fragment>
  );
};

export default DonatePage;
