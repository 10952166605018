import React from 'react'


const jointLifeExpectancyTable = {
   // Table II (Joint Life and Last Survivor Expectancy)
   20: {	
      20: 72,
      21: 71.5,
      22: 71,
      23: 70.6,
      24: 70.2,
      25: 69.8,
      26: 69.5,
      27: 69.1,
      28: 68.8,
      29: 68.5,
   },	
   21: {	
      21: 71.5,
      22: 71,
      23: 70.5,
      24: 70,
      25: 69.6,
      26: 69.2,
      27: 68.8,
      28: 68.5,
      29: 68.1,
      30: 67.8,
   },	
   22: {	
      22: 71,
      23: 70.5,
      24: 70,
      25: 69.5,
      26: 69,
      27: 68.6,
      28: 68.2,
      29: 67.8,
      30: 67.5,
      31: 67.1,
   },	
   23: {	
      23: 70.6,
      24: 70,
      25: 69.5,
      26: 69,
      27: 68.5,
      28: 68,
      29: 67.6,
      30: 67.2,
      31: 66.8,
      32: 66.5,
   },	
   24: {	
      24: 70.2,
      25: 69.6,
      26: 69,
      27: 68.5,
      28: 68,
      29: 67.5,
      30: 67.1,
      31: 66.6,
      32: 66.2,
      33: 65.8,
   },	
   25: {	
      25: 69.8,
      26: 69.2,
      27: 68.6,
      28: 68,
      29: 67.5,
      30: 67,
      31: 66.5,
      32: 66.1,
      33: 65.6,
      34: 65.2,
   },	
   26: {	
      26: 69.5,
      27: 68.8,
      28: 68.2,
      29: 67.6,
      30: 67.1,
      31: 66.5,
      32: 66,
      33: 65.5,
      34: 65.1,
      35: 64.6,
   },	
   27: {	
      27: 69.1,
      28: 68.5,
      29: 67.8,
      30: 67.2,
      31: 66.6,
      32: 66.1,
      33: 65.5,
      34: 65,
      35: 64.5,
      36: 64.1,
   },	
   28: {	
      28: 68.8,
      29: 68.1,
      30: 67.5,
      31: 66.8,
      32: 66.2,
      33: 65.6,
      34: 65.1,
      35: 64.5,
      36: 64,
      37: 63.5,
   },	
   29: {	
      29: 68.5,
      30: 67.8,
      31: 67.1,
      32: 66.5,
      33: 65.8,
      34: 65.2,
      35: 64.6,
      36: 64.1,
      37: 63.5,
      38: 63,
   },	
   30: {	
      30: 68.3,
      31: 67.5,
      32: 66.8,
      33: 66.2,
      34: 65.5,
      35: 64.9,
      36: 64.2,
      37: 63.7,
      38: 63.1,
      39: 62.6,
      40: 62,
      41: 61.6,
      42: 61.1,
      43: 60.7,
      44: 60.3,
      45: 59.9,
      46: 59.5,
      47: 59.2,
      48: 58.9,
      49: 58.6,
   },	
   31: {	
      31: 68,
      32: 67.3,
      33: 66.6,
      34: 65.8,
      35: 65.2,
      36: 64.5,
      37: 63.9,
      38: 63.2,
      39: 62.7,
      40: 62.1,
      41: 61.6,
      42: 61.1,
      43: 60.6,
      44: 60.1,
      45: 59.7,
      46: 59.3,
      47: 58.9,
      48: 58.6,
      49: 58.2,
      50: 57.9,
   },	
   32: {	
      32: 67.8,
      33: 67,
      34: 66.3,
      35: 65.6,
      36: 64.9,
      37: 64.2,
      38: 63.5,
      39: 62.9,
      40: 62.3,
      41: 61.7,
      42: 61.1,
      43: 60.6,
      44: 60.1,
      45: 59.6,
      46: 59.1,
      47: 58.7,
      48: 58.3,
      49: 57.9,
      50: 57.6,
      51: 57.2,
   },	
   33: {	
      33: 67.6,
      34: 66.8,
      35: 66,
      36: 65.3,
      37: 64.6,
      38: 63.9,
      39: 63.2,
      40: 62.5,
      41: 61.9,
      42: 61.3,
      43: 60.7,
      44: 60.1,
      45: 59.6,
      46: 59.1,
      47: 58.6,
      48: 58.1,
      49: 57.7,
      50: 57.3,
      51: 56.9,
      52: 56.6,
   },	
   34: {	
      34: 67.4,
      35: 66.6,
      36: 65.8,
      37: 65.1,
      38: 64.3,
      39: 63.6,
      40: 62.9,
      41: 62.2,
      42: 61.5,
      43: 60.9,
      44: 60.3,
      45: 59.7,
      46: 59.1,
      47: 58.6,
      48: 58.1,
      49: 57.6,
      50: 57.2,
      51: 56.7,
      52: 56.3,
      53: 55.9,
   },	
   35: {	
      35: 67.2,
      36: 66.4,
      37: 65.6,
      38: 64.8,
      39: 64.1,
      40: 63.3,
      41: 62.6,
      42: 61.9,
      43: 61.2,
      44: 60.5,
      45: 59.9,
      46: 59.3,
      47: 58.7,
      48: 58.1,
      49: 57.6,
      50: 57.1,
      51: 56.6,
      52: 56.2,
      53: 55.7,
      54: 55.3,
   },	
   36: {	
      36: 67.1,
      37: 66.2,
      38: 65.4,
      39: 64.6,
      40: 63.8,
      41: 63.1,
      42: 62.3,
      43: 61.6,
      44: 60.9,
      45: 60.2,
      46: 59.5,
      47: 58.9,
      48: 58.3,
      49: 57.7,
      50: 57.2,
      51: 56.6,
      52: 56.1,
      53: 55.6,
      54: 55.2,
      55: 54.7,
   },	
   37: {	
      37: 66.9,
      38: 66.1,
      39: 65.2,
      40: 64.4,
      41: 63.6,
      42: 62.8,
      43: 62.1,
      44: 61.3,
      45: 60.6,
      46: 59.9,
      47: 59.2,
      48: 58.6,
      49: 57.9,
      50: 57.3,
      51: 56.7,
      52: 56.2,
      53: 55.6,
      54: 55.1,
      55: 54.6,
      56: 54.2,
   },	
   38: {	
      38: 66.8,
      39: 65.9,
      40: 65.1,
      41: 64.2,
      42: 63.4,
      43: 62.6,
      44: 61.9,
      45: 61.1,
      46: 60.3,
      47: 59.6,
      48: 58.9,
      49: 58.2,
      50: 57.6,
      51: 56.9,
      52: 56.3,
      53: 55.7,
      54: 55.2,
      55: 54.6,
      56: 54.1,
      57: 53.6,
   },	
   39: {	
      39: 66.6,
      40: 65.8,
      41: 64.9,
      42: 64.1,
      43: 63.3,
      44: 62.4,
      45: 61.6,
      46: 60.9,
      47: 60.1,
      48: 59.4,
      49: 58.6,
      50: 57.9,
      51: 57.2,
      52: 56.6,
      53: 55.9,
      54: 55.3,
      55: 54.7,
      56: 54.2,
      57: 53.6,
      58: 53.1,
   },	
   40: {	
      40: 66.5,
      41: 65.6,
      42: 64.8,
      43: 63.9,
      44: 63.1,
      45: 62.3,
      46: 61.5,
      47: 60.7,
      48: 59.9,
      49: 59.1,
      50: 58.4,
      51: 57.6,
      52: 56.9,
      53: 56.3,
      54: 55.6,
      55: 55,
      56: 54.3,
      57: 53.8,
      58: 53.2,
      59: 52.7,
      60: 52.2,
      61: 51.7,
      62: 51.2,
      63: 50.8,
      64: 50.4,
      65: 50,
      66: 49.7,
      67: 49.3,
      68: 49,
      69: 48.8,
   },	
   41: {	
      41: 66.4,
      42: 65.5,
      43: 64.6,
      44: 63.8,
      45: 62.9,
      46: 62.1,
      47: 61.3,
      48: 60.5,
      49: 59.7,
      50: 58.9,
      51: 58.1,
      52: 57.4,
      53: 56.7,
      54: 56,
      55: 55.3,
      56: 54.6,
      57: 54,
      58: 53.4,
      59: 52.8,
      60: 52.2,
      61: 51.7,
      62: 51.2,
      63: 50.7,
      64: 50.2,
      65: 49.8,
      66: 49.4,
      67: 49,
      68: 48.7,
      69: 48.4,
      70: 48.1,
   },	
   42: {	
      42: 66.3,
      43: 65.4,
      44: 64.5,
      45: 63.6,
      46: 62.8,
      47: 61.9,
      48: 61.1,
      49: 60.3,
      50: 59.5,
      51: 58.7,
      52: 57.9,
      53: 57.1,
      54: 56.4,
      55: 55.7,
      56: 55,
      57: 54.3,
      58: 53.6,
      59: 53,
      60: 52.4,
      61: 51.8,
      62: 51.2,
      63: 50.7,
      64: 50.2,
      65: 49.7,
      66: 49.2,
      67: 48.8,
      68: 48.4,
      69: 48,
      70: 47.7,
      71: 47.4,
   },	
   43: {	
      43: 66.2,
      44: 65.3,
      45: 64.4,
      46: 63.5,
      47: 62.7,
      48: 61.8,
      49: 61,
      50: 60.1,
      51: 59.3,
      52: 58.5,
      53: 57.7,
      54: 56.9,
      55: 56.2,
      56: 55.4,
      57: 54.7,
      58: 54,
      59: 53.3,
      60: 52.6,
      61: 52,
      62: 51.4,
      63: 50.8,
      64: 50.2,
      65: 49.7,
      66: 49.2,
      67: 48.7,
      68: 48.3,
      69: 47.8,
      70: 47.4,
      71: 47.1,
      72: 46.7,
   },	
   44: {	
      44: 66.1,
      45: 65.2,
      46: 64.3,
      47: 63.4,
      48: 62.5,
      49: 61.7,
      50: 60.8,
      51: 60,
      52: 59.1,
      53: 58.3,
      54: 57.5,
      55: 56.7,
      56: 55.9,
      57: 55.2,
      58: 54.4,
      59: 53.7,
      60: 53,
      61: 52.3,
      62: 51.6,
      63: 51,
      64: 50.4,
      65: 49.8,
      66: 49.2,
      67: 48.7,
      68: 48.2,
      69: 47.7,
      70: 47.3,
      71: 46.8,
      72: 46.4,
      73: 46.1,
   },	
   45: {	
      45: 66,
      46: 65.1,
      47: 64.2,
      48: 63.3,
      49: 62.4,
      50: 61.5,
      51: 60.7,
      52: 59.8,
      53: 59,
      54: 58.1,
      55: 57.3,
      56: 56.5,
      57: 55.7,
      58: 54.9,
      59: 54.2,
      60: 53.4,
      61: 52.7,
      62: 52,
      63: 51.3,
      64: 50.7,
      65: 50,
      66: 49.4,
      67: 48.8,
      68: 48.3,
      69: 47.7,
      70: 47.2,
      71: 46.7,
      72: 46.3,
      73: 45.9,
      74: 45.5,
   },	
   46: {	
      46: 65.9,
      47: 65,
      48: 64.1,
      49: 63.2,
      50: 62.3,
      51: 61.4,
      52: 60.6,
      53: 59.7,
      54: 58.8,
      55: 58,
      56: 57.2,
      57: 56.3,
      58: 55.5,
      59: 54.7,
      60: 54,
      61: 53.2,
      62: 52.4,
      63: 51.7,
      64: 51,
      65: 50.3,
      66: 49.7,
      67: 49,
      68: 48.4,
      69: 47.8,
      70: 47.3,
      71: 46.7,
      72: 46.2,
      73: 45.7,
      74: 45.3,
      75: 44.9,
   },	
   47: {	
      47: 65.9,
      48: 65,
      49: 64,
      50: 63.1,
      51: 62.2,
      52: 61.3,
      53: 60.5,
      54: 59.6,
      55: 58.7,
      56: 57.9,
      57: 57,
      58: 56.2,
      59: 55.4,
      60: 54.5,
      61: 53.7,
      62: 53,
      63: 52.2,
      64: 51.5,
      65: 50.7,
      66: 50,
      67: 49.3,
      68: 48.7,
      69: 48,
      70: 47.4,
      71: 46.8,
      72: 46.3,
      73: 45.7,
      74: 45.2,
      75: 44.8,
      76: 44.3,
   },	
   48: {	
      48: 65.8,
      49: 64.9,
      50: 64,
      51: 63,
      52: 62.1,
      53: 61.2,
      54: 60.3,
      55: 59.5,
      56: 58.6,
      57: 57.7,
      58: 56.9,
      59: 56,
      60: 55.2,
      61: 54.4,
      62: 53.6,
      63: 52.8,
      64: 52,
      65: 51.2,
      66: 50.5,
      67: 49.7,
      68: 49,
      69: 48.4,
      70: 47.7,
      71: 47.1,
      72: 46.4,
      73: 45.9,
      74: 45.3,
      75: 44.8,
      76: 44.3,
      77: 43.8,
   },	
   49: {	
      49: 65.7,
      50: 64.8,
      51: 63.9,
      52: 63,
      53: 62.1,
      54: 61.2,
      55: 60.3,
      56: 59.4,
      57: 58.5,
      58: 57.6,
      59: 56.7,
      60: 55.9,
      61: 55,
      62: 54.2,
      63: 53.4,
      64: 52.6,
      65: 51.8,
      66: 51,
      67: 50.2,
      68: 49.5,
      69: 48.8,
      70: 48.1,
      71: 47.4,
      72: 46.7,
      73: 46.1,
      74: 45.5,
      75: 44.9,
      76: 44.3,
      77: 43.8,
      78: 43.3,
   },	
   50: {	
      50: 65.7,
      51: 64.8,
      52: 63.8,
      53: 62.9,
      54: 62,
      55: 61.1,
      56: 60.2,
      57: 59.3,
      58: 58.4,
      59: 57.5,
      60: 56.6,
      61: 55.8,
      62: 54.9,
      63: 54.1,
      64: 53.2,
      65: 52.4,
      66: 51.6,
      67: 50.8,
      68: 50,
      69: 49.2,
      70: 48.5,
      71: 47.8,
      72: 47.1,
      73: 46.4,
      74: 45.7,
      75: 45.1,
      76: 44.5,
      77: 43.9,
      78: 43.3,
      79: 42.8,
      80: 42.3,
      81: 41.8,
      82: 41.4,
      83: 40.9,
      84: 40.6,
      85: 40.2,
      86: 39.8,
      87: 39.5,
      88: 39.2,
      89: 39,
   },	
   51: {	
      51: 65.6,
      52: 64.7,
      53: 63.8,
      54: 62.8,
      55: 61.9,
      56: 61,
      57: 60.1,
      58: 59.2,
      59: 58.3,
      60: 57.4,
      61: 56.5,
      62: 55.6,
      63: 54.8,
      64: 53.9,
      65: 53.1,
      66: 52.2,
      67: 51.4,
      68: 50.6,
      69: 49.8,
      70: 49,
      71: 48.3,
      72: 47.5,
      73: 46.8,
      74: 46.1,
      75: 45.4,
      76: 44.7,
      77: 44.1,
      78: 43.5,
      79: 42.9,
      80: 42.3,
      81: 41.8,
      82: 41.3,
      83: 40.8,
      84: 40.4,
      85: 40,
      86: 39.6,
      87: 39.2,
      88: 38.9,
      89: 38.6,
      90: 38.3,
   },	
   52: {	
      52: 65.6,
      53: 64.7,
      54: 63.7,
      55: 62.8,
      56: 61.9,
      57: 60.9,
      58: 60,
      59: 59.1,
      60: 58.2,
      61: 57.3,
      62: 56.4,
      63: 55.5,
      64: 54.7,
      65: 53.8,
      66: 52.9,
      67: 52.1,
      68: 51.3,
      69: 50.4,
      70: 49.6,
      71: 48.8,
      72: 48,
      73: 47.3,
      74: 46.5,
      75: 45.8,
      76: 45.1,
      77: 44.4,
      78: 43.8,
      79: 43.1,
      80: 42.5,
      81: 41.9,
      82: 41.4,
      83: 40.8,
      84: 40.3,
      85: 39.9,
      86: 39.4,
      87: 39,
      88: 38.6,
      89: 38.2,
      90: 37.9,
      91: 37.6,
   },	
   53: {	
      53: 65.5,
      54: 64.6,
      55: 63.7,
      56: 62.7,
      57: 61.8,
      58: 60.9,
      59: 59.9,
      60: 59,
      61: 58.1,
      62: 57.2,
      63: 56.3,
      64: 55.4,
      65: 54.6,
      66: 53.7,
      67: 52.8,
      68: 52,
      69: 51.1,
      70: 50.3,
      71: 49.5,
      72: 48.6,
      73: 47.8,
      74: 47.1,
      75: 46.3,
      76: 45.6,
      77: 44.8,
      78: 44.1,
      79: 43.4,
      80: 42.8,
      81: 42.1,
      82: 41.5,
      83: 40.9,
      84: 40.4,
      85: 39.9,
      86: 39.4,
      87: 38.9,
      88: 38.4,
      89: 38,
      90: 37.6,
      91: 37.3,
      92: 36.9,
   },	
   54: {	
      54: 65.5,
      55: 64.6,
      56: 63.6,
      57: 62.7,
      58: 61.7,
      59: 60.8,
      60: 59.9,
      61: 59,
      62: 58,
      63: 57.1,
      64: 56.2,
      65: 55.3,
      66: 54.5,
      67: 53.6,
      68: 52.7,
      69: 51.8,
      70: 51,
      71: 50.1,
      72: 49.3,
      73: 48.5,
      74: 47.7,
      75: 46.9,
      76: 46.1,
      77: 45.3,
      78: 44.6,
      79: 43.8,
      80: 43.1,
      81: 42.5,
      82: 41.8,
      83: 41.2,
      84: 40.6,
      85: 40,
      86: 39.4,
      87: 38.9,
      88: 38.4,
      89: 37.9,
      90: 37.5,
      91: 37.1,
      92: 36.7,
      93: 36.3,
   },	
   55: {	
      55: 65.5,
      56: 64.5,
      57: 63.6,
      58: 62.6,
      59: 61.7,
      60: 60.8,
      61: 59.8,
      62: 58.9,
      63: 58,
      64: 57.1,
      65: 56.2,
      66: 55.3,
      67: 54.4,
      68: 53.5,
      69: 52.6,
      70: 51.7,
      71: 50.9,
      72: 50,
      73: 49.1,
      74: 48.3,
      75: 47.5,
      76: 46.7,
      77: 45.9,
      78: 45.1,
      79: 44.3,
      80: 43.6,
      81: 42.9,
      82: 42.2,
      83: 41.5,
      84: 40.8,
      85: 40.2,
      86: 39.6,
      87: 39,
      88: 38.4,
      89: 37.9,
      90: 37.4,
      91: 36.9,
      92: 36.5,
      93: 36.1,
      94: 35.7,
   },	
   56: {	
      56: 65.4,
      57: 64.5,
      58: 63.5,
      59: 62.6,
      60: 61.6,
      61: 60.7,
      62: 59.8,
      63: 58.8,
      64: 57.9,
      65: 57,
      66: 56.1,
      67: 55.2,
      68: 54.3,
      69: 53.4,
      70: 52.5,
      71: 51.6,
      72: 50.7,
      73: 49.9,
      74: 49,
      75: 48.2,
      76: 47.3,
      77: 46.5,
      78: 45.7,
      79: 44.9,
      80: 44.1,
      81: 43.4,
      82: 42.6,
      83: 41.9,
      84: 41.2,
      85: 40.5,
      86: 39.8,
      87: 39.2,
      88: 38.6,
      89: 38,
      90: 37.5,
      91: 36.9,
      92: 36.5,
      93: 36,
      94: 35.5,
      95: 35.1,
   },	
   57: {	
      57: 65.4,
      58: 64.5,
      59: 63.5,
      60: 62.5,
      61: 61.6,
      62: 60.7,
      63: 59.7,
      64: 58.8,
      65: 57.9,
      66: 56.9,
      67: 56,
      68: 55.1,
      69: 54.2,
      70: 53.3,
      71: 52.4,
      72: 51.5,
      73: 50.6,
      74: 49.8,
      75: 48.9,
      76: 48,
      77: 47.2,
      78: 46.3,
      79: 45.5,
      80: 44.7,
      81: 43.9,
      82: 43.1,
      83: 42.4,
      84: 41.6,
      85: 40.9,
      86: 40.2,
      87: 39.5,
      88: 38.9,
      89: 38.2,
      90: 37.6,
      91: 37.1,
      92: 36.5,
      93: 36,
      94: 35.5,
      95: 35,
      96: 34.6,
   },	
   58: {	
      58: 65.4,
      59: 64.4,
      60: 63.5,
      61: 62.5,
      62: 61.6,
      63: 60.6,
      64: 59.7,
      65: 58.7,
      66: 57.8,
      67: 56.9,
      68: 56,
      69: 55,
      70: 54.1,
      71: 53.2,
      72: 52.3,
      73: 51.4,
      74: 50.5,
      75: 49.7,
      76: 48.8,
      77: 47.9,
      78: 47.1,
      79: 46.2,
      80: 45.4,
      81: 44.5,
      82: 43.7,
      83: 42.9,
      84: 42.2,
      85: 41.4,
      86: 40.7,
      87: 39.9,
      88: 39.2,
      89: 38.6,
      90: 37.9,
      91: 37.3,
      92: 36.7,
      93: 36.1,
      94: 35.5,
      95: 35,
      96: 34.5,
      97: 34.1,
   },	
   59: {	
      59: 65.4,
      60: 64.4,
      61: 63.4,
      62: 62.5,
      63: 61.5,
      64: 60.6,
      65: 59.6,
      66: 58.7,
      67: 57.8,
      68: 56.8,
      69: 55.9,
      70: 55,
      71: 54.1,
      72: 53.2,
      73: 52.2,
      74: 51.3,
      75: 50.5,
      76: 49.6,
      77: 48.7,
      78: 47.8,
      79: 46.9,
      80: 46.1,
      81: 45.2,
      82: 44.4,
      83: 43.6,
      84: 42.8,
      85: 42,
      86: 41.2,
      87: 40.4,
      88: 39.7,
      89: 39,
      90: 38.3,
      91: 37.6,
      92: 36.9,
      93: 36.3,
      94: 35.7,
      95: 35.1,
      96: 34.6,
      97: 34.1,
      98: 33.6,
   },	
   60: {	
      60: 65.3,
      61: 64.4,
      62: 63.4,
      63: 62.4,
      64: 61.5,
      65: 60.5,
      66: 59.6,
      67: 58.7,
      68: 57.7,
      69: 56.8,
      70: 55.9,
      71: 54.9,
      72: 54,
      73: 53.1,
      74: 52.2,
      75: 51.3,
      76: 50.4,
      77: 49.5,
      78: 48.6,
      79: 47.7,
      80: 46.8,
      81: 46,
      82: 45.1,
      83: 44.3,
      84: 43.4,
      85: 42.6,
      86: 41.8,
      87: 41,
      88: 40.2,
      89: 39.5,
      90: 38.7,
      91: 38,
      92: 37.3,
      93: 36.6,
      94: 36,
      95: 35.3,
      96: 34.8,
      97: 34.2,
      98: 33.6,
      99: 33.1,
      100: 32.6,
      101: 32.2,
      102: 31.7,
      103: 31.3,
      104: 31,
      105: 30.6,
      106: 30.3,
      107: 30,
      108: 29.7,
      109: 29.4,
   },	
   61: {	
      61: 65.3,
      62: 64.3,
      63: 63.4,
      64: 62.4,
      65: 61.5,
      66: 60.5,
      67: 59.6,
      68: 58.6,
      69: 57.7,
      70: 56.7,
      71: 55.8,
      72: 54.9,
      73: 54,
      74: 53,
      75: 52.1,
      76: 51.2,
      77: 50.3,
      78: 49.4,
      79: 48.5,
      80: 47.6,
      81: 46.7,
      82: 45.8,
      83: 45,
      84: 44.1,
      85: 43.3,
      86: 42.4,
      87: 41.6,
      88: 40.8,
      89: 40,
      90: 39.2,
      91: 38.5,
      92: 37.7,
      93: 37,
      94: 36.3,
      95: 35.7,
      96: 35,
      97: 34.4,
      98: 33.8,
      99: 33.2,
      100: 32.7,
      101: 32.2,
      102: 31.7,
      103: 31.2,
      104: 30.8,
      105: 30.4,
      106: 30,
      107: 29.7,
      108: 29.4,
      109: 29.1,
      110: 28.8,
   },	
   62: {	
      62: 65.3,
      63: 64.3,
      64: 63.4,
      65: 62.4,
      66: 61.4,
      67: 60.5,
      68: 59.5,
      69: 58.6,
      70: 57.6,
      71: 56.7,
      72: 55.8,
      73: 54.8,
      74: 53.9,
      75: 53,
      76: 52.1,
      77: 51.1,
      78: 50.2,
      79: 49.3,
      80: 48.4,
      81: 47.5,
      82: 46.6,
      83: 45.7,
      84: 44.9,
      85: 44,
      86: 43.1,
      87: 42.3,
      88: 41.5,
      89: 40.6,
      90: 39.8,
      91: 39,
      92: 38.3,
      93: 37.5,
      94: 36.8,
      95: 36.1,
      96: 35.4,
      97: 34.7,
      98: 34.1,
      99: 33.4,
      100: 32.8,
      101: 32.3,
      102: 31.7,
      103: 31.2,
      104: 30.8,
      105: 30.3,
      106: 29.9,
      107: 29.5,
      108: 29.1,
      109: 28.7,
      110: 28.4,
      111: 28.1,
   },	
   63: {	
      63: 65.3,
      64: 64.3,
      65: 63.3,
      66: 62.4,
      67: 61.4,
      68: 60.5,
      69: 59.5,
      70: 58.6,
      71: 57.6,
      72: 56.7,
      73: 55.7,
      74: 54.8,
      75: 53.9,
      76: 52.9,
      77: 52,
      78: 51.1,
      79: 50.2,
      80: 49.3,
      81: 48.3,
      82: 47.4,
      83: 46.5,
      84: 45.7,
      85: 44.8,
      86: 43.9,
      87: 43,
      88: 42.2,
      89: 41.3,
      90: 40.5,
      91: 39.7,
      92: 38.9,
      93: 38.1,
      94: 37.3,
      95: 36.6,
      96: 35.8,
      97: 35.1,
      98: 34.4,
      99: 33.8,
      100: 33.1,
      101: 32.5,
      102: 31.9,
      103: 31.3,
      104: 30.8,
      105: 30.3,
      106: 29.8,
      107: 29.4,
      108: 28.9,
      109: 28.5,
      110: 28.2,
      111: 27.8,
      112: 27.5,
   },	
   64: {	
      64: 65.2,
      65: 64.3,
      66: 63.3,
      67: 62.3,
      68: 61.4,
      69: 60.4,
      70: 59.5,
      71: 58.5,
      72: 57.6,
      73: 56.6,
      74: 55.7,
      75: 54.8,
      76: 53.8,
      77: 52.9,
      78: 52,
      79: 51,
      80: 50.1,
      81: 49.2,
      82: 48.3,
      83: 47.4,
      84: 46.5,
      85: 45.6,
      86: 44.7,
      87: 43.8,
      88: 42.9,
      89: 42.1,
      90: 41.2,
      91: 40.4,
      92: 39.5,
      93: 38.7,
      94: 37.9,
      95: 37.1,
      96: 36.3,
      97: 35.6,
      98: 34.9,
      99: 34.2,
      100: 33.5,
      101: 32.8,
      102: 32.2,
      103: 31.5,
      104: 31,
      105: 30.4,
      106: 29.9,
      107: 29.4,
      108: 28.9,
      109: 28.4,
      110: 28,
      111: 27.6,
      112: 27.2,
      113: 26.9,
   },	
   65: {	
      65: 65.2,
      66: 64.3,
      67: 63.3,
      68: 62.3,
      69: 61.4,
      70: 60.4,
      71: 59.5,
      72: 58.5,
      73: 57.5,
      74: 56.6,
      75: 55.7,
      76: 54.7,
      77: 53.8,
      78: 52.8,
      79: 51.9,
      80: 51,
      81: 50.1,
      82: 49.1,
      83: 48.2,
      84: 47.3,
      85: 46.4,
      86: 45.5,
      87: 44.6,
      88: 43.7,
      89: 42.8,
      90: 41.9,
      91: 41.1,
      92: 40.2,
      93: 39.4,
      94: 38.6,
      95: 37.7,
      96: 36.9,
      97: 36.2,
      98: 35.4,
      99: 34.6,
      100: 33.9,
      101: 33.2,
      102: 32.5,
      103: 31.9,
      104: 31.2,
      105: 30.6,
      106: 30,
      107: 29.5,
      108: 28.9,
      109: 28.4,
      110: 28,
      111: 27.5,
      112: 27.1,
      113: 26.7,
      114: 26.3,
   },	
   66: {	
      66: 65.2,
      67: 64.2,
      68: 63.3,
      69: 62.3,
      70: 61.3,
      71: 60.4,
      72: 59.4,
      73: 58.5,
      74: 57.5,
      75: 56.6,
      76: 55.6,
      77: 54.7,
      78: 53.7,
      79: 52.8,
      80: 51.9,
      81: 50.9,
      82: 50,
      83: 49.1,
      84: 48.2,
      85: 47.2,
      86: 46.3,
      87: 45.4,
      88: 44.5,
      89: 43.6,
      90: 42.7,
      91: 41.8,
      92: 41,
      93: 40.1,
      94: 39.3,
      95: 38.4,
      96: 37.6,
      97: 36.8,
      98: 36,
      99: 35.2,
      100: 34.4,
      101: 33.7,
      102: 33,
      103: 32.3,
      104: 31.6,
      105: 30.9,
      106: 30.3,
      107: 29.7,
      108: 29.1,
      109: 28.5,
      110: 28,
      111: 27.5,
      112: 27,
      113: 26.6,
      114: 26.2,
      115: 25.8,
   },	
   67: {	
      67: 65.2,
      68: 64.2,
      69: 63.3,
      70: 62.3,
      71: 61.3,
      72: 60.4,
      73: 59.4,
      74: 58.5,
      75: 57.5,
      76: 56.5,
      77: 55.6,
      78: 54.7,
      79: 53.7,
      80: 52.8,
      81: 51.8,
      82: 50.9,
      83: 50,
      84: 49,
      85: 48.1,
      86: 47.2,
      87: 46.3,
      88: 45.4,
      89: 44.4,
      90: 43.5,
      91: 42.6,
      92: 41.8,
      93: 40.9,
      94: 40,
      95: 39.1,
      96: 38.3,
      97: 37.5,
      98: 36.6,
      99: 35.8,
      100: 35,
      101: 34.2,
      102: 33.5,
      103: 32.7,
      104: 32,
      105: 31.3,
      106: 30.6,
      107: 30,
      108: 29.4,
      109: 28.7,
      110: 28.2,
      111: 27.6,
      112: 27.1,
      113: 26.6,
      114: 26.1,
      115: 25.7,
      116: 25.3,
   },	
   68: {	
      68: 65.2,
      69: 64.2,
      70: 63.2,
      71: 62.3,
      72: 61.3,
      73: 60.3,
      74: 59.4,
      75: 58.4,
      76: 57.5,
      77: 56.5,
      78: 55.6,
      79: 54.6,
      80: 53.7,
      81: 52.7,
      82: 51.8,
      83: 50.9,
      84: 49.9,
      85: 49,
      86: 48.1,
      87: 47.1,
      88: 46.2,
      89: 45.3,
      90: 44.4,
      91: 43.5,
      92: 42.6,
      93: 41.7,
      94: 40.8,
      95: 39.9,
      96: 39,
      97: 38.2,
      98: 37.3,
      99: 36.5,
      100: 35.7,
      101: 34.9,
      102: 34.1,
      103: 33.3,
      104: 32.5,
      105: 31.8,
      106: 31.1,
      107: 30.4,
      108: 29.7,
      109: 29.1,
      110: 28.4,
      111: 27.8,
      112: 27.2,
      113: 26.7,
      114: 26.2,
      115: 25.7,
      116: 25.2,
      117: 24.8,
   },	
   69: {	
      69: 65.2,
      70: 64.2,
      71: 63.2,
      72: 62.3,
      73: 61.3,
      74: 60.3,
      75: 59.4,
      76: 58.4,
      77: 57.5,
      78: 56.5,
      79: 55.6,
      80: 54.6,
      81: 53.7,
      82: 52.7,
      83: 51.8,
      84: 50.8,
      85: 49.9,
      86: 49,
      87: 48,
      88: 47.1,
      89: 46.2,
      90: 45.2,
      91: 44.3,
      92: 43.4,
      93: 42.5,
      94: 41.6,
      95: 40.7,
      96: 39.8,
      97: 38.9,
      98: 38.1,
      99: 37.2,
      100: 36.4,
      101: 35.5,
      102: 34.7,
      103: 33.9,
      104: 33.1,
      105: 32.3,
      106: 31.6,
      107: 30.9,
      108: 30.1,
      109: 29.4,
      110: 28.8,
      111: 28.1,
      112: 27.5,
      113: 26.9,
      114: 26.3,
      115: 25.8,
      116: 25.3,
      117: 24.8,
      118: 24.3,
   },	
   70: {	
      70: 65.2,
      71: 64.2,
      72: 63.2,
      73: 62.2,
      74: 61.3,
      75: 60.3,
      76: 59.4,
      77: 58.4,
      78: 57.4,
      79: 56.5,
      80: 55.5,
      81: 54.6,
      82: 53.6,
      83: 52.7,
      84: 51.7,
      85: 50.8,
      86: 49.9,
      87: 48.9,
      88: 48,
      89: 47,
      90: 46.1,
      91: 45.2,
      92: 44.3,
      93: 43.3,
      94: 42.4,
      95: 41.5,
      96: 40.6,
      97: 39.7,
      98: 38.8,
      99: 38,
      100: 37.1,
      101: 36.2,
      102: 35.4,
      103: 34.6,
      104: 33.8,
      105: 33,
      106: 32.2,
      107: 31.4,
      108: 30.7,
      109: 29.9,
      110: 29.2,
      111: 28.5,
      112: 27.9,
      113: 27.2,
      114: 26.6,
      115: 26,
      116: 25.4,
      117: 24.9,
      118: 24.3,
      119: 23.9,
      120: 23.4,
      20: 22.9,
      21: 22.5,
      22: 22.2,
      23: 21.8,
      24: 21.5,
      25: 21.2,
      26: 20.9,
      27: 20.6,
      28: 20.4,
   },	
   71: {	
      71: 65.1,
      72: 64.2,
      73: 63.2,
      74: 62.2,
      75: 61.3,
      76: 60.3,
      77: 59.3,
      78: 58.4,
      79: 57.4,
      80: 56.5,
      81: 55.5,
      82: 54.6,
      83: 53.6,
      84: 52.7,
      85: 51.7,
      86: 50.8,
      87: 49.8,
      88: 48.9,
      89: 47.9,
      90: 47,
      91: 46.1,
      92: 45.1,
      93: 44.2,
      94: 43.3,
      95: 42.4,
      96: 41.5,
      97: 40.6,
      98: 39.7,
      99: 38.8,
      100: 37.9,
      101: 37,
      102: 36.1,
      103: 35.3,
      104: 34.5,
      105: 33.6,
      106: 32.8,
      107: 32,
      108: 31.2,
      109: 30.5,
      110: 29.7,
      111: 29,
      112: 28.3,
      113: 27.6,
      114: 26.9,
      115: 26.3,
      116: 25.7,
      117: 25.1,
      118: 24.5,
      119: 24,
      120: 23.4,
      20: 22.9,
      21: 22.5,
      22: 22,
      23: 21.6,
      24: 21.3,
      25: 20.9,
      26: 20.6,
      27: 20.3,
      28: 20,
      29: 19.8,
   },	
   72: {	
      72: 65.1,
      73: 64.2,
      74: 63.2,
      75: 62.2,
      76: 61.3,
      77: 60.3,
      78: 59.3,
      79: 58.4,
      80: 57.4,
      81: 56.5,
      82: 55.5,
      83: 54.5,
      84: 53.6,
      85: 52.6,
      86: 51.7,
      87: 50.8,
      88: 49.8,
      89: 48.9,
      90: 47.9,
      91: 47,
      92: 46,
      93: 45.1,
      94: 44.2,
      95: 43.2,
      96: 42.3,
      97: 41.4,
      98: 40.5,
      99: 39.6,
      100: 38.7,
      101: 37.8,
      102: 36.9,
      103: 36,
      104: 35.2,
      105: 34.3,
      106: 33.5,
      107: 32.7,
      108: 31.9,
      109: 31.1,
      110: 30.3,
      111: 29.5,
      112: 28.8,
      113: 28.1,
      114: 27.4,
      115: 26.7,
      116: 26,
      117: 25.4,
      118: 24.8,
      119: 24.2,
      120: 23.6,
      20: 23.1,
      21: 22.5,
      22: 22,
      23: 21.6,
      24: 21.1,
      25: 20.7,
      26: 20.4,
      27: 20,
      28: 19.7,
      29: 19.4,
      30: 19.2,
   },	
   73: {	
      73: 65.1,
      74: 64.2,
      75: 63.2,
      76: 62.2,
      77: 61.2,
      78: 60.3,
      79: 59.3,
      80: 58.4,
      81: 57.4,
      82: 56.4,
      83: 55.5,
      84: 54.5,
      85: 53.6,
      86: 52.6,
      87: 51.7,
      88: 50.7,
      89: 49.8,
      90: 48.8,
      91: 47.9,
      92: 46.9,
      93: 46,
      94: 45.1,
      95: 44.1,
      96: 43.2,
      97: 42.3,
      98: 41.4,
      99: 40.4,
      100: 39.5,
      101: 38.6,
      102: 37.7,
      103: 36.8,
      104: 36,
      105: 35.1,
      106: 34.2,
      107: 33.4,
      108: 32.6,
      109: 31.7,
      110: 30.9,
      111: 30.1,
      112: 29.4,
      113: 28.6,
      114: 27.9,
      115: 27.2,
      116: 26.5,
      117: 25.8,
      118: 25.1,
      119: 24.5,
      120: 23.9,
      20: 23.3,
      21: 22.7,
      22: 22.2,
      23: 21.6,
      24: 21.1,
      25: 20.7,
      26: 20.3,
      27: 19.9,
      28: 19.5,
      29: 19.1,
      30: 18.8,
      31: 18.6,
   },	
   74: {	
      74: 65.1,
      75: 64.1,
      76: 63.2,
      77: 62.2,
      78: 61.2,
      79: 60.3,
      80: 59.3,
      81: 58.3,
      82: 57.4,
      83: 56.4,
      84: 55.5,
      85: 54.5,
      86: 53.6,
      87: 52.6,
      88: 51.7,
      89: 50.7,
      90: 49.8,
      91: 48.8,
      92: 47.9,
      93: 46.9,
      94: 46,
      95: 45,
      96: 44.1,
      97: 43.2,
      98: 42.2,
      99: 41.3,
      100: 40.4,
      101: 39.5,
      102: 38.6,
      103: 37.7,
      104: 36.8,
      105: 35.9,
      106: 35,
      107: 34.1,
      108: 33.3,
      109: 32.4,
      110: 31.6,
      111: 30.8,
      112: 30,
      113: 29.2,
      114: 28.4,
      115: 27.7,
      116: 27,
      117: 26.2,
      118: 25.5,
      119: 24.9,
      120: 24.2,
      20: 23.6,
      21: 23,
      22: 22.4,
      23: 21.8,
      24: 21.3,
      25: 20.7,
      26: 20.3,
      27: 19.8,
      28: 19.4,
      29: 19,
      30: 18.6,
      31: 18.3,
      32: 18,
   },	
   75: {	
      75: 65.1,
      76: 64.1,
      77: 63.2,
      78: 62.2,
      79: 61.2,
      80: 60.3,
      81: 59.3,
      82: 58.3,
      83: 57.4,
      84: 56.4,
      85: 55.5,
      86: 54.5,
      87: 53.5,
      88: 52.6,
      89: 51.6,
      90: 50.7,
      91: 49.7,
      92: 48.8,
      93: 47.8,
      94: 46.9,
      95: 45.9,
      96: 45,
      97: 44.1,
      98: 43.1,
      99: 42.2,
      100: 41.3,
      101: 40.3,
      102: 39.4,
      103: 38.5,
      104: 37.6,
      105: 36.7,
      106: 35.8,
      107: 34.9,
      108: 34.1,
      109: 33.2,
      110: 32.4,
      111: 31.5,
      112: 30.7,
      113: 29.9,
      114: 29.1,
      115: 28.3,
      116: 27.5,
      117: 26.8,
      118: 26.1,
      119: 25.3,
      120: 24.6,
      20: 24,
      21: 23.3,
      22: 22.7,
      23: 22.1,
      24: 21.5,
      25: 20.9,
      26: 20.4,
      27: 19.9,
      28: 19.4,
      29: 18.9,
      30: 18.5,
      31: 18.1,
      32: 17.8,
      33: 17.4,
   },	
   76: {	
      76: 65.1,
      77: 64.1,
      78: 63.2,
      79: 62.2,
      80: 61.2,
      81: 60.2,
      82: 59.3,
      83: 58.3,
      84: 57.4,
      85: 56.4,
      86: 55.4,
      87: 54.5,
      88: 53.5,
      89: 52.6,
      90: 51.6,
      91: 50.7,
      92: 49.7,
      93: 48.8,
      94: 47.8,
      95: 46.9,
      96: 45.9,
      97: 45,
      98: 44,
      99: 43.1,
      100: 42.2,
      101: 41.2,
      102: 40.3,
      103: 39.4,
      104: 38.5,
      105: 37.5,
      106: 36.6,
      107: 35.7,
      108: 34.9,
      109: 34,
      110: 33.1,
      111: 32.3,
      112: 31.4,
      113: 30.6,
      114: 29.8,
      115: 29,
      116: 28.2,
      117: 27.4,
      118: 26.6,
      119: 25.9,
      120: 25.2,
      20: 24.4,
      21: 23.7,
      22: 23.1,
      23: 22.4,
      24: 21.8,
      25: 21.2,
      26: 20.6,
      27: 20,
      28: 19.5,
      29: 19,
      30: 18.5,
      31: 18.1,
      32: 17.7,
      33: 17.3,
      34: 16.9,
   },	
   77: {	
      77: 65.1,
      78: 64.1,
      79: 63.1,
      80: 62.2,
      81: 61.2,
      82: 60.2,
      83: 59.3,
      84: 58.3,
      85: 57.3,
      86: 56.4,
      87: 55.4,
      88: 54.5,
      89: 53.5,
      90: 52.6,
      91: 51.6,
      92: 50.7,
      93: 49.7,
      94: 48.8,
      95: 47.8,
      96: 46.9,
      97: 45.9,
      98: 45,
      99: 44,
      100: 43.1,
      101: 42.1,
      102: 41.2,
      103: 40.3,
      104: 39.3,
      105: 38.4,
      106: 37.5,
      107: 36.6,
      108: 35.7,
      109: 34.8,
      110: 33.9,
      111: 33,
      112: 32.2,
      113: 31.3,
      114: 30.5,
      115: 29.7,
      116: 28.8,
      117: 28,
      118: 27.3,
      119: 26.5,
      120: 25.7,
      20: 25,
      21: 24.3,
      22: 23.5,
      23: 22.9,
      24: 22.2,
      25: 21.5,
      26: 20.9,
      27: 20.3,
      28: 19.7,
      29: 19.1,
      30: 18.6,
      31: 18.1,
      32: 17.7,
      33: 17.2,
      34: 16.8,
      35: 16.4,
   },	
   78: {	
      78: 65.1,
      79: 64.1,
      80: 63.1,
      81: 62.2,
      82: 61.2,
      83: 60.2,
      84: 59.3,
      85: 58.3,
      86: 57.3,
      87: 56.4,
      88: 55.4,
      89: 54.5,
      90: 53.5,
      91: 52.6,
      92: 51.6,
      93: 50.6,
      94: 49.7,
      95: 48.7,
      96: 47.8,
      97: 46.8,
      98: 45.9,
      99: 44.9,
      100: 44,
      101: 43,
      102: 42.1,
      103: 41.2,
      104: 40.2,
      105: 39.3,
      106: 38.4,
      107: 37.5,
      108: 36.5,
      109: 35.6,
      110: 34.7,
      111: 33.9,
      112: 33,
      113: 32.1,
      114: 31.2,
      115: 30.4,
      116: 29.6,
      117: 28.7,
      118: 27.9,
      119: 27.1,
      120: 26.4,
      20: 25.6,
      21: 24.8,
      22: 24.1,
      23: 23.4,
      24: 22.7,
      25: 22,
      26: 21.3,
      27: 20.6,
      28: 20,
      29: 19.4,
      30: 18.8,
      31: 18.3,
      32: 17.8,
      33: 17.3,
      34: 16.8,
      35: 16.4,
      36: 16,
   },	
   79: {	
      79: 65.1,
      80: 64.1,
      81: 63.1,
      82: 62.2,
      83: 61.2,
      84: 60.2,
      85: 59.3,
      86: 58.3,
      87: 57.3,
      88: 56.4,
      89: 55.4,
      90: 54.5,
      91: 53.5,
      92: 52.5,
      93: 51.6,
      94: 50.6,
      95: 49.7,
      96: 48.7,
      97: 47.8,
      98: 46.8,
      99: 45.9,
      100: 44.9,
      101: 44,
      102: 43,
      103: 42.1,
      104: 41.1,
      105: 40.2,
      106: 39.3,
      107: 38.3,
      108: 37.4,
      109: 36.5,
      110: 35.6,
      111: 34.7,
      112: 33.8,
      113: 32.9,
      114: 32,
      115: 31.2,
      116: 30.3,
      117: 29.5,
      118: 28.7,
      119: 27.8,
      120: 27,
      20: 26.2,
      21: 25.5,
      22: 24.7,
      23: 23.9,
      24: 23.2,
      25: 22.5,
      26: 21.8,
      27: 21.1,
      28: 20.4,
      29: 19.8,
      30: 19.2,
      31: 18.6,
      32: 18,
      33: 17.4,
      34: 16.9,
      35: 16.4,
      36: 16,
      37: 15.6,
   },	
   80: {	
      80: 65.1,
      81: 64.1,
      82: 63.1,
      83: 62.1,
      84: 61.2,
      85: 60.2,
      86: 59.2,
      87: 58.3,
      88: 57.3,
      89: 56.4,
      90: 55.4,
      91: 54.4,
      92: 53.5,
      93: 52.5,
      94: 51.6,
      95: 50.6,
      96: 49.7,
      97: 48.7,
      98: 47.8,
      99: 46.8,
      100: 45.9,
      101: 44.9,
      102: 43.9,
      103: 43,
      104: 42.1,
      105: 41.1,
      106: 40.2,
      107: 39.2,
      108: 38.3,
      109: 37.4,
      110: 36.5,
      111: 35.5,
      112: 34.6,
      113: 33.7,
      114: 32.9,
      115: 32,
      116: 31.1,
      117: 30.3,
      118: 29.4,
      119: 28.6,
      120: 27.8,
      20: 26.9,
      21: 26.1,
      22: 25.3,
      23: 24.6,
      24: 23.8,
      25: 23.1,
      26: 22.3,
      27: 21.6,
      28: 20.9,
      29: 20.2,
      30: 19.6,
      31: 18.9,
      32: 18.3,
      33: 17.7,
      34: 17.1,
      35: 16.6,
      36: 16.1,
      37: 15.6,
      38: 15.2,
      39: 14.7,
      40: 14.4,
      41: 14,
      42: 13.7,
      43: 13.4,
      44: 13.1,
      45: 12.9,
      46: 12.7,
      47: 12.5,
      48: 12.3,
   },	
   81: {	
      81: 65.1,
      82: 64.1,
      83: 63.1,
      84: 62.1,
      85: 61.2,
      86: 60.2,
      87: 59.2,
      88: 58.3,
      89: 57.3,
      90: 56.4,
      91: 55.4,
      92: 54.4,
      93: 53.5,
      94: 52.5,
      95: 51.6,
      96: 50.6,
      97: 49.7,
      98: 48.7,
      99: 47.7,
      100: 46.8,
      101: 45.8,
      102: 44.9,
      103: 43.9,
      104: 43,
      105: 42,
      106: 41.1,
      107: 40.1,
      108: 39.2,
      109: 38.3,
      110: 37.3,
      111: 36.4,
      112: 35.5,
      113: 34.6,
      114: 33.7,
      115: 32.8,
      116: 31.9,
      117: 31.1,
      118: 30.2,
      119: 29.3,
      120: 28.5,
      20: 27.7,
      21: 26.9,
      22: 26,
      23: 25.2,
      24: 24.5,
      25: 23.7,
      26: 22.9,
      27: 22.2,
      28: 21.5,
      29: 20.7,
      30: 20,
      31: 19.4,
      32: 18.7,
      33: 18.1,
      34: 17.4,
      35: 16.9,
      36: 16.3,
      37: 15.8,
      38: 15.3,
      39: 14.8,
      40: 14.4,
      41: 14,
      42: 13.6,
      43: 13.2,
      44: 12.9,
      45: 12.6,
      46: 12.4,
      47: 12.2,
      48: 12,
      49: 11.8,
   },	
   82: {	
      82: 65.1,
      83: 64.1,
      84: 63.1,
      85: 62.1,
      86: 61.2,
      87: 60.2,
      88: 59.2,
      89: 58.3,
      90: 57.3,
      91: 56.3,
      92: 55.4,
      93: 54.4,
      94: 53.5,
      95: 52.5,
      96: 51.6,
      97: 50.6,
      98: 49.7,
      99: 48.7,
      100: 47.7,
      101: 46.8,
      102: 45.8,
      103: 44.9,
      104: 43.9,
      105: 43,
      106: 42,
      107: 41.1,
      108: 40.1,
      109: 39.2,
      110: 38.3,
      111: 37.3,
      112: 36.4,
      113: 35.5,
      114: 34.6,
      115: 33.7,
      116: 32.8,
      117: 31.9,
      118: 31,
      119: 30.1,
      120: 29.3,
      20: 28.4,
      21: 27.6,
      22: 26.8,
      23: 26,
      24: 25.2,
      25: 24.4,
      26: 23.6,
      27: 22.8,
      28: 22.1,
      29: 21.3,
      30: 20.6,
      31: 19.9,
      32: 19.2,
      33: 18.5,
      34: 17.9,
      35: 17.2,
      36: 16.6,
      37: 16,
      38: 15.5,
      39: 15,
      40: 14.5,
      41: 14,
      42: 13.6,
      43: 13.2,
      44: 12.8,
      45: 12.5,
      46: 12.2,
      47: 11.9,
      48: 11.7,
      49: 11.5,
      50: 11.3,
   },	
   83: {	
      83: 65.1,
      84: 64.1,
      85: 63.1,
      86: 62.1,
      87: 61.2,
      88: 60.2,
      89: 59.2,
      90: 58.3,
      91: 57.3,
      92: 56.3,
      93: 55.4,
      94: 54.4,
      95: 53.5,
      96: 52.5,
      97: 51.6,
      98: 50.6,
      99: 49.6,
      100: 48.7,
      101: 47.7,
      102: 46.8,
      103: 45.8,
      104: 44.9,
      105: 43.9,
      106: 43,
      107: 42,
      108: 41.1,
      109: 40.1,
      110: 39.2,
      111: 38.2,
      112: 37.3,
      113: 36.4,
      114: 35.4,
      115: 34.5,
      116: 33.6,
      117: 32.7,
      118: 31.8,
      119: 31,
      120: 30.1,
      20: 29.2,
      21: 28.4,
      22: 27.5,
      23: 26.7,
      24: 25.9,
      25: 25.1,
      26: 24.3,
      27: 23.5,
      28: 22.7,
      29: 22,
      30: 21.2,
      31: 20.5,
      32: 19.7,
      33: 19,
      34: 18.3,
      35: 17.7,
      36: 17,
      37: 16.4,
      38: 15.8,
      39: 15.2,
      40: 14.7,
      41: 14.2,
      42: 13.7,
      43: 13.2,
      44: 12.8,
      45: 12.4,
      46: 12.1,
      47: 11.8,
      48: 11.5,
      49: 11.2,
      50: 11,
      51: 10.8,
   },	
   84: {	
      84: 65.1,
      85: 64.1,
      86: 63.1,
      87: 62.1,
      88: 61.2,
      89: 60.2,
      90: 59.2,
      91: 58.3,
      92: 57.3,
      93: 56.3,
      94: 55.4,
      95: 54.4,
      96: 53.5,
      97: 52.5,
      98: 51.5,
      99: 50.6,
      100: 49.6,
      101: 48.7,
      102: 47.7,
      103: 46.8,
      104: 45.8,
      105: 44.9,
      106: 43.9,
      107: 42.9,
      108: 42,
      109: 41,
      110: 40.1,
      111: 39.2,
      112: 38.2,
      113: 37.3,
      114: 36.3,
      115: 35.4,
      116: 34.5,
      117: 33.6,
      118: 32.7,
      119: 31.8,
      120: 30.9,
      20: 30,
      21: 29.2,
      22: 28.3,
      23: 27.5,
      24: 26.7,
      25: 25.8,
      26: 25,
      27: 24.2,
      28: 23.4,
      29: 22.6,
      30: 21.9,
      31: 21.1,
      32: 20.4,
      33: 19.6,
      34: 18.9,
      35: 18.2,
      36: 17.5,
      37: 16.8,
      38: 16.2,
      39: 15.6,
      40: 15,
      41: 14.4,
      42: 13.9,
      43: 13.4,
      44: 12.9,
      45: 12.5,
      46: 12.1,
      47: 11.7,
      48: 11.4,
      49: 11.1,
      50: 10.8,
      51: 10.5,
      52: 10.3,
   },	
   85: {	
      85: 65.1,
      86: 64.1,
      87: 63.1,
      88: 62.1,
      89: 61.2,
      90: 60.2,
      91: 59.2,
      92: 58.3,
      93: 57.3,
      94: 56.3,
      95: 55.4,
      96: 54.4,
      97: 53.5,
      98: 52.5,
      99: 51.5,
      100: 50.6,
      101: 49.6,
      102: 48.7,
      103: 47.7,
      104: 46.8,
      105: 45.8,
      106: 44.8,
      107: 43.9,
      108: 42.9,
      109: 42,
      110: 41,
      111: 40.1,
      112: 39.1,
      113: 38.2,
      114: 37.3,
      115: 36.3,
      116: 35.4,
      117: 34.5,
      118: 33.6,
      119: 32.7,
      120: 31.8,
      20: 30.9,
      21: 30,
      22: 29.1,
      23: 28.3,
      24: 27.4,
      25: 26.6,
      26: 25.8,
      27: 25,
      28: 24.1,
      29: 23.3,
      30: 22.6,
      31: 21.8,
      32: 21,
      33: 20.3,
      34: 19.5,
      35: 18.8,
      36: 18.1,
      37: 17.4,
      38: 16.7,
      39: 16,
      40: 15.4,
      41: 14.8,
      42: 14.2,
      43: 13.6,
      44: 13.1,
      45: 12.6,
      46: 12.2,
      47: 11.8,
      48: 11.4,
      49: 11,
      50: 10.7,
      51: 10.4,
      52: 10.1,
      53: 9.9,
   },	
   86: {	
      86: 65.1,
      87: 64.1,
      88: 63.1,
      89: 62.1,
      90: 61.1,
      91: 60.2,
      92: 59.2,
      93: 58.2,
      94: 57.3,
      95: 56.3,
      96: 55.4,
      97: 54.4,
      98: 53.5,
      99: 52.5,
      100: 51.5,
      101: 50.6,
      102: 49.6,
      103: 48.7,
      104: 47.7,
      105: 46.7,
      106: 45.8,
      107: 44.8,
      108: 43.9,
      109: 42.9,
      110: 42,
      111: 41,
      112: 40.1,
      113: 39.1,
      114: 38.2,
      115: 37.2,
      116: 36.3,
      117: 35.4,
      118: 34.5,
      119: 33.5,
      120: 32.6,
      20: 31.7,
      21: 30.9,
      22: 30,
      23: 29.1,
      24: 28.2,
      25: 27.4,
      26: 26.6,
      27: 25.7,
      28: 24.9,
      29: 24.1,
      30: 23.3,
      31: 22.5,
      32: 21.7,
      33: 20.9,
      34: 20.2,
      35: 19.4,
      36: 18.7,
      37: 17.9,
      38: 17.2,
      39: 16.5,
      40: 15.9,
      41: 15.2,
      42: 14.6,
      43: 14,
      44: 13.4,
      45: 12.9,
      46: 12.4,
      47: 11.9,
      48: 11.5,
      49: 11.1,
      50: 10.7,
      51: 10.4,
      52: 10,
      53: 9.8,
      54: 9.5,
   },	
   87: {	
      87: 65,
      88: 64.1,
      89: 63.1,
      90: 62.1,
      91: 61.1,
      92: 60.2,
      93: 59.2,
      94: 58.2,
      95: 57.3,
      96: 56.3,
      97: 55.4,
      98: 54.4,
      99: 53.4,
      100: 52.5,
      101: 51.5,
      102: 50.6,
      103: 49.6,
      104: 48.7,
      105: 47.7,
      106: 46.7,
      107: 45.8,
      108: 44.8,
      109: 43.9,
      110: 42.9,
      111: 42,
      112: 41,
      113: 40.1,
      114: 39.1,
      115: 38.2,
      116: 37.2,
      117: 36.3,
      118: 35.4,
      119: 34.4,
      120: 33.5,
      20: 32.6,
      21: 31.7,
      22: 30.8,
      23: 29.9,
      24: 29.1,
      25: 28.2,
      26: 27.4,
      27: 26.5,
      28: 25.7,
      29: 24.9,
      30: 24,
      31: 23.2,
      32: 22.4,
      33: 21.6,
      34: 20.9,
      35: 20.1,
      36: 19.3,
      37: 18.6,
      38: 17.8,
      39: 17.1,
      40: 16.4,
      41: 15.7,
      42: 15.1,
      43: 14.4,
      44: 13.8,
      45: 13.2,
      46: 12.7,
      47: 12.2,
      48: 11.7,
      49: 11.2,
      50: 10.8,
      51: 10.4,
      52: 10,
      53: 9.7,
      54: 9.4,
      55: 9.1,
   },	
   88: {	
      88: 65,
      89: 64.1,
      90: 63.1,
      91: 62.1,
      92: 61.1,
      93: 60.2,
      94: 59.2,
      95: 58.2,
      96: 57.3,
      97: 56.3,
      98: 55.4,
      99: 54.4,
      100: 53.4,
      101: 52.5,
      102: 51.5,
      103: 50.6,
      104: 49.6,
      105: 48.7,
      106: 47.7,
      107: 46.7,
      108: 45.8,
      109: 44.8,
      110: 43.9,
      111: 42.9,
      112: 42,
      113: 41,
      114: 40,
      115: 39.1,
      116: 38.2,
      117: 37.2,
      118: 36.3,
      119: 35.3,
      120: 34.4,
      20: 33.5,
      21: 32.6,
      22: 31.7,
      23: 30.8,
      24: 29.9,
      25: 29,
      26: 28.2,
      27: 27.3,
      28: 26.5,
      29: 25.6,
      30: 24.8,
      31: 24,
      32: 23.2,
      33: 22.4,
      34: 21.6,
      35: 20.8,
      36: 20,
      37: 19.2,
      38: 18.5,
      39: 17.7,
      40: 17,
      41: 16.3,
      42: 15.6,
      43: 14.9,
      44: 14.3,
      45: 13.7,
      46: 13.1,
      47: 12.5,
      48: 12,
      49: 11.5,
      50: 11,
      51: 10.5,
      52: 10.1,
      53: 9.8,
      54: 9.4,
      55: 9.1,
      56: 8.8,
   },	
   89: {	
      89: 65,
      90: 64.1,
      91: 63.1,
      92: 62.1,
      93: 61.1,
      94: 60.2,
      95: 59.2,
      96: 58.2,
      97: 57.3,
      98: 56.3,
      99: 55.4,
      100: 54.4,
      101: 53.4,
      102: 52.5,
      103: 51.5,
      104: 50.6,
      105: 49.6,
      106: 48.7,
      107: 47.7,
      108: 46.7,
      109: 45.8,
      110: 44.8,
      111: 43.9,
      112: 42.9,
      113: 41.9,
      114: 41,
      115: 40,
      116: 39.1,
      117: 38.1,
      118: 37.2,
      119: 36.3,
      120: 35.3,
      20: 34.4,
      21: 33.5,
      22: 32.6,
      23: 31.7,
      24: 30.8,
      25: 29.9,
      26: 29,
      27: 28.2,
      28: 27.3,
      29: 26.4,
      30: 25.6,
      31: 24.8,
      32: 24,
      33: 23.1,
      34: 22.3,
      35: 21.5,
      36: 20.7,
      37: 20,
      38: 19.2,
      39: 18.4,
      40: 17.7,
      41: 16.9,
      42: 16.2,
      43: 15.5,
      44: 14.8,
      45: 14.2,
      46: 13.5,
      47: 12.9,
      48: 12.3,
      49: 11.8,
      50: 11.3,
      51: 10.8,
      52: 10.3,
      53: 9.9,
      54: 9.5,
      55: 9.1,
      56: 8.8,
      57: 8.5,
   },	
   90: {	
      90: 65,
      91: 64.1,
      92: 63.1,
      93: 62.1,
      94: 61.1,
      95: 60.2,
      96: 59.2,
      97: 58.2,
      98: 57.3,
      99: 56.3,
      100: 55.4,
      101: 54.4,
      102: 53.4,
      103: 52.5,
      104: 51.5,
      105: 50.6,
      106: 49.6,
      107: 48.6,
      108: 47.7,
      109: 46.7,
      110: 45.8,
      111: 44.8,
      112: 43.9,
      113: 42.9,
      114: 41.9,
      115: 41,
      116: 40,
      117: 39.1,
      118: 38.1,
      119: 37.2,
      120: 36.3,
      20: 35.3,
      21: 34.4,
      22: 33.5,
      23: 32.6,
      24: 31.7,
      25: 30.8,
      26: 29.9,
      27: 29,
      28: 28.1,
      29: 27.3,
      30: 26.4,
      31: 25.6,
      32: 24.7,
      33: 23.9,
      34: 23.1,
      35: 22.3,
      36: 21.5,
      37: 20.7,
      38: 19.9,
      39: 19.1,
      40: 18.4,
      41: 17.6,
      42: 16.9,
      43: 16.1,
      44: 15.4,
      45: 14.8,
      46: 14.1,
      47: 13.4,
      48: 12.8,
      49: 12.2,
      50: 11.6,
      51: 11.1,
      52: 10.6,
      53: 10.1,
      54: 9.7,
      55: 9.3,
      56: 8.9,
      57: 8.6,
      58: 8.3,
      59: 8,
      60: 7.7,
      61: 7.5,
      62: 7.3,
      63: 7.1,
      64: 6.9,
      65: 6.8,
      66: 6.7,
      67: 6.6,
      68: 6.5,
   },	
   91: {	
      91: 65,
      92: 64.1,
      93: 63.1,
      94: 62.1,
      95: 61.1,
      96: 60.2,
      97: 59.2,
      98: 58.2,
      99: 57.3,
      100: 56.3,
      101: 55.3,
      102: 54.4,
      103: 53.4,
      104: 52.5,
      105: 51.5,
      106: 50.6,
      107: 49.6,
      108: 48.6,
      109: 47.7,
      110: 46.7,
      111: 45.8,
      112: 44.8,
      113: 43.9,
      114: 42.9,
      115: 41.9,
      116: 41,
      117: 40,
      118: 39.1,
      119: 38.1,
      120: 37.2,
      20: 36.2,
      21: 35.3,
      22: 34.4,
      23: 33.5,
      24: 32.5,
      25: 31.6,
      26: 30.7,
      27: 29.9,
      28: 29,
      29: 28.1,
      30: 27.3,
      31: 26.4,
      32: 25.6,
      33: 24.7,
      34: 23.9,
      35: 23.1,
      36: 22.3,
      37: 21.5,
      38: 20.7,
      39: 19.9,
      40: 19.1,
      41: 18.3,
      42: 17.5,
      43: 16.8,
      44: 16.1,
      45: 15.3,
      46: 14.6,
      47: 14,
      48: 13.3,
      49: 12.7,
      50: 12.1,
      51: 11.5,
      52: 10.9,
      53: 10.4,
      54: 9.9,
      55: 9.5,
      56: 9.1,
      57: 8.7,
      58: 8.3,
      59: 8,
      60: 7.7,
      61: 7.5,
      62: 7.2,
      63: 7,
      64: 6.8,
      65: 6.6,
      66: 6.5,
      67: 6.4,
      68: 6.2,
      69: 6.1,
   },	
   92: {	
      92: 65,
      93: 64.1,
      94: 63.1,
      95: 62.1,
      96: 61.1,
      97: 60.2,
      98: 59.2,
      99: 58.2,
      100: 57.3,
      101: 56.3,
      102: 55.3,
      103: 54.4,
      104: 53.4,
      105: 52.5,
      106: 51.5,
      107: 50.6,
      108: 49.6,
      109: 48.6,
      110: 47.7,
      111: 46.7,
      112: 45.8,
      113: 44.8,
      114: 43.8,
      115: 42.9,
      116: 41.9,
      117: 41,
      118: 40,
      119: 39.1,
      120: 38.1,
      20: 37.2,
      21: 36.2,
      22: 35.3,
      23: 34.4,
      24: 33.5,
      25: 32.5,
      26: 31.6,
      27: 30.7,
      28: 29.8,
      29: 29,
      30: 28.1,
      31: 27.2,
      32: 26.4,
      33: 25.5,
      34: 24.7,
      35: 23.9,
      36: 23,
      37: 22.2,
      38: 21.4,
      39: 20.6,
      40: 19.8,
      41: 19,
      42: 18.3,
      43: 17.5,
      44: 16.7,
      45: 16,
      46: 15.3,
      47: 14.6,
      48: 13.9,
      49: 13.2,
      50: 12.6,
      51: 11.9,
      52: 11.4,
      53: 10.8,
      54: 10.3,
      55: 9.8,
      56: 9.3,
      57: 8.9,
      58: 8.5,
      59: 8.1,
      60: 7.8,
      61: 7.5,
      62: 7.2,
      63: 7,
      64: 6.7,
      65: 6.5,
      66: 6.4,
      67: 6.2,
      68: 6.1,
      69: 5.9,
      70: 5.8,
   },	
   93: {	
      93: 65,
      94: 64.1,
      95: 63.1,
      96: 62.1,
      97: 61.1,
      98: 60.2,
      99: 59.2,
      100: 58.2,
      101: 57.3,
      102: 56.3,
      103: 55.3,
      104: 54.4,
      105: 53.4,
      106: 52.5,
      107: 51.5,
      108: 50.6,
      109: 49.6,
      110: 48.6,
      111: 47.7,
      112: 46.7,
      113: 45.8,
      114: 44.8,
      115: 43.8,
      116: 42.9,
      117: 41.9,
      118: 41,
      119: 40,
      120: 39.1,
      20: 38.1,
      21: 37.2,
      22: 36.2,
      23: 35.3,
      24: 34.4,
      25: 33.4,
      26: 32.5,
      27: 31.6,
      28: 30.7,
      29: 29.8,
      30: 29,
      31: 28.1,
      32: 27.2,
      33: 26.4,
      34: 25.5,
      35: 24.7,
      36: 23.8,
      37: 23,
      38: 22.2,
      39: 21.4,
      40: 20.6,
      41: 19.8,
      42: 19,
      43: 18.2,
      44: 17.4,
      45: 16.7,
      46: 15.9,
      47: 15.2,
      48: 14.5,
      49: 13.8,
      50: 13.1,
      51: 12.5,
      52: 11.9,
      53: 11.3,
      54: 10.7,
      55: 10.1,
      56: 9.6,
      57: 9.2,
      58: 8.7,
      59: 8.3,
      60: 7.9,
      61: 7.6,
      62: 7.3,
      63: 7,
      64: 6.7,
      65: 6.5,
      66: 6.3,
      67: 6.1,
      68: 5.9,
      69: 5.8,
      70: 5.7,
      71: 5.5,
   },	
   94: {	
      94: 65,
      95: 64.1,
      96: 63.1,
      97: 62.1,
      98: 61.1,
      99: 60.2,
      100: 59.2,
      101: 58.2,
      102: 57.3,
      103: 56.3,
      104: 55.3,
      105: 54.4,
      106: 53.4,
      107: 52.5,
      108: 51.5,
      109: 50.6,
      110: 49.6,
      111: 48.6,
      112: 47.7,
      113: 46.7,
      114: 45.8,
      115: 44.8,
      116: 43.8,
      117: 42.9,
      118: 41.9,
      119: 41,
      120: 40,
      20: 39.1,
      21: 38.1,
      22: 37.2,
      23: 36.2,
      24: 35.3,
      25: 34.4,
      26: 33.4,
      27: 32.5,
      28: 31.6,
      29: 30.7,
      30: 29.8,
      31: 28.9,
      32: 28.1,
      33: 27.2,
      34: 26.3,
      35: 25.5,
      36: 24.7,
      37: 23.8,
      38: 23,
      39: 22.2,
      40: 21.4,
      41: 20.6,
      42: 19.8,
      43: 19,
      44: 18.2,
      45: 17.4,
      46: 16.6,
      47: 15.9,
      48: 15.2,
      49: 14.4,
      50: 13.7,
      51: 13.1,
      52: 12.4,
      53: 11.8,
      54: 11.2,
      55: 10.6,
      56: 10,
      57: 9.5,
      58: 9,
      59: 8.6,
      60: 8.2,
      61: 7.8,
      62: 7.4,
      63: 7.1,
      64: 6.8,
      65: 6.5,
      66: 6.3,
      67: 6.1,
      68: 5.9,
      69: 5.7,
      70: 5.5,
      71: 5.4,
      72: 5.3,
   },	
   95: {	
      95: 65,
      96: 64.1,
      97: 63.1,
      98: 62.1,
      99: 61.1,
      100: 60.2,
      101: 59.2,
      102: 58.2,
      103: 57.3,
      104: 56.3,
      105: 55.3,
      106: 54.4,
      107: 53.4,
      108: 52.5,
      109: 51.5,
      110: 50.6,
      111: 49.6,
      112: 48.6,
      113: 47.7,
      114: 46.7,
      115: 45.8,
      116: 44.8,
      117: 43.8,
      118: 42.9,
      119: 41.9,
      120: 41,
      20: 40,
      21: 39.1,
      22: 38.1,
      23: 37.2,
      24: 36.2,
      25: 35.3,
      26: 34.4,
      27: 33.4,
      28: 32.5,
      29: 31.6,
      30: 30.7,
      31: 29.8,
      32: 28.9,
      33: 28.1,
      34: 27.2,
      35: 26.3,
      36: 25.5,
      37: 24.6,
      38: 23.8,
      39: 23,
      40: 22.2,
      41: 21.4,
      42: 20.6,
      43: 19.7,
      44: 18.9,
      45: 18.2,
      46: 17.4,
      47: 16.6,
      48: 15.9,
      49: 15.1,
      50: 14.4,
      51: 13.7,
      52: 13,
      53: 12.3,
      54: 11.7,
      55: 11.1,
      56: 10.5,
      57: 9.9,
      58: 9.4,
      59: 8.9,
      60: 8.5,
      61: 8,
      62: 7.6,
      63: 7.3,
      64: 6.9,
      65: 6.6,
      66: 6.4,
      67: 6.1,
      68: 5.9,
      69: 5.7,
      70: 5.5,
      71: 5.3,
      72: 5.2,
      73: 5,
   },	
   96: {	
      96: 65,
      97: 64.1,
      98: 63.1,
      99: 62.1,
      100: 61.1,
      101: 60.2,
      102: 59.2,
      103: 58.2,
      104: 57.3,
      105: 56.3,
      106: 55.3,
      107: 54.4,
      108: 53.4,
      109: 52.5,
      110: 51.5,
      111: 50.6,
      112: 49.6,
      113: 48.6,
      114: 47.7,
      115: 46.7,
      116: 45.8,
      117: 44.8,
      118: 43.8,
      119: 42.9,
      120: 41.9,
      20: 41,
      21: 40,
      22: 39.1,
      23: 38.1,
      24: 37.2,
      25: 36.2,
      26: 35.3,
      27: 34.3,
      28: 33.4,
      29: 32.5,
      30: 31.6,
      31: 30.7,
      32: 29.8,
      33: 28.9,
      34: 28,
      35: 27.2,
      36: 26.3,
      37: 25.5,
      38: 24.6,
      39: 23.8,
      40: 23,
      41: 22.2,
      42: 21.3,
      43: 20.5,
      44: 19.7,
      45: 18.9,
      46: 18.1,
      47: 17.4,
      48: 16.6,
      49: 15.8,
      50: 15.1,
      51: 14.3,
      52: 13.6,
      53: 12.9,
      54: 12.3,
      55: 11.6,
      56: 11,
      57: 10.4,
      58: 9.9,
      59: 9.3,
      60: 8.8,
      61: 8.4,
      62: 7.9,
      63: 7.5,
      64: 7.1,
      65: 6.8,
      66: 6.5,
      67: 6.2,
      68: 5.9,
      69: 5.7,
      70: 5.5,
      71: 5.3,
      72: 5.1,
      73: 5,
      74: 4.8,
   },	
   97: {	
      97: 65,
      98: 64.1,
      99: 63.1,
      100: 62.1,
      101: 61.1,
      102: 60.2,
      103: 59.2,
      104: 58.2,
      105: 57.3,
      106: 56.3,
      107: 55.3,
      108: 54.4,
      109: 53.4,
      110: 52.5,
      111: 51.5,
      112: 50.6,
      113: 49.6,
      114: 48.6,
      115: 47.7,
      116: 46.7,
      117: 45.8,
      118: 44.8,
      119: 43.8,
      120: 42.9,
      20: 41.9,
      21: 41,
      22: 40,
      23: 39.1,
      24: 38.1,
      25: 37.2,
      26: 36.2,
      27: 35.3,
      28: 34.3,
      29: 33.4,
      30: 32.5,
      31: 31.6,
      32: 30.7,
      33: 29.8,
      34: 28.9,
      35: 28,
      36: 27.2,
      37: 26.3,
      38: 25.5,
      39: 24.6,
      40: 23.8,
      41: 23,
      42: 22.1,
      43: 21.3,
      44: 20.5,
      45: 19.7,
      46: 18.9,
      47: 18.1,
      48: 17.3,
      49: 16.6,
      50: 15.8,
      51: 15,
      52: 14.3,
      53: 13.6,
      54: 12.9,
      55: 12.2,
      56: 11.6,
      57: 11,
      58: 10.4,
      59: 9.8,
      60: 9.2,
      61: 8.7,
      62: 8.3,
      63: 7.8,
      64: 7.4,
      65: 7,
      66: 6.7,
      67: 6.4,
      68: 6.1,
      69: 5.8,
      70: 5.5,
      71: 5.3,
      72: 5.1,
      73: 4.9,
      74: 4.8,
      75: 4.6,
   },	
   98: {	
      98: 65,
      99: 64.1,
      100: 63.1,
      101: 62.1,
      102: 61.1,
      103: 60.2,
      104: 59.2,
      105: 58.2,
      106: 57.3,
      107: 56.3,
      108: 55.3,
      109: 54.4,
      110: 53.4,
      111: 52.5,
      112: 51.5,
      113: 50.6,
      114: 49.6,
      115: 48.6,
      116: 47.7,
      117: 46.7,
      118: 45.8,
      119: 44.8,
      120: 43.8,
      20: 42.9,
      21: 41.9,
      22: 41,
      23: 40,
      24: 39.1,
      25: 38.1,
      26: 37.2,
      27: 36.2,
      28: 35.3,
      29: 34.3,
      30: 33.4,
      31: 32.5,
      32: 31.6,
      33: 30.7,
      34: 29.8,
      35: 28.9,
      36: 28,
      37: 27.2,
      38: 26.3,
      39: 25.5,
      40: 24.6,
      41: 23.8,
      42: 22.9,
      43: 22.1,
      44: 21.3,
      45: 20.5,
      46: 19.7,
      47: 18.9,
      48: 18.1,
      49: 17.3,
      50: 16.5,
      51: 15.8,
      52: 15,
      53: 14.3,
      54: 13.6,
      55: 12.9,
      56: 12.2,
      57: 11.5,
      58: 10.9,
      59: 10.3,
      60: 9.7,
      61: 9.2,
      62: 8.7,
      63: 8.2,
      64: 7.7,
      65: 7.3,
      66: 6.9,
      67: 6.6,
      68: 6.2,
      69: 5.9,
      70: 5.7,
      71: 5.4,
      72: 5.2,
      73: 5,
      74: 4.8,
      75: 4.6,
      76: 4.5,
   },	
   99: {	
      99: 65,
      100: 64.1,
      101: 63.1,
      102: 62.1,
      103: 61.1,
      104: 60.2,
      105: 59.2,
      106: 58.2,
      107: 57.3,
      108: 56.3,
      109: 55.3,
      110: 54.4,
      111: 53.4,
      112: 52.5,
      113: 51.5,
      114: 50.6,
      115: 49.6,
      116: 48.6,
      117: 47.7,
      118: 46.7,
      119: 45.8,
      120: 44.8,
      20: 43.8,
      21: 42.9,
      22: 41.9,
      23: 41,
      24: 40,
      25: 39.1,
      26: 38.1,
      27: 37.2,
      28: 36.2,
      29: 35.3,
      30: 34.3,
      31: 33.4,
      32: 32.5,
      33: 31.6,
      34: 30.7,
      35: 29.8,
      36: 28.9,
      37: 28,
      38: 27.2,
      39: 26.3,
      40: 25.4,
      41: 24.6,
      42: 23.8,
      43: 22.9,
      44: 22.1,
      45: 21.3,
      46: 20.5,
      47: 19.7,
      48: 18.9,
      49: 18.1,
      50: 17.3,
      51: 16.5,
      52: 15.7,
      53: 15,
      54: 14.3,
      55: 13.5,
      56: 12.8,
      57: 12.2,
      58: 11.5,
      59: 10.9,
      60: 10.2,
      61: 9.7,
      62: 9.1,
      63: 8.6,
      64: 8.1,
      65: 7.6,
      66: 7.2,
      67: 6.8,
      68: 6.5,
      69: 6.1,
      70: 5.8,
      71: 5.5,
      72: 5.3,
      73: 5,
      74: 4.8,
      75: 4.6,
      76: 4.5,
      77: 4.3,
   },	
   100: {	
      100: 65,
      101: 64.1,
      102: 63.1,
      103: 62.1,
      104: 61.1,
      105: 60.2,
      106: 59.2,
      107: 58.2,
      108: 57.3,
      109: 56.3,
      110: 55.3,
      111: 54.4,
      112: 53.4,
      113: 52.5,
      114: 51.5,
      115: 50.6,
      116: 49.6,
      117: 48.6,
      118: 47.7,
      119: 46.7,
      120: 45.8,
      20: 44.8,
      21: 43.8,
      22: 42.9,
      23: 41.9,
      24: 41,
      25: 40,
      26: 39,
      27: 38.1,
      28: 37.1,
      29: 36.2,
      30: 35.3,
      31: 34.3,
      32: 33.4,
      33: 32.5,
      34: 31.6,
      35: 30.7,
      36: 29.8,
      37: 28.9,
      38: 28,
      39: 27.1,
      40: 26.3,
      41: 25.4,
      42: 24.6,
      43: 23.8,
      44: 22.9,
      45: 22.1,
      46: 21.3,
      47: 20.5,
      48: 19.7,
      49: 18.9,
      50: 18.1,
      51: 17.3,
      52: 16.5,
      53: 15.7,
      54: 15,
      55: 14.2,
      56: 13.5,
      57: 12.8,
      58: 12.1,
      59: 11.5,
      60: 10.8,
      61: 10.2,
      62: 9.6,
      63: 9.1,
      64: 8.5,
      65: 8,
      66: 7.6,
      67: 7.2,
      68: 6.8,
      69: 6.4,
      70: 6,
      71: 5.7,
      72: 5.4,
      73: 5.2,
      74: 4.9,
      75: 4.7,
      76: 4.5,
      77: 4.3,
      78: 4.2,
      79: 4.1,
      80: 3.9,
      81: 3.8,
      82: 3.7,
      83: 3.7,
      84: 3.6,
      85: 3.6,
      86: 3.6,
      87: 3.6,
      88: 3.6,
   },	
   101: {	
      101: 65,
      102: 64.1,
      103: 63.1,
      104: 62.1,
      105: 61.1,
      106: 60.2,
      107: 59.2,
      108: 58.2,
      109: 57.3,
      110: 56.3,
      111: 55.3,
      112: 54.4,
      113: 53.4,
      114: 52.5,
      115: 51.5,
      116: 50.6,
      117: 49.6,
      118: 48.6,
      119: 47.7,
      120: 46.7,
      20: 45.8,
      21: 44.8,
      22: 43.8,
      23: 42.9,
      24: 41.9,
      25: 41,
      26: 40,
      27: 39,
      28: 38.1,
      29: 37.1,
      30: 36.2,
      31: 35.3,
      32: 34.3,
      33: 33.4,
      34: 32.5,
      35: 31.6,
      36: 30.7,
      37: 29.8,
      38: 28.9,
      39: 28,
      40: 27.1,
      41: 26.3,
      42: 25.4,
      43: 24.6,
      44: 23.8,
      45: 22.9,
      46: 22.1,
      47: 21.3,
      48: 20.5,
      49: 19.7,
      50: 18.9,
      51: 18.1,
      52: 17.3,
      53: 16.5,
      54: 15.7,
      55: 15,
      56: 14.2,
      57: 13.5,
      58: 12.8,
      59: 12.1,
      60: 11.4,
      61: 10.8,
      62: 10.2,
      63: 9.6,
      64: 9,
      65: 8.5,
      66: 8,
      67: 7.5,
      68: 7.1,
      69: 6.7,
      70: 6.3,
      71: 6,
      72: 5.6,
      73: 5.3,
      74: 5.1,
      75: 4.8,
      76: 4.6,
      77: 4.4,
      78: 4.2,
      79: 4.1,
      80: 3.9,
      81: 3.8,
      82: 3.7,
      83: 3.6,
      84: 3.5,
      85: 3.5,
      86: 3.5,
      87: 3.4,
      88: 3.4,
      89: 3.4,
   },	
   102: {	
      102: 65,
      103: 64.1,
      104: 63.1,
      105: 62.1,
      106: 61.1,
      107: 60.2,
      108: 59.2,
      109: 58.2,
      110: 57.3,
      111: 56.3,
      112: 55.3,
      113: 54.4,
      114: 53.4,
      115: 52.5,
      116: 51.5,
      117: 50.6,
      118: 49.6,
      119: 48.6,
      120: 47.7,
      20: 46.7,
      21: 45.8,
      22: 44.8,
      23: 43.8,
      24: 42.9,
      25: 41.9,
      26: 41,
      27: 40,
      28: 39,
      29: 38.1,
      30: 37.1,
      31: 36.2,
      32: 35.3,
      33: 34.3,
      34: 33.4,
      35: 32.5,
      36: 31.6,
      37: 30.7,
      38: 29.8,
      39: 28.9,
      40: 28,
      41: 27.1,
      42: 26.3,
      43: 25.4,
      44: 24.6,
      45: 23.7,
      46: 22.9,
      47: 22.1,
      48: 21.3,
      49: 20.5,
      50: 19.7,
      51: 18.8,
      52: 18,
      53: 17.3,
      54: 16.5,
      55: 15.7,
      56: 14.9,
      57: 14.2,
      58: 13.5,
      59: 12.8,
      60: 12.1,
      61: 11.4,
      62: 10.8,
      63: 10.1,
      64: 9.6,
      65: 9,
      66: 8.5,
      67: 8,
      68: 7.5,
      69: 7,
      70: 6.6,
      71: 6.3,
      72: 5.9,
      73: 5.6,
      74: 5.3,
      75: 5,
      76: 4.7,
      77: 4.5,
      78: 4.3,
      79: 4.1,
      80: 4,
      81: 3.8,
      82: 3.7,
      83: 3.6,
      84: 3.5,
      85: 3.4,
      86: 3.4,
      87: 3.3,
      88: 3.3,
      89: 3.3,
      90: 3.3,
   },	
   103: {	
      103: 65,
      104: 64.1,
      105: 63.1,
      106: 62.1,
      107: 61.1,
      108: 60.2,
      109: 59.2,
      110: 58.2,
      111: 57.3,
      112: 56.3,
      113: 55.3,
      114: 54.4,
      115: 53.4,
      116: 52.5,
      117: 51.5,
      118: 50.5,
      119: 49.6,
      120: 48.6,
      20: 47.7,
      21: 46.7,
      22: 45.8,
      23: 44.8,
      24: 43.8,
      25: 42.9,
      26: 41.9,
      27: 41,
      28: 40,
      29: 39,
      30: 38.1,
      31: 37.1,
      32: 36.2,
      33: 35.3,
      34: 34.3,
      35: 33.4,
      36: 32.5,
      37: 31.6,
      38: 30.7,
      39: 29.8,
      40: 28.9,
      41: 28,
      42: 27.1,
      43: 26.3,
      44: 25.4,
      45: 24.6,
      46: 23.7,
      47: 22.9,
      48: 22.1,
      49: 21.3,
      50: 20.5,
      51: 19.6,
      52: 18.8,
      53: 18,
      54: 17.3,
      55: 16.5,
      56: 15.7,
      57: 14.9,
      58: 14.2,
      59: 13.5,
      60: 12.8,
      61: 12.1,
      62: 11.4,
      63: 10.7,
      64: 10.1,
      65: 9.5,
      66: 9,
      67: 8.4,
      68: 7.9,
      69: 7.4,
      70: 7,
      71: 6.6,
      72: 6.2,
      73: 5.9,
      74: 5.5,
      75: 5.2,
      76: 4.9,
      77: 4.7,
      78: 4.5,
      79: 4.2,
      80: 4.1,
      81: 3.9,
      82: 3.7,
      83: 3.6,
      84: 3.5,
      85: 3.4,
      86: 3.3,
      87: 3.3,
      88: 3.2,
      89: 3.2,
      90: 3.2,
      91: 3.2,
   },	
   104: {	
      104: 65,
      105: 64.1,
      106: 63.1,
      107: 62.1,
      108: 61.1,
      109: 60.2,
      110: 59.2,
      111: 58.2,
      112: 57.3,
      113: 56.3,
      114: 55.3,
      115: 54.4,
      116: 53.4,
      117: 52.5,
      118: 51.5,
      119: 50.5,
      120: 49.6,
      20: 48.6,
      21: 47.7,
      22: 46.7,
      23: 45.8,
      24: 44.8,
      25: 43.8,
      26: 42.9,
      27: 41.9,
      28: 41,
      29: 40,
      30: 39,
      31: 38.1,
      32: 37.1,
      33: 36.2,
      34: 35.3,
      35: 34.3,
      36: 33.4,
      37: 32.5,
      38: 31.6,
      39: 30.7,
      40: 29.8,
      41: 28.9,
      42: 28,
      43: 27.1,
      44: 26.3,
      45: 25.4,
      46: 24.6,
      47: 23.7,
      48: 22.9,
      49: 22.1,
      50: 21.3,
      51: 20.5,
      52: 19.6,
      53: 18.8,
      54: 18,
      55: 17.2,
      56: 16.5,
      57: 15.7,
      58: 14.9,
      59: 14.2,
      60: 13.5,
      61: 12.7,
      62: 12,
      63: 11.4,
      64: 10.7,
      65: 10.1,
      66: 9.5,
      67: 8.9,
      68: 8.4,
      69: 7.9,
      70: 7.4,
      71: 7,
      72: 6.6,
      73: 6.2,
      74: 5.8,
      75: 5.5,
      76: 5.2,
      77: 4.9,
      78: 4.6,
      79: 4.4,
      80: 4.2,
      81: 4,
      82: 3.8,
      83: 3.7,
      84: 3.5,
      85: 3.4,
      86: 3.3,
      87: 3.3,
      88: 3.2,
      89: 3.2,
      90: 3.2,
      91: 3.1,
      92: 3.1,
   },	
   105: {	
      105: 65,
      106: 64.1,
      107: 63.1,
      108: 62.1,
      109: 61.1,
      110: 60.2,
      111: 59.2,
      112: 58.2,
      113: 57.3,
      114: 56.3,
      115: 55.3,
      116: 54.4,
      117: 53.4,
      118: 52.5,
      119: 51.5,
      120: 50.5,
      20: 49.6,
      21: 48.6,
      22: 47.7,
      23: 46.7,
      24: 45.7,
      25: 44.8,
      26: 43.8,
      27: 42.9,
      28: 41.9,
      29: 41,
      30: 40,
      31: 39,
      32: 38.1,
      33: 37.1,
      34: 36.2,
      35: 35.3,
      36: 34.3,
      37: 33.4,
      38: 32.5,
      39: 31.6,
      40: 30.7,
      41: 29.8,
      42: 28.9,
      43: 28,
      44: 27.1,
      45: 26.3,
      46: 25.4,
      47: 24.6,
      48: 23.7,
      49: 22.9,
      50: 22.1,
      51: 21.3,
      52: 20.5,
      53: 19.6,
      54: 18.8,
      55: 18,
      56: 17.2,
      57: 16.5,
      58: 15.7,
      59: 14.9,
      60: 14.2,
      61: 13.4,
      62: 12.7,
      63: 12,
      64: 11.4,
      65: 10.7,
      66: 10.1,
      67: 9.5,
      68: 8.9,
      69: 8.4,
      70: 7.9,
      71: 7.4,
      72: 6.9,
      73: 6.5,
      74: 6.1,
      75: 5.8,
      76: 5.4,
      77: 5.1,
      78: 4.9,
      79: 4.6,
      80: 4.4,
      81: 4.1,
      82: 4,
      83: 3.8,
      84: 3.6,
      85: 3.5,
      86: 3.4,
      87: 3.3,
      88: 3.2,
      89: 3.1,
      90: 3.1,
      91: 3.1,
      92: 3.1,
      93: 3.1,
   },	
   106: {	
      106: 65,
      107: 64.1,
      108: 63.1,
      109: 62.1,
      110: 61.1,
      111: 60.2,
      112: 59.2,
      113: 58.2,
      114: 57.3,
      115: 56.3,
      116: 55.3,
      117: 54.4,
      118: 53.4,
      119: 52.5,
      120: 51.5,
      20: 50.5,
      21: 49.6,
      22: 48.6,
      23: 47.7,
      24: 46.7,
      25: 45.7,
      26: 44.8,
      27: 43.8,
      28: 42.9,
      29: 41.9,
      30: 41,
      31: 40,
      32: 39,
      33: 38.1,
      34: 37.1,
      35: 36.2,
      36: 35.3,
      37: 34.3,
      38: 33.4,
      39: 32.5,
      40: 31.6,
      41: 30.7,
      42: 29.8,
      43: 28.9,
      44: 28,
      45: 27.1,
      46: 26.3,
      47: 25.4,
      48: 24.6,
      49: 23.7,
      50: 22.9,
      51: 22.1,
      52: 21.3,
      53: 20.5,
      54: 19.6,
      55: 18.8,
      56: 18,
      57: 17.2,
      58: 16.5,
      59: 15.7,
      60: 14.9,
      61: 14.2,
      62: 13.4,
      63: 12.7,
      64: 12,
      65: 11.4,
      66: 10.7,
      67: 10.1,
      68: 9.5,
      69: 8.9,
      70: 8.4,
      71: 7.9,
      72: 7.4,
      73: 6.9,
      74: 6.5,
      75: 6.1,
      76: 5.8,
      77: 5.4,
      78: 5.1,
      79: 4.8,
      80: 4.6,
      81: 4.3,
      82: 4.1,
      83: 3.9,
      84: 3.8,
      85: 3.6,
      86: 3.5,
      87: 3.3,
      88: 3.2,
      89: 3.2,
      90: 3.1,
      91: 3.1,
      92: 3.1,
      93: 3,
      94: 3,
   },	
   107: {	
      107: 65,
      108: 64.1,
      109: 63.1,
      110: 62.1,
      111: 61.1,
      112: 60.2,
      113: 59.2,
      114: 58.2,
      115: 57.3,
      116: 56.3,
      117: 55.3,
      118: 54.4,
      119: 53.4,
      120: 52.5,
      20: 51.5,
      21: 50.5,
      22: 49.6,
      23: 48.6,
      24: 47.7,
      25: 46.7,
      26: 45.7,
      27: 44.8,
      28: 43.8,
      29: 42.9,
      30: 41.9,
      31: 41,
      32: 40,
      33: 39,
      34: 38.1,
      35: 37.1,
      36: 36.2,
      37: 35.3,
      38: 34.3,
      39: 33.4,
      40: 32.5,
      41: 31.6,
      42: 30.7,
      43: 29.8,
      44: 28.9,
      45: 28,
      46: 27.1,
      47: 26.3,
      48: 25.4,
      49: 24.6,
      50: 23.7,
      51: 22.9,
      52: 22.1,
      53: 21.3,
      54: 20.5,
      55: 19.6,
      56: 18.8,
      57: 18,
      58: 17.2,
      59: 16.5,
      60: 15.7,
      61: 14.9,
      62: 14.2,
      63: 13.4,
      64: 12.7,
      65: 12,
      66: 11.4,
      67: 10.7,
      68: 10.1,
      69: 9.5,
      70: 8.9,
      71: 8.4,
      72: 7.9,
      73: 7.4,
      74: 6.9,
      75: 6.5,
      76: 6.1,
      77: 5.8,
      78: 5.4,
      79: 5.1,
      80: 4.8,
      81: 4.6,
      82: 4.3,
      83: 4.1,
      84: 3.9,
      85: 3.7,
      86: 3.6,
      87: 3.4,
      88: 3.3,
      89: 3.2,
      90: 3.2,
      91: 3.1,
      92: 3.1,
      93: 3,
      94: 3,
      95: 3,
   },	
   108: {	
      108: 65,
      109: 64.1,
      110: 63.1,
      111: 62.1,
      112: 61.1,
      113: 60.2,
      114: 59.2,
      115: 58.2,
      116: 57.3,
      117: 56.3,
      118: 55.3,
      119: 54.4,
      120: 53.4,
      20: 52.5,
      21: 51.5,
      22: 50.5,
      23: 49.6,
      24: 48.6,
      25: 47.7,
      26: 46.7,
      27: 45.7,
      28: 44.8,
      29: 43.8,
      30: 42.9,
      31: 41.9,
      32: 41,
      33: 40,
      34: 39,
      35: 38.1,
      36: 37.1,
      37: 36.2,
      38: 35.3,
      39: 34.3,
      40: 33.4,
      41: 32.5,
      42: 31.6,
      43: 30.7,
      44: 29.8,
      45: 28.9,
      46: 28,
      47: 27.1,
      48: 26.3,
      49: 25.4,
      50: 24.6,
      51: 23.7,
      52: 22.9,
      53: 22.1,
      54: 21.3,
      55: 20.5,
      56: 19.6,
      57: 18.8,
      58: 18,
      59: 17.2,
      60: 16.5,
      61: 15.7,
      62: 14.9,
      63: 14.2,
      64: 13.4,
      65: 12.7,
      66: 12,
      67: 11.4,
      68: 10.7,
      69: 10.1,
      70: 9.5,
      71: 8.9,
      72: 8.4,
      73: 7.8,
      74: 7.4,
      75: 6.9,
      76: 6.5,
      77: 6.1,
      78: 5.7,
      79: 5.4,
      80: 5.1,
      81: 4.8,
      82: 4.5,
      83: 4.3,
      84: 4.1,
      85: 3.9,
      86: 3.7,
      87: 3.6,
      88: 3.4,
      89: 3.3,
      90: 3.2,
      91: 3.1,
      92: 3.1,
      93: 3,
      94: 3,
      95: 3,
      96: 3,
   },	
   109: {	
      109: 65,
      110: 64.1,
      111: 63.1,
      112: 62.1,
      113: 61.1,
      114: 60.2,
      115: 59.2,
      116: 58.2,
      117: 57.3,
      118: 56.3,
      119: 55.3,
      120: 54.4,
      20: 53.4,
      21: 52.5,
      22: 51.5,
      23: 50.5,
      24: 49.6,
      25: 48.6,
      26: 47.7,
      27: 46.7,
      28: 45.7,
      29: 44.8,
      30: 43.8,
      31: 42.9,
      32: 41.9,
      33: 41,
      34: 40,
      35: 39,
      36: 38.1,
      37: 37.1,
      38: 36.2,
      39: 35.3,
      40: 34.3,
      41: 33.4,
      42: 32.5,
      43: 31.6,
      44: 30.7,
      45: 29.8,
      46: 28.9,
      47: 28,
      48: 27.1,
      49: 26.3,
      50: 25.4,
      51: 24.6,
      52: 23.7,
      53: 22.9,
      54: 22.1,
      55: 21.3,
      56: 20.4,
      57: 19.6,
      58: 18.8,
      59: 18,
      60: 17.2,
      61: 16.4,
      62: 15.7,
      63: 14.9,
      64: 14.2,
      65: 13.4,
      66: 12.7,
      67: 12,
      68: 11.3,
      69: 10.7,
      70: 10.1,
      71: 9.5,
      72: 8.9,
      73: 8.4,
      74: 7.8,
      75: 7.4,
      76: 6.9,
      77: 6.5,
      78: 6.1,
      79: 5.7,
      80: 5.4,
      81: 5.1,
      82: 4.8,
      83: 4.5,
      84: 4.3,
      85: 4.1,
      86: 3.9,
      87: 3.7,
      88: 3.6,
      89: 3.4,
      90: 3.3,
      91: 3.2,
      92: 3.1,
      93: 3.1,
      94: 3,
      95: 3,
      96: 3,
      97: 3,
   },	
   110: {	
      110: 65,
      111: 64.1,
      112: 63.1,
      113: 62.1,
      114: 61.1,
      115: 60.2,
      116: 59.2,
      117: 58.2,
      118: 57.3,
      119: 56.3,
      120: 55.3,
      20: 54.4,
      21: 53.4,
      22: 52.5,
      23: 51.5,
      24: 50.5,
      25: 49.6,
      26: 48.6,
      27: 47.7,
      28: 46.7,
      29: 45.7,
      30: 44.8,
      31: 43.8,
      32: 42.9,
      33: 41.9,
      34: 41,
      35: 40,
      36: 39,
      37: 38.1,
      38: 37.1,
      39: 36.2,
      40: 35.3,
      41: 34.3,
      42: 33.4,
      43: 32.5,
      44: 31.6,
      45: 30.7,
      46: 29.8,
      47: 28.9,
      48: 28,
      49: 27.1,
      50: 26.3,
      51: 25.4,
      52: 24.6,
      53: 23.7,
      54: 22.9,
      55: 22.1,
      56: 21.3,
      57: 20.4,
      58: 19.6,
      59: 18.8,
      60: 18,
      61: 17.2,
      62: 16.4,
      63: 15.7,
      64: 14.9,
      65: 14.2,
      66: 13.4,
      67: 12.7,
      68: 12,
      69: 11.3,
      70: 10.7,
      71: 10.1,
      72: 9.5,
      73: 8.9,
      74: 8.3,
      75: 7.8,
      76: 7.4,
      77: 6.9,
      78: 6.5,
      79: 6.1,
      80: 5.7,
      81: 5.4,
      82: 5.1,
      83: 4.8,
      84: 4.5,
      85: 4.3,
      86: 4.1,
      87: 3.9,
      88: 3.7,
      89: 3.5,
      90: 3.4,
      91: 3.3,
      92: 3.2,
      93: 3.1,
      94: 3.1,
      95: 3,
      96: 3,
      97: 3,
      98: 3,
      99: 3,
      100: 2.9,
      101: 2.9,
      102: 2.9,
      103: 2.9,
      104: 2.8,
      105: 2.7,
      106: 2.6,
      107: 2.5,
      108: 2.2,
      109: 2
   },	
   111: {	
      111: 65,
      112: 64.1,
      113: 63.1,
      114: 62.1,
      115: 61.1,
      116: 60.2,
      117: 59.2,
      118: 58.2,
      119: 57.3,
      120: 56.3,
      20: 55.3,
      21: 54.4,
      22: 53.4,
      23: 52.5,
      24: 51.5,
      25: 50.5,
      26: 49.6,
      27: 48.6,
      28: 47.7,
      29: 46.7,
      30: 45.7,
      31: 44.8,
      32: 43.8,
      33: 42.9,
      34: 41.9,
      35: 41,
      36: 40,
      37: 39,
      38: 38.1,
      39: 37.1,
      40: 36.2,
      41: 35.3,
      42: 34.3,
      43: 33.4,
      44: 32.5,
      45: 31.6,
      46: 30.7,
      47: 29.8,
      48: 28.9,
      49: 28,
      50: 27.1,
      51: 26.3,
      52: 25.4,
      53: 24.6,
      54: 23.7,
      55: 22.9,
      56: 22.1,
      57: 21.3,
      58: 20.4,
      59: 19.6,
      60: 18.8,
      61: 18,
      62: 17.2,
      63: 16.4,
      64: 15.7,
      65: 14.9,
      66: 14.2,
      67: 13.4,
      68: 12.7,
      69: 12,
      70: 11.3,
      71: 10.7,
      72: 10.1,
      73: 9.5,
      74: 8.9,
      75: 8.3,
      76: 7.8,
      77: 7.3,
      78: 6.9,
      79: 6.5,
      80: 6.1,
      81: 5.7,
      82: 5.4,
      83: 5.1,
      84: 4.8,
      85: 4.5,
      86: 4.3,
      87: 4.1,
      88: 3.9,
      89: 3.7,
      90: 3.5,
      91: 3.4,
      92: 3.3,
      93: 3.2,
      94: 3.1,
      95: 3,
      96: 3,
      97: 3,
      98: 3,
      99: 3,
      100: 2.9,
      101: 2.9,
      102: 2.9,
      103: 2.9,
      104: 2.8,
      105: 2.8,
      106: 2.7,
      107: 2.6,
      108: 2.4,
      109: 2.2,
      110: 2
   },	
   112: {	
      112: 65,
      113: 64.1,
      114: 63.1,
      115: 62.1,
      116: 61.1,
      117: 60.2,
      118: 59.2,
      119: 58.2,
      120: 57.3,
      20: 56.3,
      21: 55.3,
      22: 54.4,
      23: 53.4,
      24: 52.5,
      25: 51.5,
      26: 50.5,
      27: 49.6,
      28: 48.6,
      29: 47.7,
      30: 46.7,
      31: 45.7,
      32: 44.8,
      33: 43.8,
      34: 42.9,
      35: 41.9,
      36: 41,
      37: 40,
      38: 39,
      39: 38.1,
      40: 37.1,
      41: 36.2,
      42: 35.3,
      43: 34.3,
      44: 33.4,
      45: 32.5,
      46: 31.6,
      47: 30.7,
      48: 29.8,
      49: 28.9,
      50: 28,
      51: 27.1,
      52: 26.3,
      53: 25.4,
      54: 24.6,
      55: 23.7,
      56: 22.9,
      57: 22.1,
      58: 21.3,
      59: 20.4,
      60: 19.6,
      61: 18.8,
      62: 18,
      63: 17.2,
      64: 16.4,
      65: 15.7,
      66: 14.9,
      67: 14.2,
      68: 13.4,
      69: 12.7,
      70: 12,
      71: 11.3,
      72: 10.7,
      73: 10.1,
      74: 9.5,
      75: 8.9,
      76: 8.3,
      77: 7.8,
      78: 7.3,
      79: 6.9,
      80: 6.5,
      81: 6.1,
      82: 5.7,
      83: 5.4,
      84: 5.1,
      85: 4.8,
      86: 4.5,
      87: 4.3,
      88: 4,
      89: 3.8,
      90: 3.7,
      91: 3.5,
      92: 3.4,
      93: 3.2,
      94: 3.1,
      95: 3.1,
      96: 3,
      97: 3,
      98: 2.9,
      99: 2.9,
      100: 2.9,
      101: 2.9,
      102: 2.9,
      103: 2.9,
      104: 2.9,
      105: 2.8,
      106: 2.8,
      107: 2.7,
      108: 2.6,
      109: 2.4,
      110: 2.2,
      111: 2
   },	
   113: {	
      113: 65,
      114: 64.1,
      115: 63.1,
      116: 62.1,
      117: 61.1,
      118: 60.2,
      119: 59.2,
      120: 58.2,
      20: 57.3,
      21: 56.3,
      22: 55.3,
      23: 54.4,
      24: 53.4,
      25: 52.5,
      26: 51.5,
      27: 50.5,
      28: 49.6,
      29: 48.6,
      30: 47.7,
      31: 46.7,
      32: 45.7,
      33: 44.8,
      34: 43.8,
      35: 42.9,
      36: 41.9,
      37: 41,
      38: 40,
      39: 39,
      40: 38.1,
      41: 37.1,
      42: 36.2,
      43: 35.3,
      44: 34.3,
      45: 33.4,
      46: 32.5,
      47: 31.6,
      48: 30.7,
      49: 29.8,
      50: 28.9,
      51: 28,
      52: 27.1,
      53: 26.3,
      54: 25.4,
      55: 24.6,
      56: 23.7,
      57: 22.9,
      58: 22.1,
      59: 21.3,
      60: 20.4,
      61: 19.6,
      62: 18.8,
      63: 18,
      64: 17.2,
      65: 16.4,
      66: 15.7,
      67: 14.9,
      68: 14.2,
      69: 13.4,
      70: 12.7,
      71: 12,
      72: 11.3,
      73: 10.7,
      74: 10,
      75: 9.4,
      76: 8.9,
      77: 8.3,
      78: 7.8,
      79: 7.3,
      80: 6.9,
      81: 6.4,
      82: 6.1,
      83: 5.7,
      84: 5.3,
      85: 5,
      86: 4.7,
      87: 4.5,
      88: 4.2,
      89: 4,
      90: 3.8,
      91: 3.6,
      92: 3.5,
      93: 3.4,
      94: 3.2,
      95: 3.1,
      96: 3.1,
      97: 3,
      98: 3,
      99: 2.9,
      100: 2.9,
      101: 2.9,
      102: 2.9,
      103: 2.9,
      104: 2.9,
      105: 2.8,
      106: 2.8,
      107: 2.8,
      108: 2.7,
      109: 2.6,
      110: 2.4,
      111: 2.2,
      112: 1.9
   },	
   114: {	
      114: 65,
      115: 64.1,
      116: 63.1,
      117: 62.1,
      118: 61.1,
      119: 60.2,
      120: 59.2,
      20: 58.2,
      21: 57.3,
      22: 56.3,
      23: 55.3,
      24: 54.4,
      25: 53.4,
      26: 52.5,
      27: 51.5,
      28: 50.5,
      29: 49.6,
      30: 48.6,
      31: 47.7,
      32: 46.7,
      33: 45.7,
      34: 44.8,
      35: 43.8,
      36: 42.9,
      37: 41.9,
      38: 41,
      39: 40,
      40: 39,
      41: 38.1,
      42: 37.1,
      43: 36.2,
      44: 35.3,
      45: 34.3,
      46: 33.4,
      47: 32.5,
      48: 31.6,
      49: 30.7,
      50: 29.8,
      51: 28.9,
      52: 28,
      53: 27.1,
      54: 26.3,
      55: 25.4,
      56: 24.6,
      57: 23.7,
      58: 22.9,
      59: 22.1,
      60: 21.3,
      61: 20.4,
      62: 19.6,
      63: 18.8,
      64: 18,
      65: 17.2,
      66: 16.4,
      67: 15.7,
      68: 14.9,
      69: 14.1,
      70: 13.4,
      71: 12.7,
      72: 12,
      73: 11.3,
      74: 10.7,
      75: 10,
      76: 9.4,
      77: 8.9,
      78: 8.3,
      79: 7.8,
      80: 7.3,
      81: 6.9,
      82: 6.4,
      83: 6,
      84: 5.7,
      85: 5.3,
      86: 5,
      87: 4.7,
      88: 4.4,
      89: 4.2,
      90: 4,
      91: 3.8,
      92: 3.6,
      93: 3.5,
      94: 3.3,
      95: 3.2,
      96: 3.1,
      97: 3,
      98: 3,
      99: 2.9,
      100: 2.9,
      101: 2.9,
      102: 2.9,
      103: 2.9,
      104: 2.8,
      105: 2.8,
      106: 2.8,
      107: 2.8,
      108: 2.7,
      109: 2.6,
      110: 2.5,
      111: 2.4,
      112: 2.1,
      113: 1.9
   },	
   115: {	
      115: 65,
      116: 64.1,
      117: 63.1,
      118: 62.1,
      119: 61.1,
      120: 60.2,
      20: 59.2,
      21: 58.2,
      22: 57.3,
      23: 56.3,
      24: 55.3,
      25: 54.4,
      26: 53.4,
      27: 52.5,
      28: 51.5,
      29: 50.5,
      30: 49.6,
      31: 48.6,
      32: 47.7,
      33: 46.7,
      34: 45.7,
      35: 44.8,
      36: 43.8,
      37: 42.9,
      38: 41.9,
      39: 41,
      40: 40,
      41: 39,
      42: 38.1,
      43: 37.1,
      44: 36.2,
      45: 35.3,
      46: 34.3,
      47: 33.4,
      48: 32.5,
      49: 31.6,
      50: 30.7,
      51: 29.8,
      52: 28.9,
      53: 28,
      54: 27.1,
      55: 26.3,
      56: 25.4,
      57: 24.6,
      58: 23.7,
      59: 22.9,
      60: 22.1,
      61: 21.3,
      62: 20.4,
      63: 19.6,
      64: 18.8,
      65: 18,
      66: 17.2,
      67: 16.4,
      68: 15.7,
      69: 14.9,
      70: 14.1,
      71: 13.4,
      72: 12.7,
      73: 12,
      74: 11.3,
      75: 10.7,
      76: 10,
      77: 9.4,
      78: 8.8,
      79: 8.3,
      80: 7.8,
      81: 7.3,
      82: 6.8,
      83: 6.4,
      84: 6,
      85: 5.6,
      86: 5.3,
      87: 5,
      88: 4.7,
      89: 4.4,
      90: 4.2,
      91: 4,
      92: 3.8,
      93: 3.6,
      94: 3.4,
      95: 3.3,
      96: 3.2,
      97: 3.1,
      98: 3,
      99: 2.9,
      100: 2.9,
      101: 2.9,
      102: 2.8,
      103: 2.8,
      104: 2.8,
      105: 2.8,
      106: 2.8,
      107: 2.8,
      108: 2.7,
      109: 2.7,
      110: 2.6,
      111: 2.5,
      112: 2.3,
      113: 2.1,
      114: 1.8
   },	
   116: {	
      116: 65,
      117: 64.1,
      118: 63.1,
      119: 62.1,
      120: 61.1,
      20: 60.2,
      21: 59.2,
      22: 58.2,
      23: 57.3,
      24: 56.3,
      25: 55.3,
      26: 54.4,
      27: 53.4,
      28: 52.5,
      29: 51.5,
      30: 50.5,
      31: 49.6,
      32: 48.6,
      33: 47.7,
      34: 46.7,
      35: 45.7,
      36: 44.8,
      37: 43.8,
      38: 42.9,
      39: 41.9,
      40: 41,
      41: 40,
      42: 39,
      43: 38.1,
      44: 37.1,
      45: 36.2,
      46: 35.3,
      47: 34.3,
      48: 33.4,
      49: 32.5,
      50: 31.6,
      51: 30.7,
      52: 29.8,
      53: 28.9,
      54: 28,
      55: 27.1,
      56: 26.3,
      57: 25.4,
      58: 24.6,
      59: 23.7,
      60: 22.9,
      61: 22.1,
      62: 21.3,
      63: 20.4,
      64: 19.6,
      65: 18.8,
      66: 18,
      67: 17.2,
      68: 16.4,
      69: 15.6,
      70: 14.9,
      71: 14.1,
      72: 13.4,
      73: 12.7,
      74: 12,
      75: 11.3,
      76: 10.6,
      77: 10,
      78: 9.4,
      79: 8.8,
      80: 8.3,
      81: 7.7,
      82: 7.3,
      83: 6.8,
      84: 6.4,
      85: 6,
      86: 5.6,
      87: 5.2,
      88: 4.9,
      89: 4.6,
      90: 4.4,
      91: 4.1,
      92: 3.9,
      93: 3.7,
      94: 3.5,
      95: 3.3,
      96: 3.2,
      97: 3.1,
      98: 3,
      99: 2.9,
      100: 2.8,
      101: 2.8,
      102: 2.8,
      103: 2.8,
      104: 2.8,
      105: 2.7,
      106: 2.7,
      107: 2.7,
      108: 2.7,
      109: 2.6,
      110: 2.6,
      111: 2.5,
      112: 2.4,
      113: 2.2,
      114: 2,
      115: 1.8
   },	
   117: {	
      117: 65,
      118: 64.1,
      119: 63.1,
      120: 62.1,
      20: 61.1,
      21: 60.2,
      22: 59.2,
      23: 58.2,
      24: 57.3,
      25: 56.3,
      26: 55.3,
      27: 54.4,
      28: 53.4,
      29: 52.5,
      30: 51.5,
      31: 50.5,
      32: 49.6,
      33: 48.6,
      34: 47.7,
      35: 46.7,
      36: 45.7,
      37: 44.8,
      38: 43.8,
      39: 42.9,
      40: 41.9,
      41: 41,
      42: 40,
      43: 39,
      44: 38.1,
      45: 37.1,
      46: 36.2,
      47: 35.3,
      48: 34.3,
      49: 33.4,
      50: 32.5,
      51: 31.6,
      52: 30.7,
      53: 29.8,
      54: 28.9,
      55: 28,
      56: 27.1,
      57: 26.3,
      58: 25.4,
      59: 24.6,
      60: 23.7,
      61: 22.9,
      62: 22.1,
      63: 21.2,
      64: 20.4,
      65: 19.6,
      66: 18.8,
      67: 18,
      68: 17.2,
      69: 16.4,
      70: 15.6,
      71: 14.9,
      72: 14.1,
      73: 13.4,
      74: 12.7,
      75: 12,
      76: 11.3,
      77: 10.6,
      78: 10,
      79: 9.4,
      80: 8.8,
      81: 8.2,
      82: 7.7,
      83: 7.2,
      84: 6.8,
      85: 6.3,
      86: 5.9,
      87: 5.5,
      88: 5.2,
      89: 4.9,
      90: 4.6,
      91: 4.3,
      92: 4,
      93: 3.8,
      94: 3.6,
      95: 3.4,
      96: 3.3,
      97: 3.1,
      98: 3,
      99: 2.9,
      100: 2.8,
      101: 2.7,
      102: 2.7,
      103: 2.7,
      104: 2.7,
      105: 2.6,
      106: 2.6,
      107: 2.6,
      108: 2.6,
      109: 2.6,
      110: 2.5,
      111: 2.5,
      112: 2.4,
      113: 2.3,
      114: 2.1,
      115: 1.9,
      116: 1.6
   },	
   118: {	
      118: 65,
      119: 64.1,
      120: 63.1,
      20: 62.1,
      21: 61.1,
      22: 60.2,
      23: 59.2,
      24: 58.2,
      25: 57.3,
      26: 56.3,
      27: 55.3,
      28: 54.4,
      29: 53.4,
      30: 52.5,
      31: 51.5,
      32: 50.5,
      33: 49.6,
      34: 48.6,
      35: 47.7,
      36: 46.7,
      37: 45.7,
      38: 44.8,
      39: 43.8,
      40: 42.9,
      41: 41.9,
      42: 41,
      43: 40,
      44: 39,
      45: 38.1,
      46: 37.1,
      47: 36.2,
      48: 35.3,
      49: 34.3,
      50: 33.4,
      51: 32.5,
      52: 31.6,
      53: 30.7,
      54: 29.8,
      55: 28.9,
      56: 28,
      57: 27.1,
      58: 26.3,
      59: 25.4,
      60: 24.5,
      61: 23.7,
      62: 22.9,
      63: 22.1,
      64: 21.2,
      65: 20.4,
      66: 19.6,
      67: 18.8,
      68: 18,
      69: 17.2,
      70: 16.4,
      71: 15.6,
      72: 14.9,
      73: 14.1,
      74: 13.4,
      75: 12.6,
      76: 11.9,
      77: 11.3,
      78: 10.6,
      79: 10,
      80: 9.3,
      81: 8.8,
      82: 8.2,
      83: 7.7,
      84: 7.2,
      85: 6.7,
      86: 6.3,
      87: 5.8,
      88: 5.5,
      89: 5.1,
      90: 4.8,
      91: 4.5,
      92: 4.2,
      93: 3.9,
      94: 3.7,
      95: 3.5,
      96: 3.3,
      97: 3.1,
      98: 3,
      99: 2.8,
      100: 2.7,
      101: 2.6,
      102: 2.6,
      103: 2.5,
      104: 2.5,
      105: 2.5,
      106: 2.5,
      107: 2.5,
      108: 2.4,
      109: 2.4,
      110: 2.4,
      111: 2.4,
      112: 2.3,
      113: 2.2,
      114: 2.1,
      115: 1.9,
      116: 1.7,
      117: 1.4
   },	
   119: {	
      119: 65,
      120: 64.1,
      20: 63.1,
      21: 62.1,
      22: 61.1,
      23: 60.2,
      24: 59.2,
      25: 58.2,
      26: 57.3,
      27: 56.3,
      28: 55.3,
      29: 54.4,
      30: 53.4,
      31: 52.5,
      32: 51.5,
      33: 50.5,
      34: 49.6,
      35: 48.6,
      36: 47.7,
      37: 46.7,
      38: 45.7,
      39: 44.8,
      40: 43.8,
      41: 42.9,
      42: 41.9,
      43: 41,
      44: 40,
      45: 39,
      46: 38.1,
      47: 37.1,
      48: 36.2,
      49: 35.3,
      50: 34.3,
      51: 33.4,
      52: 32.5,
      53: 31.6,
      54: 30.7,
      55: 29.8,
      56: 28.9,
      57: 28,
      58: 27.1,
      59: 26.2,
      60: 25.4,
      61: 24.5,
      62: 23.7,
      63: 22.9,
      64: 22.1,
      65: 21.2,
      66: 20.4,
      67: 19.6,
      68: 18.8,
      69: 18,
      70: 17.2,
      71: 16.4,
      72: 15.6,
      73: 14.8,
      74: 14.1,
      75: 13.4,
      76: 12.6,
      77: 11.9,
      78: 11.2,
      79: 10.6,
      80: 9.9,
      81: 9.3,
      82: 8.7,
      83: 8.2,
      84: 7.6,
      85: 7.1,
      86: 6.6,
      87: 6.2,
      88: 5.8,
      89: 5.4,
      90: 5,
      91: 4.7,
      92: 4.4,
      93: 4.1,
      94: 3.8,
      95: 3.6,
      96: 3.3,
      97: 3.1,
      98: 2.9,
      99: 2.8,
      100: 2.6,
      101: 2.5,
      102: 2.4,
      103: 2.4,
      104: 2.3,
      105: 2.3,
      106: 2.3,
      107: 2.3,
      108: 2.2,
      109: 2.2,
      110: 2.2,
      111: 2.2,
      112: 2.1,
      113: 2.1,
      114: 2,
      115: 1.9,
      116: 1.7,
      117: 1.3,
      118: 1.1
   },	
   120: {	
      120: 65,
      20: 64.1,
      21: 63.1,
      22: 62.1,
      23: 61.1,
      24: 60.2,
      25: 59.2,
      26: 58.2,
      27: 57.3,
      28: 56.3,
      29: 55.3,
      30: 54.4,
      31: 53.4,
      32: 52.5,
      33: 51.5,
      34: 50.5,
      35: 49.6,
      36: 48.6,
      37: 47.7,
      38: 46.7,
      39: 45.7,
      40: 44.8,
      41: 43.8,
      42: 42.9,
      43: 41.9,
      44: 41,
      45: 40,
      46: 39,
      47: 38.1,
      48: 37.1,
      49: 36.2,
      50: 35.3,
      51: 34.3,
      52: 33.4,
      53: 32.5,
      54: 31.6,
      55: 30.6,
      56: 29.8,
      57: 28.9,
      58: 28,
      59: 27.1,
      60: 26.2,
      61: 25.4,
      62: 24.5,
      63: 23.7,
      64: 22.9,
      65: 22,
      66: 21.2,
      67: 20.4,
      68: 19.6,
      69: 18.8,
      70: 18,
      71: 17.2,
      72: 16.4,
      73: 15.6,
      74: 14.8,
      75: 14.1,
      76: 13.3,
      77: 12.6,
      78: 11.9,
      79: 11.2,
      80: 10.5,
      81: 9.9,
      82: 9.3,
      83: 8.7,
      84: 8.1,
      85: 7.6,
      86: 7.1,
      87: 6.6,
      88: 6.1,
      89: 5.7,
      90: 5.3,
      91: 4.9,
      92: 4.6,
      93: 4.3,
      94: 4,
      95: 3.7,
      96: 3.4,
      97: 3.2,
      98: 3,
      99: 2.8,
      100: 2.6,
      101: 2.5,
      102: 2.3,
      103: 2.2,
      104: 2.1,
      105: 2.1,
      106: 2.1,
      107: 2,
      108: 2,
      109: 2,
      110: 2,
      111: 2,
      112: 1.9,
      113: 1.9,
      114: 1.8,
      115: 1.8,
      116: 1.6,
      117: 1.4,
      118: 1.1,
      119: 1,
   },	
 };

 export default jointLifeExpectancyTable;
 