import React, { useState } from 'react';
import { Alert, Button, Card, Form, Collapse, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const APRCalculator = () => {
  const [apyRate, setApyRate] = useState(3.815);
  const [compoundingFrequency, setCompoundingFrequency] = useState('monthly');
  const [apr, setAPR] = useState(0);

  const frequencies = {
    daily: 365,
    weekly: 52,
    biweekly: 26,
    monthly: 12,
    quarterly: 4,
    annually: 1
  };

  const calculateAPR = (e) => {
    e.preventDefault();
    const r = apyRate / 100;
    const n = frequencies[compoundingFrequency];
    const periodicRate = ((1 + r) ** (1/n)) - 1;
    const apr = (periodicRate * n * 100).toFixed(3);
    setAPR(apr);
  };

  const resetForm = () => {
    setApyRate(0);
    setCompoundingFrequency('monthly');
    setAPR(0);
  };

  return (
      <React.Fragment>
         <Layout>
            <CoverCard>
               <Card.Title id='title'>APY to APR Calculator</Card.Title>
                  <Form onSubmit={calculateAPR}>
                  <Form.Group controlId="apyRate">
                     <Form.Label>Interest Rate (APY %):</Form.Label>
                     <NumericFormat
                        decimalScale={3}
                        suffix="%"
                        className="form-control"
                        value={apyRate}
                        onValueChange={(values) => {
                        const { value } = values;
                        setApyRate(parseFloat(value));
                        }}
                     />
                  </Form.Group>
                  <Form.Group controlId="compoundingFrequency">
                     <Form.Label>Compounding Frequency:</Form.Label>
                     <div>
                        <Form.Check
                        type="radio"
                        id="daily"
                        label="Daily"
                        value="daily"
                        checked={compoundingFrequency === 'daily'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="weekly"
                        label="Weekly"
                        value="weekly"
                        checked={compoundingFrequency === 'weekly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="biweekly"
                        label="Bi-Weekly"
                        value="biweekly"
                        checked={compoundingFrequency === 'biweekly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="monthly"
                        label="Monthly"
                        value="monthly"
                        checked={compoundingFrequency === 'monthly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="quarterly"
                        label="Quarterly"
                        value="quarterly"
                        checked={compoundingFrequency === 'quarterly'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                        <Form.Check
                        type="radio"
                        id="annually"
                        label="Annually"
                        value="annually"
                        checked={compoundingFrequency === 'annually'}
                        onChange={(e) => setCompoundingFrequency(e.target.value)}
                        />
                     </div>
                  </Form.Group>

                  <Button variant="success" type="submit" className="custom-button" size="sm">
                     Calculate
                  </Button>
                  <Button variant="danger" type="button" onClick={resetForm} className="custom-button" size="sm">
                     Reset
                  </Button>

                  <Card.Text>
                     APR (Annual Percentage Rate): {apr}%
                  </Card.Text>
                  </Form>
            </CoverCard>
            <CoverCard>
               {/* Insert Below */}
                  <Card.Title className='fw-bold'>APY to APR Converter</Card.Title>
                  <p>
                    This calculator allows users to input the APY and select the compounding frequency: daily, weekly, bi-weekly, monthly, quarterly, and annually. By default, "Monthly" is selected.
                  </p>
                  <p>
                    When you click the "Calculate" button, the calculator uses the provided interest rate and compounding frequency to convert the APY to APR (Annual Percentage Rate) using the following formula:
                  </p>
                  <p className="fw-bold">
                    APY to APR Conversion Formula:
                    <br />
                    <code>Periodic Rate "r" = (1 + APY)<sup>1/n</sup>-1</code>
                    <br />
                    <code>APR = r x n</code>
                  </p>
                  <p>
                    Where:
                  </p>
                  <ul>
                    <li>APY: Annual Percentage Yield</li>
                    <li>r: Annual interest rate (as a decimal)</li>
                    <li>n: Number of compounding periods per year</li>
                  </ul>
                  <p>
                    APR stands for Annual Percentage Rate. Unlike APY, APR does not take into account the effect of interest compounding over the course of a year. It simply multiplies the periodic interest rate by the number of periods in a year. As a result, APR can be useful for understanding the base cost of borrowing or investing without the effects of compounding.
                  </p>
                  <p>
                  APR is calculated by multiplying the periodic interest rate by the number of periods in a year. The periodic interest rate is derived from the APY by dividing the APY by the number of periods in a year and then taking the nth root of the sum of 1 and the result, subtracting 1 from the outcome.
                  </p>
                  <p>
                    The "Reset" button clears the form, allowing you to enter new values.
                  </p>
                  <div>
                    <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/apy_to_apr.png" />
                  </div>
            </CoverCard>
         </Layout>
   </React.Fragment>
  );
};

export default APRCalculator;