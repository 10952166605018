import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip, Button, Table, Card } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const MortgagePayOff = () => {
  const [originalLoanAmount, setOriginalLoanAmount] = useState('500,000');
  const [originalTerm, setOriginalTerm] = useState('20');
  const [interestRate, setInterestRate] = useState('4.5');
  const [remainingBalance, setRemainingBalance] = useState('325,000');
  const [remainingLength, setRemainingLength] = useState('10');
  const [additionalMonthlyPayment, setAdditionalMonthlyPayment] = useState('0');
  const [desiredPayoffTime, setDesiredPayoffTime] = useState('0');
  const [monthlyPayment, setMonthlyPayment] = useState('0');
  const [requiredTotalMonthlyPayment, setRequiredTotalMonthlyPayment] = useState('0');
  const [totalInterestPaidTerm, setTotalInterestPaidTerm] = useState('0');
  const [totalInterestPaidAdditional, setTotalInterestPaidAdditional] = useState('0');
  const [currentEstimatedTime, setCurrentEstimatedTime] = useState('0');
  const [totalInterestPaidDesiredTime, setTotalInterestPaidDesiredTime] = useState('0');
  const [amortizationTable, setAmortizationTable] = useState([]);

  const calculateAmortizationTable = (e) => {
    e.preventDefault();

    const loanAmountNum = parseFloat(originalLoanAmount.replace(/[^0-9.-]+/g, ''));
    const termNum = parseFloat(originalTerm);
    const interestRateNum = parseFloat(interestRate) / 100;
    const remainingBalanceNum = parseFloat(remainingBalance.replace(/[^0-9.-]+/g, ''));
    const remainingLengthNum = parseFloat(remainingLength);
    const additionalMonthlyPaymentNum = parseFloat(additionalMonthlyPayment.replace(/[^0-9.-]+/g, ''));
    const desiredPayoffTimeNum = parseFloat(desiredPayoffTime);

    // Calculate Fixed Monthly Payment
    const monthlyInterestRateNum = interestRateNum / 12;
    const numberOfPaymentsNum = termNum * 12;
    const monthlyPaymentNum =
      (loanAmountNum *
        (monthlyInterestRateNum * Math.pow(1 + monthlyInterestRateNum, numberOfPaymentsNum))) /
      (Math.pow(1 + monthlyInterestRateNum, numberOfPaymentsNum) - 1);
    setMonthlyPayment(monthlyPaymentNum.toFixed(2));

    // Calculate Required Total Monthly Payment to Payoff by Desired Time
    const remainingPayments = remainingLengthNum * 12;
    const requiredTotalMonthlyPaymentNum =
      (remainingBalanceNum * monthlyInterestRateNum) /
      (1 - Math.pow(1 + monthlyInterestRateNum, -remainingPayments));
    setRequiredTotalMonthlyPayment(requiredTotalMonthlyPaymentNum.toFixed(2));

    // Calculate Total Interest Paid if Held Entire Term
    const totalInterestPaidTermNum = monthlyPaymentNum * numberOfPaymentsNum - loanAmountNum;
    setTotalInterestPaidTerm(totalInterestPaidTermNum.toFixed(2));

          // Calculate Total Interest Paid with Additional Payments
          if (additionalMonthlyPaymentNum > 0) {
            let remainingBalanceNum = parseFloat(remainingBalance.replace(/[^0-9.-]+/g, ''));
            let totalInterestPaidAdditionalNum = 0;
            let newMonthlyPayment = monthlyPaymentNum + additionalMonthlyPaymentNum;
            let month = 0;
    
            while (remainingBalanceNum > 0) {
              // Calculate interest for the current month
              let interestCurrent = remainingBalanceNum * (interestRateNum / 12);
              totalInterestPaidAdditionalNum += interestCurrent;
              
              // Calculate the principal portion of the monthly payment
              let principal = newMonthlyPayment - interestCurrent;
    
              // Ensure last payment isn't more than remaining balance
              if (remainingBalanceNum < newMonthlyPayment) {
                principal = remainingBalanceNum - (remainingBalanceNum * (interestRateNum / 12));
                newMonthlyPayment = principal + (remainingBalanceNum * (interestRateNum / 12));
              }
              
              // Update the remaining balance for the next month
              remainingBalanceNum -= principal;
    
              month++;
    
              // Break the loop if the remaining balance becomes zero or negative
              if (remainingBalanceNum <= 0) {
                break;
              }
            }
    
            setTotalInterestPaidAdditional(totalInterestPaidAdditionalNum.toFixed(2));
          } else {
            setTotalInterestPaidAdditional('');
          }


      // Calculate Total Interest Paid with Desired Payoff Time
      if (desiredPayoffTimeNum > 0) {
        let remainingBalanceNum = parseFloat(remainingBalance.replace(/[^0-9.-]+/g, ''));
        let totalInterestPaidDesiredTimeNum = 0;

        // Calculate the monthly payment needed to payoff in the desired time
        let desiredMonthlyPaymentNum =
          (remainingBalanceNum * monthlyInterestRateNum) /
          (1 - Math.pow(1 + monthlyInterestRateNum, -desiredPayoffTimeNum * 12));

        for (let i = 0; i < desiredPayoffTimeNum * 12; i++) {
          // Calculate interest for the current month
          let interestCurrent = remainingBalanceNum * (interestRateNum / 12);
          totalInterestPaidDesiredTimeNum += interestCurrent;

          // Calculate the principal portion of the monthly payment
          let principal = desiredMonthlyPaymentNum - interestCurrent;

          // Ensure last payment isn't more than remaining balance
          if (remainingBalanceNum - principal < 0) {
            principal = remainingBalanceNum;
            desiredMonthlyPaymentNum = principal + interestCurrent;
          }

          // Update the remaining balance for the next month
          remainingBalanceNum -= principal;

          // Break the loop if the remaining balance becomes zero or negative
          if (remainingBalanceNum <= 0) {
            break;
          }
        }

        setTotalInterestPaidDesiredTime(totalInterestPaidDesiredTimeNum.toFixed(2));
      } else {
        setTotalInterestPaidDesiredTime('');
      }


    // Calculate Current Estimated Time Until Paid off with Additional Payments
      let estimatedTime = 0;
      let remainingBalanceEstimation = remainingBalanceNum;
      let totalInterestEstimation = 0;
      while (remainingBalanceEstimation > 0) {
        const interestPayment = remainingBalanceEstimation * monthlyInterestRateNum;
        const principalPayment = monthlyPaymentNum + additionalMonthlyPaymentNum - interestPayment;
        remainingBalanceEstimation -= principalPayment;
        totalInterestEstimation += interestPayment;
        estimatedTime++;
      }
      setCurrentEstimatedTime(estimatedTime);

    
    // Generate Amortization Table
    let amortizationTableData = [];
    let currentBalance = remainingBalanceNum;
    for (let period = 1; period <= numberOfPaymentsNum; period++) {
      const interestPayment = currentBalance * monthlyInterestRateNum;
      const principalPayment = monthlyPaymentNum + additionalMonthlyPaymentNum - interestPayment;
      const endingBalance = currentBalance - principalPayment;

      const rowData = {
        period: period,
        beginningBalance: currentBalance.toFixed(2),
        payment: (monthlyPaymentNum + additionalMonthlyPaymentNum).toFixed(2),
        interestPayment: interestPayment.toFixed(2),
        principalPayment: principalPayment.toFixed(2),
        endingBalance: endingBalance.toFixed(2),
      };

      amortizationTableData.push(rowData);

      currentBalance = endingBalance;
      if (endingBalance <= 0) break;
    }

    setAmortizationTable(amortizationTableData);
  };

  const resetCalculator = () => {
    setOriginalLoanAmount('500,000');
    setOriginalTerm('20');
    setInterestRate('4.5');
    setRemainingBalance('325,000');
    setRemainingLength('10');
    setAdditionalMonthlyPayment('0');
    setDesiredPayoffTime('0');
    setMonthlyPayment('0');
    setRequiredTotalMonthlyPayment('0');
    setTotalInterestPaidTerm('0');
    setTotalInterestPaidAdditional('0');
    setCurrentEstimatedTime('0');
    setTotalInterestPaidDesiredTime('0');
    setAmortizationTable([]);
  };

  return (
    <React.Fragment>
  <Layout>
    <CoverCard>
      <Card.Title id="title">Mortgage Payoff Calculator</Card.Title>

      <Form onSubmit={calculateAmortizationTable}>
      <div className="row-container">
         <div className="row-item">
            <Form.Group controlId="formOriginalLoanAmount" className="mb-4">
            <Form.Label className="mb-1">Total Loan Amount:
                  <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>The initial loan amount you borrowed.</Tooltip>}
                  >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
            </Form.Label>
            <NumericFormat
                  thousandSeparator={true}
                  prefix={'$'}
                  className="form-control"
                  value={originalLoanAmount}
                  onValueChange={({ value }) => setOriginalLoanAmount(value)}
            />
            </Form.Group>
            </div>

            <div className="row-item">
            <Form.Group controlId="originalTerm" className="mb-4">
                  <Form.Label className="mb-1">Term:
                  <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>The initial term (in years) of the loan.</Tooltip>}
                  >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                  type="number"
                  value={originalTerm}
                  onChange={(e) => setOriginalTerm(e.target.value)}
                  />
            </Form.Group>
            </div>

            <div className="row-item">
            <Form.Group controlId="formInterestRate" className="mb-4">
            <Form.Label className="mb-1">Rate:</Form.Label>
            <OverlayTrigger
               placement="right"
               overlay={<Tooltip>Your expected or offered mortgage interest rate.</Tooltip>}
            >
               <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
            </OverlayTrigger>
            <NumericFormat
               decimalScale={2}
               suffix={'%'}
               className="form-control"
               value={interestRate}
               onValueChange={({ value }) => setInterestRate(value)}
            />
            </Form.Group>
         </div>
      </div>

      <div className="row-container">
         <div className="row-item">
            <Form.Group controlId="remainingBalance" className="mb-4">
               <Form.Label className="mb-1">Remaining Balance:
                  <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={<Tooltip>The current remaining balance of the loan.</Tooltip>}
                  >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
               </Form.Label>
               <NumericFormat
                  thousandSeparator={true}
                  prefix={'$'}
                  className="form-control"
                  value={remainingBalance}
                  onValueChange={({ value }) => setRemainingBalance(value)}
               />
               </Form.Group>
            </div>

            <div className="row-item">
            <Form.Group controlId="remainingLength" className="mb-4">
               <Form.Label className="mb-1">Remaining Time (years):
                  <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={<Tooltip>The Remaining Term Length (in years) of the loan left.</Tooltip>}
                  >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
               </Form.Label>
               <Form.Control
                  type="number"
                  value={remainingLength}
                  onChange={(e) => setRemainingLength(e.target.value)}
               />
               </Form.Group>
            </div>
         </div>

         <div className="row-container">
            <div className="row-item">
            <Form.Group controlId="additionalMonthlyPayment" className="mb-4">
                  <Form.Label className="mb-1">Additional Monthly Payments:
                  <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>The additional amount you can pay each month to pay the mortgage faster.</Tooltip>}
                  >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  </Form.Label>
                  <NumericFormat
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$'}
                  className="form-control"
                  value={additionalMonthlyPayment}
                  onValueChange={({ value }) => setAdditionalMonthlyPayment(value)}
                  />
            </Form.Group>
            </div>

            <div className="row-item">
               <Form.Group controlId="desiredPayoffTime" className="mb-4">
                  <Form.Label className="mb-1">Desired Payoff Time (in years):
                  <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>The desired time (in years) in which you want to pay off the loan.</Tooltip>}
                  >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                  type="number"
                  value={desiredPayoffTime}
                  onChange={(e) => setDesiredPayoffTime(e.target.value)}
                  />
               </Form.Group>
            </div>
         </div>

         <div className="row-container">
            <div className="row-item">
            <Form.Group controlId="formFixedMonthlyPayment" className="mb-4">
                  <Form.Label className="mb-1">Fixed Monthly Payment:
                  <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={<Tooltip>The fixed monthly payment amount based on the loan details.</Tooltip>}
                  >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" readOnly value={'$' + Number(monthlyPayment).toLocaleString()} />
               </Form.Group>
            </div>

            <div className="row-item">
            <Form.Group controlId="formTotalMonthlyPayment" className="mb-4">
               <Form.Label className="mb-1">Required PMT for target:
                  <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>The required total monthly payment to pay off the loan by the desired payoff time frame.</Tooltip>}
                  >
                  <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                  </OverlayTrigger>
               </Form.Label>
               <Form.Control type="text" readOnly value={'$' + Number(requiredTotalMonthlyPayment).toLocaleString()} />
            </Form.Group>
            </div>
          </div>

            <div className="row-container">
               <div className="row-item">
               <Form.Group controlId="formTotalInterestPaidTerm" className="mb-4">
                  <Form.Label className="mb-1">Total Interest:
                     <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={<Tooltip>The total interest paid if the loan is held for the entire mortgage period, start to finish</Tooltip>}
                     >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" readOnly value={'$' + Number(totalInterestPaidTerm).toLocaleString()} />
               </Form.Group>
               </div>
               <div className="row-item">
               <Form.Group controlId="formTotalInterestPaidAdditional" className="mb-4">
                  <Form.Label className="mb-1">Interest with Additional PMT:
                     <OverlayTrigger
                     placement="right"
                     delay={{ show: 250, hide: 400 }}
                     overlay={<Tooltip>The total interest paid if additional monthly payments are made.</Tooltip>}
                     >
                     <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                     </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" readOnly value={'$' + Number(totalInterestPaidAdditional).toLocaleString()} />
               </Form.Group>
               </div>
            </div>

            <div className="row-container">
              <div className="row-item">
              <Form.Group controlId="formEstPayoffTime" className="mb-4">
                    <Form.Label className="mb-1">Est. Payoff Time:
                      <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={<Tooltip>The estimated time it would take to pay off the loan with additional monthly payments.</Tooltip>}
                      >
                      <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={`${Math.floor(currentEstimatedTime / 12)} years and ${currentEstimatedTime % 12} months`}
                      readOnly
                    />
                </Form.Group>
              </div>

              <div className="row-item">
                <Form.Group controlId="formTotalInterestPaidDesiredTime" className="mb-4">
                  <Form.Label className="mb-1">Total Int Paid if Paid by Desired Time:
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>The total interest paid if the loan is paid off by the desired payoff time. If no value is entered in the "Desired Payoff Time" field then the formula returns 0.</Tooltip>}
                    >
                    <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control type="text" readOnly value={'$' + Number(totalInterestPaidDesiredTime).toLocaleString()} />
              </Form.Group>
              </div>
            </div>

            <Button variant="success" type="submit" className="custom-button" size="sm">Calculate</Button>
            <Button variant="danger" type="button" onClick={resetCalculator} className="custom-button" size="sm">Reset</Button>
            </Form>

          <Table striped bordered hover responsive="sm" size="sm" border="dark">
            <thead className='text-center mortgage-header' id='mortgage-calc-table-1'>
               <tr>
                  <th>Period (Months)</th>
                  <th>Remaining Balance</th>
                  <th>Payment</th>
                  <th>Interest Payment</th>
                  <th>Principal Payment</th>
                  <th>Ending Balance</th>
               </tr>
            </thead>
            <tbody className='text-center'>
               {amortizationTable.map((row) => (
                <tr key={row.period}>
                <td>{row.period}</td>
                <td>{'$' + Number(row.beginningBalance).toLocaleString()}</td>
                <td>{'$' + Number(row.payment).toLocaleString()}</td>
                <td>{'$' + Number(row.interestPayment).toLocaleString()}</td>
                <td>{'$' + Number(row.principalPayment).toLocaleString()}</td>
                <td>{'$' + Number(row.endingBalance).toLocaleString()}</td>
                </tr>
               ))}
          </tbody>
      </Table>
    </CoverCard>
    <CoverCard>
      <Card.Title className='fw-bold'>How The Mortgage Payoff Calculator Works</Card.Title>
        <p>
          This Mortgage Payoff Calculator provides the users with a detailed estimation and breakdown of the cost to pay off their mortgage loan. It calculates the monthly payment, total interest paid over the entire term, the total interest paid with additional payments, the total interest paid if the mortgage is paid off in a desired time, and generates an amortization table to show how each payment affects the mortgage balance.
        </p>
        <p>
          A mortgage is a loan typically offered by a bank or a financial institution to help individuals or businesses purchase real estate property when they cannot afford the entire price upfront. The borrower must then repay the loan, along with interest, over a predetermined period (the loan term). This is usually done through monthly payments. 
        </p>
        <p>
          When a person pays more than their minimum monthly mortgage payment, the excess is usually applied directly to the principal balance. This reduces the remaining balance and the interest accrued on it, allowing for faster repayment of the loan and a decrease in the total amount of interest paid.
        </p>
        <p>
          The Mortgage Payoff Calculator uses several key pieces of information:
        </p>
        <ul>
          <li>Original loan amount</li>
          <li>Original term (length of the loan)</li>
          <li>Interest rate</li>
          <li>Remaining balance</li>
          <li>Remaining length of the loan</li>
          <li>Additional monthly payment (if any)</li>
          <li>Desired payoff time (if applicable)</li>
        </ul>
        <p>
          Using these inputs, it calculates various aspects of the mortgage. The fixed monthly payment is calculated first, followed by the total monthly payment required to pay off the mortgage by a certain desired time (if entered). The calculator then estimates the total interest paid if the loan is held for its entire term, and the total interest paid if additional monthly payments are made.
        </p>
        <p>
          The calculator also determines the total interest paid if the mortgage is paid off in a desired time (if entered) and estimates the time it would take to pay off the mortgage with additional monthly payments.
        </p>
        <p>
          Lastly, the calculator generates an amortization table, which is a detailed breakdown of each payment for the remaining term of the loan. This table includes the remaining balance at the beginning of each period, the total monthly payment, the interest payment, the principal payment, and the remaining balance at the end of the period.
        </p>
        <p>
          The formula for calculating the total monthly payment to payoff the mortgage by the desired time (R) is:
        </p>
        <ul>
          <li><code>R = B * (i / (1 - (1 + i) ^ -n))</code></li>
        </ul>
        <p>Where:</p>
        <ul>
          <li>B is the remaining balance</li>
          <li>i is the monthly interest rate (annual rate divided by 12)</li>
          <li>n is the remaining number of payments (months)</li>
        </ul>

        <p>
          The formula for calculating the total interest paid if the mortgage is held for its entire term (I) is:
        </p>
        <ul>
          <li><code>I = M * n - P</code></li>
        </ul>
        <p>Where:</p>
        <ul>
          <li>M is the monthly payment</li>
          <li>n is the total number of payments (months)</li>
          <li>P is the Principal loan amount</li>
        </ul>

        <p>
          The formula for calculating the interest for the current month while computing total interest paid with additional payments (Ic) is:
        </p>
        <ul>
          <li><code>Ic = B * (i / 12)</code></li>
        </ul>
        <p>Where:</p>
        <ul>
          <li>B is the remaining balance</li>
          <li>i is the annual interest rate</li>
        </ul>

        <p>
          The formula for calculating the principal portion of the monthly payment (Pc) is:
        </p>
        <ul>
          <li><code>Pc = M + A - Ic</code></li>
        </ul>
        <p>Where:</p>
        <ul>
          <li>M is the original monthly payment</li>
          <li>A is the additional monthly payment</li>
          <li>Ic is the interest for the current month</li>
        </ul>
        <div>
          <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/mortgage_calc.png" />
        </div>
    </CoverCard>
  </Layout>
</React.Fragment>
  );
};  

export default MortgagePayOff;