import React from 'react';
import { Alert, Button, Card, Form, Collapse, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';


import '@utils/styles.scss';

const Layout = ({ children }) => {
  return (
    <main id='main' style={{position: 'relative', width: '100%'}}>
      <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
      <section id="future_value" className="onlineTools">
         {children}
      </section>
    </main>
  );
};

export default Layout;
