import React, { useState } from 'react';
import { BsFillArrowUpSquareFill, BsFillArrowDownSquareFill } from 'react-icons/bs';
import { animateScroll as scroll } from 'react-scroll';

const ScrollComponent = () => {
  const [hoveredUp, setHoveredUp] = useState(false);
  const [hoveredDown, setHoveredDown] = useState(false);

  const upStyle = hoveredUp ? { color: '#5a6268' } : { color: '#0000CD', opacity: '0.3' };
  const downStyle = hoveredDown ? { color: '#5a6268' } : { color: '#0000CD', opacity: '0.3' };

  const scrollToTop = () => {
    scroll.scrollToTop({ duration: 500 });
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom({ duration: 500 });
  };

  return (
   <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 10000 }}>
     <button 
       onMouseEnter={() => setHoveredUp(true)}
       onMouseLeave={() => setHoveredUp(false)}
       onClick={scrollToTop} 
       style={{ marginRight: '10px', cursor: 'pointer', background: 'none', border: 'none' }}>
         <BsFillArrowUpSquareFill size="2em" style={upStyle} />
     </button>
     <button 
       onMouseEnter={() => setHoveredDown(true)}
       onMouseLeave={() => setHoveredDown(false)}
       onClick={scrollToBottom} 
       style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
         <BsFillArrowDownSquareFill size="2em" style={downStyle} />
     </button>
   </div>
 );
};

export default ScrollComponent;
