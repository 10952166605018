import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Form, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import tombsnobles1 from '@src/images/tombsnobles1';

import '@utils/styles.scss';


class CancelCharge extends React.Component {
   render () {
     return (
      <main id='main' style={{position: 'relative', width: '100%', height: '100vh'}}>
      <div style={{position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 0.1, zIndex: -1}}></div>
      <section id="discountedCashFlow" className="onlineTools">
        <div className="container" id="onlineTools">
            <>
            <div className="d-flex justify-content-center align-items-center">
                    <Card style={{ width: '30rem' }}>
                 <Card.Body>
                 <Card.Title>Payment Successfully Cancelled!</Card.Title>
  
                 <div>
                 <h1>Donation Cancelled</h1>
                   <p>You have cancelled your donation. Feel free to support us whenever you feel like it!</p>
                   <a href="/">Back to home</a>
                </div>
  
                 
                 </Card.Body>
              </Card>
            </div>
            </>
          </div>
        </section>
      </main>
     );
   }
 }
 
 export default CancelCharge; 
 