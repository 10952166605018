import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import { safeCredentials, handleErrors } from '@utils/fetchHelper';
import { GiReceiveMoney } from 'react-icons/gi'; // Importing the required icon

import '@utils/styles.scss';

const DonateButton = () => {
  const [stripe, setStripe] = useState(null);
  const [amount, setAmount] = useState(25);
  const [isInputVisible, setInputVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {


    async function fetchStripe() {
      const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
      setStripe(stripe);
    }

    fetchStripe();
  }, []);

  // handle scenario when viewport is less than 500px width
  const handleClick = () => {
    if (window.innerWidth < 500) {
      history.push('/donate');
    } else {
      setInputVisible(true);
    }
  };

  const handleDonate = e => {
    e.preventDefault();

    if (amount === '') {
      return;
    }

    createCheckoutSession()
      .then(sessionId => {
        redirectToCheckout(sessionId);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCancel = () => {
    setInputVisible(false);
    setAmount('');
  };

  const createCheckoutSession = () => {
    return fetch(`/charges`, safeCredentials({
      method: 'POST',
      body: JSON.stringify({ amount: amount }) // send amount in dollars
    }))
      .then(handleErrors)
      .then(async response => {
        
        const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
        return { response, stripe };
      })
      .then(({ response }) => {
        return response.charge.checkout_session_id;
      })
      .catch(error => {
        console.log(error);
      });
  };

  const redirectToCheckout = async sessionId => {
    const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
    stripe.redirectToCheckout({
      sessionId: sessionId,
    })
      .then(result => {
        if (result.error) {
          console.log(result.error);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
    {!isInputVisible ? (
      <div id="show-support" className="iconStyle" onClick={handleClick}>
        <GiReceiveMoney />
        <span>Show Support</span>
      </div>    
         ) : (
        <Form onSubmit={handleDonate} className="mb-3">
          <Form.Group controlId="amount">
            <Form.Label>Your Support is Appreciated! Enter your preferred amount.</Form.Label>
            <Form.Control
              type="number"
              min="5"
              value={amount}
              onChange={e => setAmount(Number(e.target.value))}
              required
              placeholder="Enter amount"
            />
          </Form.Group>
          <Button variant="success" type="submit" className="mb-4 ml-2" size="sm" disabled={amount === ''}>
            Donate
          </Button>{' '}
          <Button variant="danger" className="mb-4 ml-2" size="sm" onClick={handleCancel}>
            Cancel
          </Button>
        </Form>
      )}
    </div>
  );
};

export default DonateButton;