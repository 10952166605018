import React from 'react';
import { Card, Form } from 'react-bootstrap';
import GoBack from '@utils/go_back.jsx';
import GoHome from '@utils/go_home.jsx';

import '@utils/styles.scss';

const CoverCard = ({ children }) => {
  return (
    <div className="container" id="cardCover">
      <div className="d-flex justify-content-center align-items-start coverCardContainer"> 
        <Card style={{ width: '40rem' }}>
          <Card.Body>
            {children}
            <div style={{ marginTop: '20px' }}>
              <GoBack className="text-right" />
              <GoHome className="text-right" />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CoverCard;
