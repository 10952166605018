import React, { useState } from 'react';
import { Button, Card, Form, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
// import tombsnobles1 from '@src/images/tombsnobles1';
import GoBack from '@utils/go_back.jsx';
import CoverCard from '@utils/_card.jsx';
import Layout from '@utils/_layout.jsx';

import '@utils/styles.scss';

const DiscountedCashFlow = () => {
  const [currentStockPrice, setCurrentStockPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [eps, setEps] = useState(0);
  const [growthRate, setGrowthRate] = useState(0);
  const [fcf, setFcf] = useState(0);
  const [fcfGrowth, setFcfGrowth] = useState(0);
  const [yearsOfGrowth, setYearsOfGrowth] = useState(5);
  const [perpetualGrowth, setPerpetualGrowth] = useState(0);
  const [dcfType, setDcfType] = useState('eps');
  const [yearsToSimulate, setYearsToSimulate] = useState(100);
  const [open, setOpen] = useState(false);
  const [calculatedDCF, setCalculatedDCF] = useState(0);
  const [overUnderValuation, setOverUnderValuation] = useState(null);

   const calculateDCF = () => {
      let dcf = 0;
      let cashFlow = dcfType === 'eps' ? eps : fcf;
      let growth = dcfType === 'eps' ? growthRate : fcfGrowth;
   
      for (let i = 1; i <= yearsToSimulate; i++) {
      if (i > yearsOfGrowth) {
         growth = perpetualGrowth;
      }
      cashFlow *= 1 + growth;
      dcf += cashFlow / Math.pow(1 + discountRate, i);
      }
   
      return dcf.toFixed(2);
   };
 

  const handleCalculate = () => {
   const dcfValue = calculateDCF();
   setCalculatedDCF(dcfValue);
   const valuation = (dcfValue - currentStockPrice) / currentStockPrice;
   setOverUnderValuation(isNaN(valuation) ? null : valuation);
 };

 const resetForm = () => {
   setCurrentStockPrice(0);
   setDiscountRate(0);
   setEps(0);
   setGrowthRate(0);
   setFcf(0);
   setFcfGrowth(0);
   setYearsOfGrowth(5);
   setPerpetualGrowth(0);
   setDcfType('eps');
   setYearsToSimulate(100);
   setCalculatedDCF(0);
   setOverUnderValuation(null); // Clear the overUnderValuation state
 };
 

  const dcfValue = calculateDCF();
//   const overUnderValuation = (dcfValue - currentStockPrice) / currentStockPrice;
   // ... state variables and other code ...
 
   return (
     <main id="main" style={{ position: 'relative', width: '100%', height: '100vh' }}>
       <div
         style={{
           position: 'fixed',
           top: 0,
           left: 0,
           height: '100%',
           width: '100%',
           backgroundImage: `url(${"/files/profiles/tombsnobles1.jpg"})`,
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           opacity: 0.1,
           zIndex: -1
         }}
       ></div>
       <section id="discountedCashFlow" className="onlineTools">
         <div className="container" id="onlineTools">
           <>
             <div className="d-flex justify-content-center align-items-center">
               <Card style={{ width: '40rem' }}>
                 <Card.Body>
                   <Card.Title><h2>Discounted Cash Flow (DCF) Calculator</h2></Card.Title>
                   <Form>
                     {/* Current Stock Price */}
                     <Form.Group controlId="currentStockPrice">
                       <Form.Label>Current Stock Price ($)</Form.Label>
                       <NumericFormat
                         thousandSeparator={true}
                         prefix={'$'}
                         className="form-control"
                         value={currentStockPrice}
                         onValueChange={(values) => setCurrentStockPrice(parseFloat(values.value))}
                       />
                     </Form.Group>
 
                     {/* Discount Rate */}
                     <Form.Group controlId="discountRate">
                        <Form.Label>Discount Rate (%):
                           <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => (
                              <Tooltip id="button-tooltip" {...props}>
                                 The return you could achieve someplace else. I usually put the risk-free rate.
                              </Tooltip>
                              )}
                           >
                              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                           </OverlayTrigger>
                        </Form.Label>
                           <NumericFormat 
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={'%'} 
                              className="form-control" 
                              value={discountRate} 
                              onValueChange={(values) => {
                                 const { value } = values;
                                 setDiscountRate(parseFloat(value));
                              }} 
                           />
                        </Form.Group>
 
                     {/* TTM Earnings Per Share */}
                     <Form.Group controlId="ttmEarningsPerShare">
                        <Form.Label>TTM EPS ($):
                           <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={props => (
                              <Tooltip id="button-tooltip" {...props}>
                                 Trailing 12 month dollars value earned in profit per share.
                              </Tooltip>
                              )}
                           >
                              <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                           </OverlayTrigger>
                           </Form.Label>
                              <NumericFormat 
                                 thousandSeparator={true} 
                                 prefix={'$'} 
                                 className="form-control" 
                                 value={eps} 
                                 onValueChange={(values) => {
                                 const { value } = values;
                                 setEps(parseFloat(value));
                                 }} 
                              />
                        </Form.Group>

                        {/* Earnings Growth */}
                        <Form.Group controlId="earningsGrowth">
                           <Form.Label>EPS Growth (%)
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    The expected growth in earnings per share near term.
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                           </Form.Label>
                              <NumericFormat 
                                 decimalScale={2}
                                 fixedDecimalScale
                                 suffix={'%'} 
                                 className="form-control" 
                                 value={growthRate} 
                                 onValueChange={(values) => {
                                    const { value } = values;
                                    setGrowthRate(parseFloat(value));
                                 }} 
                              />
                           </Form.Group>
 
                     {/* Advanced Options */}
                     <Button variant="primary" className="mb-4 ml-2" size="sm" onClick={() => setOpen(!open)}>
                       Advanced Options
                     </Button>
                     <Collapse in={open}>
                       <div>

                           {/* TTM Free Cash Flow Per Share */}
                         <Form.Group controlId="ttmFcf">
                           <Form.Label>TTM FCF per share ($):
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    The trailing 12 month dollar value of free cash flow per share. Only use this if you selected the FCF option DCF type.
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                              </Form.Label>
                                 <NumericFormat 
                                    thousandSeparator={true} 
                                    prefix={'$'} 
                                    className="form-control" 
                                    value={fcf} 
                                    onValueChange={(values) => {
                                    const { value } = values;
                                    setFcf(parseFloat(value));
                                    }} 
                                 />
                           </Form.Group>

                           {/* Free Cash Flow Growth */}
                           <Form.Group controlId="fcfGrowth">
                              <Form.Label>FCF Growth (%):
                                 <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={props => (
                                    <Tooltip id="button-tooltip" {...props}>
                                       The annual rate of growth you expect for FCF in near term. Only use this if you selected the FCF option DCF type.
                                    </Tooltip>
                                    )}
                                 >
                                    <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                                 </OverlayTrigger>
                              </Form.Label>
                                 <NumericFormat 
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={'%'} 
                                    className="form-control" 
                                    value={fcfGrowth} 
                                    onValueChange={(values) => {
                                       const { value } = values;
                                       setFcfGrowth(parseFloat(value));
                                    }} 
                                 />
                              </Form.Group>
 
                         {/* Years of Above Growth */}
                         <Form.Group controlId="yearsOfGrowth">
                           <Form.Label>Estimated Time Above Growth Rate (in Years):
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    Expected near term growth (in years). Usually 5 years or less. The "foreseeable" period of time you would expect to see more aggressive growth in a company or growth higher than the perpetual growth rate.
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                              </Form.Label>
                              <Form.Control
                                 type="number"
                                 value={yearsOfGrowth}
                                 onChange={(e) => setYearsOfGrowth(parseInt(e.target.value))}
                              />
                           </Form.Group>
 
                         {/* Perpetual Growth */}
                         {/* Free Cash Flow Growth */}
                           <Form.Group controlId="perpetualGrowth">
                              <Form.Label>Perpetual Growth Rate (%):
                                 <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={props => (
                                    <Tooltip id="button-tooltip" {...props}>
                                       After 'growth' period, the estimated average growth rate in Earnings per share or FCF per share. I typically put 2%.
                                    </Tooltip>
                                    )}
                                 >
                                    <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                                 </OverlayTrigger>
                              </Form.Label>
                                 <NumericFormat 
                                    decimalScale={2}
                                    fixedDecimalScale
                                    suffix={'%'} 
                                    className="form-control" 
                                    value={perpetualGrowth} 
                                    onValueChange={(values) => {
                                       const { value } = values;
                                       setPerpetualGrowth(parseFloat(value));
                                    }} 
                                 />
                              </Form.Group>
 
                         {/* DCF Type */}
                         <Form.Group controlId="dcfType">
                           <Form.Label>DCF Type:
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    Free Cash Flow per share or Earnings per share.
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                              </Form.Label>
                              <Form.Check
                                 type="radio"
                                 id="eps"
                                 label="Earnings per Share (EPS)"
                                 checked={dcfType === 'eps'}
                                 onChange={() => setDcfType('eps')}
                              />
                              <Form.Check
                                 type="radio"
                                 id="fcf"
                                 label="Free Cash Flow per Share (FCFPS)"
                                 checked={dcfType === 'fcf'}
                                 onChange={() => setDcfType('fcf')}
                              />
                           </Form.Group>
 
                         {/* Years to Simulate */}
                         <Form.Group controlId="yearsToSimulate">
                           <Form.Label>Time (Years):
                              <OverlayTrigger
                                 placement="right"
                                 delay={{ show: 250, hide: 400 }}
                                 overlay={props => (
                                 <Tooltip id="button-tooltip" {...props}>
                                    The number of years in the future for the DCF model to calculate.
                                 </Tooltip>
                                 )}
                              >
                                 <Button variant="info" size="sm" style={{ marginLeft: '10px', padding: '0 5px', fontSize: '0.7em' }}>i</Button>
                              </OverlayTrigger>
                              </Form.Label>
                              <Form.Control
                                 type="number"
                                 value={yearsToSimulate}
                                 onChange={(e) => setYearsToSimulate(parseInt(e.target.value))}
                              />
                           </Form.Group>
                       </div>
                     </Collapse>
 
                     {/* Calculate and Reset Buttons */}
                     <Button variant="success" className="mb-4" size="sm" onClick={handleCalculate}>
                        Calculate
                     </Button>
                     <Button variant="danger" onClick={resetForm} className="mb-4 ml-2" size="sm">
                        Reset
                     </Button>
                     <Card.Text>
                        Discounted Cash Flow (DCF) / Calculated Fair Value: {calculatedDCF}
                     </Card.Text>
                     <Card.Text>
                     Over / Under Value Percentage: {overUnderValuation !== null ? (overUnderValuation * 100).toFixed(2) + '%' : ''}
                     </Card.Text>
                   </Form>
                   <GoBack className="mt-2 ml-2 text-right"/>
                 </Card.Body>
               </Card>
             </div>
           </>
         </div>
         <div className='container' className='onlineTools mt-3'>
            <div className="d-flex justify-content-center align-items-center">
               <Card style={{ width: '40rem' }}>
                  <Card.Body>
                  <Card.Title className='fw-bold'>Discounted Cash Flow (DCF) Calculator</Card.Title>
                  <p>
                     The Discounted Cash Flow (DCF) Calculator helps you estimate the value of an investment or company based on its expected future cash flows. It takes into account the time value of money, meaning that future cash flows are worth less than the same amount of cash received today.
                  </p>
                  <p>
                     The calculation for DCF is based on the formula:
                  </p>
                  <p className="fw-bold">
                     Discounted Cash Flow (DCF):
                     <br />
                     <code>DCF = CF₁ / (1 + r) + CF₂ / (1 + r)² + CF₃ / (1 + r)³ + ... + CFₙ / (1 + r)ⁿ</code>
                  </p>
                  <p>
                     Where:
                  </p>
                  <ul>
                     <li>DCF: Discounted Cash Flow</li>
                     <li>CF₁, CF₂, CF₃, ..., CFₙ: Expected future cash flows in each period (e.g., year)</li>
                     <li>r: Discount rate or required rate of return</li>
                  </ul>
                  <p>
                     The DCF calculator takes the following inputs:
                  </p>
                  <ul>
                     <li>Current Stock Price ($): The current market price of the stock or investment.</li>
                     <li>Discount Rate (%): The rate used to discount future cash flows to their present value. It represents the minimum rate of return required by an investor to invest in the stock.</li>
                     <li>TTM EPS ($): Trailing twelve months earnings per share. It is the total earnings of the company over the past twelve months divided by the number of outstanding shares.</li>
                     <li>EPS Growth (%): The expected annual growth rate of earnings per share.</li>
                     <li>TTM FCF per share ($): Trailing twelve months free cash flow per share. It represents the amount of cash generated by the company's operations after accounting for capital expenditures.</li>
                     <li>FCF Growth (%): The expected annual growth rate of free cash flow per share.</li>
                     <li>Estimated Time Above Growth Rate (in Years): The number of years expected for the company to grow at a rate higher than the perpetual growth rate.</li>
                     <li>Perpetual Growth Rate (%): The estimated average growth rate of earnings per share or free cash flow per share after the growth period.</li>
                     <li>DCF Type: The type of cash flow used for the DCF calculation, either Earnings per Share (EPS) or Free Cash Flow per Share (FCFPS).</li>
                     <li>Time (Years): The number of years in the future for which the DCF model calculates the value.</li>
                  </ul>
                  <p>
                     After entering these parameters, the calculator calculates the DCF value based on the inputs provided and displays it as the "Discounted Cash Flow (DCF) / Calculated Fair Value." Additionally, it calculates the over/under valuation percentage by comparing the DCF value with the current stock price.
                  </p>
                  <div>
                     <Card.Img variant="top" src="/files/images/dcf.png" />
                  </div>
                  </Card.Body>
               </Card>
            </div>
            </div>
       </section>
     </main>
   );
 };

 export default DiscountedCashFlow;
 
