import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ListGroup from 'react-bootstrap/ListGroup';

import '@src/home.scss';

const Excel = (props) => {
   return(
      <React.Fragment>
        <Accordion.Header><b>Excel & Googlesheets</b></Accordion.Header>
        <Accordion.Body>
           {/* new card group */}
           <CardGroup>
                 <Card>
                 <a href="https://rpasher.s3.amazonaws.com/excel/RMDCalculator.xlsx" download>
                 <Card.Img variant="top" src="/files/images/rmd2.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>RMD Calculator & Tracker</Card.Title>
                    <Card.Text>
                       This sheet serves the dual purpose of calculating RMDs and tracking them to keep better organization of client data. Use instructions are in comment on cell A1 on sheet RMD.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">Click on photo to download excel file</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                    <a href="https://rpasher.s3.amazonaws.com/excel/mortgagecalculator.xlsx" download>
                    <Card.Img variant="top" src="https://rpasher.s3.amazonaws.com/images/mortgagecalc1.png" />
                    </a>
                    <Card.Body>
                    <Card.Title>Mortgage Calculator</Card.Title>
                    <Card.Text>
                       Calculate and compare mortgage rates to each other.
                    </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                    <small className="text-muted">Click on photo to download excel file</small>
                    </Card.Footer>
                    </Card>      
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://rpasher.s3.amazonaws.com/excel/mortgagetax.xlsx" download>
                    <Card.Img variant="top" src="/files/images/mortgagetax.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>Mortgage Tax Savings</Card.Title>
                    <Card.Text>
                       Calculate and compare tax savings options with mortgage loans.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">Click on photo to download excel file</small>
                 </Card.Footer>
                 </Card>
                 {/* end cards */}
              </CardGroup>
              {/* end card group */}

              <hr/>

              {/* new card group */}
              <CardGroup>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="/files/excel/Optionpricing.xlsm" download>
                    <Card.Img variant="top" src="/files/images/options1.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>Option Pricing</Card.Title>
                    <Card.Text>
                       Use Black-Scholes Model to value Put and Call Options
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">click on photo to download excel file</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                 <a href="https://rpasher.s3.amazonaws.com/excel/ccpayoff.xlsx" download>
                    <Card.Img variant="top" src="/files/images/ccpayoff.png" />
                 </a>
                 <Card.Body>
                    <Card.Title>Credit Card Payoff</Card.Title>
                    <Card.Text>
                       Calculate times and values involved with paying off Credit Card Debt.
                    </Card.Text>
                 </Card.Body>
                 <Card.Footer>
                    <small className="text-muted">click on photo to download excel file</small>
                 </Card.Footer>
                 </Card>
                 {/* new card with excel/sheets image and file */}
                 <Card>
                    <a href="https://docs.google.com/spreadsheets/d/1GAzr-usEYVwoZpUeTS6jLVJHHsm37tyLsx0o9fm3KM8/edit?usp=sharing" target="_blank" rel="noreferrer">
                       <Card.Img variant="top" src="/files/images/isos.png" />
                    </a>
                    <Card.Body>
                       <Card.Title>Incentive Stock Options (ISOs)</Card.Title>
                       <Card.Text>
                          Calculate the after-tax value of Incentive Stock Options
                       </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                       <small className="text-muted">click on photo to navigate to Googlesheets page</small>
                    </Card.Footer>
                    </Card>
                 {/* end cards */}
              </CardGroup>
              {/* end card group */}
        </Accordion.Body>
      </React.Fragment>
   );
}

export default Excel;